import { InfoCircle } from "react-bootstrap-icons";
import { ButtonToolbar, OverlayTrigger, Popover } from "react-bootstrap";
// import React, { useEffect, useState } from "react";
// import axios from "axios";

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
// });

function InfoPop({msg})  {

    const popoverClickRootClose = (
      <Popover id="popover-trigger-click-root-close" title="Popover right">
        <div>
                <p>
                  {msg}
                </p>
        </div>
      </Popover>
    );
  
    return (
      <ButtonToolbar>
        <OverlayTrigger
          trigger={msg !== "-" ? "click" : "" }
          rootClose
          placement="auto"
          overlay={popoverClickRootClose}
        >
          <InfoCircle />
        </OverlayTrigger>
      </ButtonToolbar>
    );
  };

  export default InfoPop;