import { useState } from "react";
import allianz from "../../assets/images/insurer/aliianz.svg";
import etiqa from "../../assets/images/insurer/etiqa.svg";
// import kurnia from "../../assets/images/insurer/kurnia.svg";
import rhb from "../../assets/images/insurer/rhb.svg";
// import tokio from "../../assets/images/insurer/tokio.svg";
import zurich from "../../assets/images/insurer/zurich.svg";
import zurichTaka from "../../assets/images/insurer/Zurich-Takaful-logo-1.svg"
import tune from "../../assets/images/tune_logo.svg";
import notMatch from "../../assets/images/insurer/not-match.svg";
import arrow_down from "../../assets/images/arrow_down.svg";
import arrow_up from "../../assets/images/arrow-up.svg";
import "../../assets/styles/PlanBox.scss";
import { useTranslation } from "react-i18next";
import { checkvalue } from "../Common/DecimalFormatter";

function PlanBox(props) {
  const { t } = useTranslation();

  const selectedPlan = (plan) => {
    props.handleSelectedPlan(plan);

    const selectedPolicyType = logoArray[plan.compCode].name.split(" ")[0] +" "+ logoArray[plan.compCode].name.split(" ")[1] + " "+ policyTypes[plan.coverCode] ;
    window.dataLayer.push({
      event: "select_item",
      event_category: "Plans",
      event_action: "Select Item",
      event_label: selectedPolicyType,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
      vehicle_make:localStorage.vehicleMake,
      vehicle_variant: localStorage.getVehicleName,
      items: [
        {
          item_id:  plan.compCode,
          item_name: selectedPolicyType,
          item_brand:  logoArray[plan.compCode].name.split(" ")[0],
          item_category: "Vehicle Insurance",
          item_variant: "Market Value",
          currency: "MYR",
          price: grossPrem,
          quantity: "1",
        },
      ],
    });
  };

  const showHide = (index) => {
    props.handleShowHide(index);
  };
  const [policyTypes] = useState({
    "01": `${t("comprehensive")}`,
    "02": `${t("third_party")}`,
    "03": `${t("third_party_ft")}`,
  });
  const logoArray = {
    13: { name: "ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD", logo: allianz },
    18: { name: "ZURICH GENERAL INSURANCE MALAYSIA BERHAD", logo: zurich },
    97: { name: "ZURICH GENERAL TAKAFUL MALAYSIA BERHAD", logo: zurichTaka },
    96: { name: "ETIQA GENERAL TAKAFUL BERHAD", logo: etiqa },
    // 91: { name: "TOKIO MARINE INSURANS (MALAYSIA) BERHAD", logo: tokio },
    21: { name: "RHB INSURANCE BERHAD", logo: rhb },
    40: { name: "TUNE PROTECT MALAYSIA ", logo: tune },
  };

  const ncdAmt = props.plan?.ncdAmt.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const grossPrem = props.plan?.grossPrem.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const checkval = checkvalue(grossPrem);

  return (
    <>{props.available === true && logoArray[props.plan.compCode] && (
      <div
        className={`plan-box ${
          props.benefits?.length === 0 ? `${t("benefit_empty")}` : ""
        }`}
        key={props.index}
      >
        {/* Company Section */}
        <div className="company">
          <div className="logo">
            <div>
              <img
                className={props.plan.compCode === '40' ? 'tune' : ''}
                src={logoArray[props.plan.compCode].logo}
                alt={`${logoArray[props.plan.compCode].name}-logo"`}
              />
            </div>
            <span className="label">{policyTypes[props.plan.coverCode]}</span>
          </div>

          {props.benefits?.length > 0 && (
            <div className="plan-amount">
              <button
                className="blue-button medium hover"
                onClick={() => {
                  selectedPlan(props.plan);
                }}
              >
                RM {checkval}

              </button>
              <div className="desc">
              {t("your_saving")}{" "}
                <span className="highlight bold">RM{props.plan?.ncdAmt}</span>
              </div>
            </div>
          )}
        </div>
        {/* Description Section */}
        <div
          className={`description ${props.benefits.length === 0 ? "mt-3" : ""}`}
        >
          <div
            className={`description-list
            ${
              Boolean(
                JSON.stringify(props.plans[props.index].isBenefitExpand) ===
                  "false"
              )
                ? "expand"
                : ""
            }
            ${props.benefits.length > 5 ? "limit-box" : ""}`}
          >
            {/* li Details */}
            <ul>
              {props.benefits.length === 0 ? (
                <li className="error">
                  <img className="not-match" src={notMatch} alt="not-match" />
                  <span className="label">
                    {t("error_no_info")}
                  </span>
                </li>
              ) : (
                <span>
                  {props.benefits.length > 0 &&
                    props.benefits.map((benefit, indexBenefit) => {
                      return <li key={indexBenefit}>{benefit}</li>;
                    })}
                </span>
              )}
            </ul>
          </div>
          {/* Show Hide Icon */}
          {props.benefits.length > 5 && (
            <div className="description-more">
              <span className="read-more" onClick={() => showHide(props.index)}>
                {Boolean(
                  JSON.stringify(props.plans[props.index].isBenefitExpand) ===
                    "false"
                ) ? (
                  <img src={arrow_up} alt="arrow-down" />
                ) : (
                  <img src={arrow_down} alt="arrow-down" />
                )}
              </span>
            </div>
          )}
        </div>
        {/* Total Amount Section */}
        {props.benefits.length > 0 && (
          <div className="amount">
            <button
              className="blue-button medium hover"
              onClick={() => {
                selectedPlan(props.plan);
              }}
            >
              RM {checkval}

            </button>
            <div className="desc">
            {t("your_saving")}{" "}
              <span className="highlight bold">RM {ncdAmt}</span>
            </div>
          </div>
        )}
      </div>
    ) }
      
    </>
  );
}

export default PlanBox;
