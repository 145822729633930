import DOMPurify from "dompurify";
import { useState } from "react";
import payment_details from "../../assets/images/priceBox.svg";
import close_button from "../../assets/images/close.svg";
import "../../assets/styles/PaymentBox.scss";
import { useTranslation } from "react-i18next";
import { checkvalue } from "../Common/DecimalFormatter";
import allianz from "../../assets/images/insurer/aliianz.svg";
import etiqa from "../../assets/images/insurer/etiqa.svg";
import rhb from "../../assets/images/insurer/rhb.svg";
// import tokio from "../../assets/images/insurer/tokio.svg";
import zurich from "../../assets/images/insurer/zurich.svg";
import zurichTakaful from "../../assets/images/insurer/Zurich-Takaful-logo-1.svg";
import tune from "../../assets/images/tune_logo.svg";

function PaymentBox(props) {
  const { t } = useTranslation();
  const logoArray = {
    13: {
      name: "ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD",
      logo: allianz,
    },
    18: { name: "ZURICH GENERAL INSURANCE MALAYSIA BERHAD", logo: zurich },
    97: { name: "ZURICH GENERAL TAKAFUL MALAYSIA BERHAD", logo: zurichTakaful },
    96: { name: "ETIQA GENERAL TAKAFUL BERHAD", logo: etiqa },
    // 91: { name: "TOKIO MARINE INSURANS (MALAYSIA) BERHAD", logo: tokio },
    21: { name: "RHB INSURANCE BERHAD", logo: rhb },
    40: { name: "TUNE PROTECT MALAYSIA", logo: tune },
  };
  var monthShort = [
    "",
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const ncdAmt = parseFloat(props.premiumdetails?.ncdAmt)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const grossPrem = parseFloat(props.premiumdetails?.grossPrem)
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const startDate = props.premiumdetails.effectiveDate.split("-");
  const expDate = props.premiumdetails.expiryDate.split("-");
  const effectiveDate = `${startDate[2]} ${
    monthShort[parseInt(startDate[1])]
  } ${startDate[0]}`;
  const expiryDate = `${expDate[2]} ${monthShort[parseInt(expDate[1])]} ${
    expDate[0]
  }`;
  const [show, setShow] = useState(false);
  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data),
  });

  const basicPremium = props.premiumdetails?.tariffPremium
    .toFixed(2)
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const checkval = checkvalue(
    parseFloat(props.premiumdetails?.grossDue).toLocaleString()
  );
  //parseFloat(props.premiumdetails?.grossDue).toLocaleString()
  const [policyTypes] = useState({
    "01": `${t("comprehensive")}`,
    "02": `${t("third_party")}`,
    "03": `${t("third_party_ft")}`,
  });

  const formatNumber = (input) => {
    if (input === null || input === undefined || input === '') {
      return '0.00';
    }
  
    const number = typeof input === 'string' ? Number(input.replace(/,/g, '')) : input;
  
    if (!isNaN(number)) {
      return typeof input === 'number' 
        ? number.toFixed(2) 
        : number.toLocaleString(undefined, {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          });
    } else {
      return '0.00';
    }
  };
  
  const handleAddOns = () => {
    if (props.payment.buttonLabel === `${t("pay")}`) {
      // On Click of Pay
      window.dataLayer.push({
        event: "motor_submit_order",
        event_category: "Checkout",
        event_action: "Submit Order",
        event_label: "Order",
        vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
        customer_age: localStorage.customerAge,
        customer_gender: localStorage.customerGender,
      });
    }
  };

  const pdsUrl = props.premiumdetails?.resExtraParam.item.find((url) => url.indicator === 'pdsurl')

  return (
    <>
      <div
        className={`payment-details ${show ? "full-height " : ""} ${
          props.check ? "bottom-breakdown" : ""
        }`}
      >
        <div className={`close  ${show ? "appear" : ""}`}>
          <img src={close_button} alt="close" onClick={() => setShow(false)} />
        </div>
        <div className={show ? "scrollable" : ""}>
          <div className={`header ${show ? "show-pop" : ""}`}>
            <div className="title">{t("premium_detail")}</div>
            <span className="subBlueTitle">
              {logoArray[props.premiumdetails?.compCode]?.name}
            </span>
          </div>
          <div className={`body ${show ? "show-pop" : ""}`}>
            <div className="section">
              <div className="detail">
                <div className="label">{t("p_type")}: </div>
                <div className="bold">
                  {policyTypes[props.premiumdetails?.coverCode]}
                </div>
              </div>
              <div className="detail">
                <div className="label">{t("coverage_period")} </div>
                <div className="bold">
                  {/* {props.premiumdetails?.effectiveDate} -{" "}
                {props.premiumdetails?.expiryDate} */}
                  {effectiveDate} - {expiryDate}
                </div>
              </div>
              <div className="detail">
                <div className="label">{t("sum_insured")}: </div>
                <div className="bold">
                  RM{" "}
                  {parseFloat(
                    props.premiumdetails?.sumInsured
                  ).toLocaleString()}
                </div>
              </div>
            </div>
            <div className="section">
              <div className="detail">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th
                        className={"ml-2"}
                        dangerouslySetInnerHTML={sanitizedData(`${t("basic")}`)}
                      ></th>
                      <td>RM</td>
                      <td className="value ">{basicPremium}</td>
                    </tr>
                    <tr>
                      <th
                        className={"ml-2 ncd-detail"}
                        dangerouslySetInnerHTML={sanitizedData(
                          `NCD(${props.premiumdetails?.ncdPerc}%)`
                        )}
                      ></th>
                      <td className="ncd-detail bold">- RM</td>
                      <td className={"ml-2 ncd-detail bold"}>
                        {ncdAmt.toLocaleString()}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="section">
              <div className="detail d-flex flex-column">
                <div className="bold">{t("add_on")}:</div>
                <table className="custom-table">
                  <thead>
                    {props.premiumdetails?.addBenData?.item?.map(
                      (data, index) => (
                        <tr className="detail" key={index}>
                          <th className="ml-3 benpremium">
                            {data.benCode === "CVPALL01FLD"
                              ? t("addon_1")
                              : data.benCode === "ALLD" ||
                              data.benCode === "ALD"
                              ? t("addon_2")
                              : data.benCode === "AND"
                              ? t("addon_3")
                              : data.benCode === "WSC"
                              ? t("addon_4")
                              : data.benCode === "LLOP"
                              ? t("addon_5")
                              : data.benCode === "LLTP"
                              ? t("addon_6")
                              : data.benCode === "UNTOW1" ||
                                data.benCode === "UNTOW2"
                              ? t("addon_7")
                              : data.benCode === "EHAILING"
                              ? t("addon_8")
                              : data.benCode === "CVPP401C002"
                              ? t("addon_9")
                              : data.benCode === "CVPP4CART"
                              ? t("addon_10")
                              : data.benCode === "CVPALL02LLTP"
                              ? t("addon_6")
                              : data.benCode === "CVPALL02LLOP"
                              ? t("addon_6")
                              : data.benCode === "CVMALL01FLD"
                              ? t("addon_11")
                              : ""}
                          </th>
                          {data.benCode === "57-1" ||
                          data.benCode === "MCPA1" ||
                          data.benCode === "MCPA1-2" ||
                          data.benCode === "MCPA1-3" ||
                          data.benCode === "MCPA2" ||
                          data.benCode === "MCPA2-1" ||
                          data.benCode === "MCPA2-2" ||
                          data.benCode === "PCB1" ||
                          data.benCode === "PCB2" ||
                          data.benCode === "A205RP" ||
                          data.benCode === "PAB1RP" ||
                          data.benCode === "PCB1" ||
                          data.benCode === "PCB2" ||
                          data.benCode === "RPPA1" ||
                          data.benCode === "A205" ||
                          data.benCode === "PAB31" ? (
                            ""
                          ) : (
                            <td>RM</td>
                          )}
                          {data.benCode === "57-1" ||
                          data.benCode === "MCPA1" ||
                          data.benCode === "MCPA1-2" ||
                          data.benCode === "MCPA1-3" ||
                          data.benCode === "MCPA2" ||
                          data.benCode === "MCPA2-1" ||
                          data.benCode === "MCPA2-2" ||
                          data.benCode === "PCB1" ||
                          data.benCode === "PCB2" ||
                          data.benCode === "A205RP" ||
                          data.benCode === "PAB1RP" ||
                          data.benCode === "PCB1" ||
                          data.benCode === "PCB2" ||
                          data.benCode === "RPPA1" ||
                          data.benCode === "A205" ||
                          data.benCode === "PAB31" ? (
                            ""
                          ) : (
                            <td className="benprem">
                              {parseFloat(data.benPremium).toFixed(2)}
                            </td>
                          )}
                        </tr>
                      )
                    )}
                  </thead>
                </table>
              </div>
            </div>
            <div className="section">
              <div className="detail">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th
                        className={"ml-2"}
                        dangerouslySetInnerHTML={sanitizedData(`${t("gross")}`)}
                      ></th>
                      <td>RM</td>
                      <td className={"ml-2 rm"}>
                        {" "}
                        {grossPrem.toLocaleString()}
                      </td>
                    </tr>
                    <tr>
                      <th
                        className={"ml-2"}
                        dangerouslySetInnerHTML={sanitizedData(
                          `${t("rebate")}`
                        )}
                      ></th>
                      <td>RM</td>
                      <td className={"ml-2 rm"}>0.00</td>
                    </tr>
                    <tr>
                      <th
                        className={"ml-2"}
                        dangerouslySetInnerHTML={sanitizedData("SST(8%)")}
                      ></th>
                      <td>RM</td>
                      <td className={"ml-2 rm"}>
                        {props.premiumdetails?.serviceTaxAmt.toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <th
                        className={"ml-2"}
                        dangerouslySetInnerHTML={sanitizedData(
                          `${t("stamp_duty")}`
                        )}
                      ></th>
                      <td>RM</td>
                      <td className={"ml-2 rm"}>
                        {" "}
                        {parseFloat(props.premiumdetails?.stampDuty).toFixed(2)}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            <div className="section">
              <div className="detail">
                <table className="custom-table">
                  <thead>
                    <tr>
                      <th
                        className={"ml-2"}
                        dangerouslySetInnerHTML={sanitizedData(`${t("total")}`)}
                      ></th>
                      <td>RM</td>
                      <td className={"ml-2 rm"}> {checkval}</td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
            {/* {props.payment.sections.map((section, index) => (
            <div className="section" key={index}>
              {section.title && <div className="medium">{section.title}</div>}
              {section.details.map((detail, index) => (
                <div className="detail" key={index}>
                  <div
                    className={`${detail.isLabelBold
                      ? "medium"
                      : section.title
                        ? "ml-2"
                        : ""
                      }`}
                    dangerouslySetInnerHTML={sanitizedData(detail.label)}
                  ></div>
                  <div
                    className={`${detail.isLabelBold
                      ? "medium"
                      : section.title
                        ? "ml-2"
                        : ""
                      }`}
                  >
                    {detail.value}
                  </div>
                </div>
              ))}
            </div>
          ))} */}
          </div>
        </div>
        <div className="footer">
          {props.pay && (
            <div className={`declaration small`}>
              {t("pay_info")}{" "}
              <span className="subBlueTitle">
                <a
                  href="https://www.pos.com.my/legal/terms-and-conditions-motor-insurance"
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("terms")}
                </a>
              </span>
              , {t("pay_info_2")}{" "}
              <span className="subBlueTitle">
                <a
                  href={pdsUrl?.value}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t("product_disclosure")}
                </a>
              </span>
              {props.premiumdetails.compCode === "18" ||
              props.premiumdetails.compCode === "97" ? (
                <span>
                  ,{" "}
                  <a
                    className="subBlueTitle"
                    href="https://www.zurich.com.my/en/customer-hub/show-me-more-info/personal-data-protection-notice"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {t("product_disclosure2")}
                  </a>{" "}
                </span>
              ) : (
                ""
              )}{" "}
              {t("pay_info_3")}
            </div>
          )}
          <div className="wrapper">
            <div
              className="price-background col-7 col-sm-8 col-md-9"
              onClick={() => {
                if (String(props.isAmountReady) === "true") {
                  setShow(!show);
                } else {
                  setShow(false);
                }
              }}
            >
              <img
                className="pop-details"
                src={payment_details}
                alt="pop-details"
                onClick={() => {
                  if (String(props.isAmountReady) === "true") {
                    setShow(true);
                  } else {
                    setShow(false);
                  }
                }}
              />
              <div className="amount-wrapper">
                <div className="amount">RM{checkval}</div>
                <span className="amount-label light">
                  {props.payment.total_highlight}
                </span>
              </div>
            </div>
            {String(props.isAmountReady) === "true" && (
              <button
                className="blue-button hover pay-blue-button"
                form={props.form}
                type={props.type}
                onClick={() => {
                  props.handlePrevious();
                  setShow(false);
                  handleAddOns();
                }}
              >
                {props.payment.buttonLabel}
              </button>
            )}
          </div>
        </div>
        <div style={{ marginTop: '12px', fontSize: '12px' }}>
          {props?.pay && formatNumber(props?.premiumdetails?.commiAmt)
            ? `* ${t('commission_1')}${props.premiumdetails.commiAmt} ${t('commission_2')}`
            : null}
        </div>
      </div>
    </>
  );
}

export default PaymentBox;
