import { t } from "i18next";
import "../assets/styles/faq.scss";
import Accordion from "react-bootstrap/Accordion";
import { useTranslation } from 'react-i18next';




function ExpandCollapse() {
  

  // Function to handle the click event and push data to the dataLayer.
  const handleExpandEvent = (title) => {
    // On Click of FAQ Title list
    window.dataLayer.push({
      event: 'expand_faq',
      event_category: 'Home',
      event_action: 'Expand FAQ',
      event_label: title,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
    });
  };

  
  const faq = [
    {
      title: t("faq_1"),
      description: t("faq_ans_1")
    },
    {
      title: t("faq_2"),
      description: t("faq_ans_2")
    },
    {
      title: t("faq_3"),
      description: t("faq_ans_3")
    },
    {
      title: t("faq_4"),
      description: t("faq_ans_4")
    },
    {
      title: t("faq_5"),
      description: t("faq_ans_5")
    },
    {
      title: t("faq_6"),
      description: t("faq_ans_6")
    },
    {
      title: t("faq_7"),
      description: ( 
        <>
        {t('faq_ans_7_1')}
        <a href="tel:1-300-300-300">{t('faq_ans_7_2')}</a>
        {t('faq_ans_7_3')}
        <a href="https://www.pos.com.my/contact-us#AskPos" target="_blank" rel="noreferrer">{t('faq_ans_7_4')}</a>
        </>
      )
    },
    {
      title: t("faq_8"),
      description: (
        <>
        {t('faq_ans_8_1')}
        <a href="tel:1-800-22-5542">{t('faq_ans_8_2')}</a>
        {t('faq_ans_8_3')}
        <a href="1-300-88-6222">{t('faq_ans_8_4')}</a>
        {t('faq_ans_8_5')}
        <a href="1-300-88-6222">{t('faq_ans_8_4')}</a>
        {t('faq_ans_8_7')}
        <a href="1-800-88-5753">{t('faq_ans_8_8')}</a>
        {t('faq_ans_8_9')}
        <a href="03-21803000">{t('faq_ans_8_10')}</a>
        {t('faq_ans_8_11')}
        <a href="1-800-88-6491">{t('faq_ans_8_12')}</a>
        </>
      )
    },
    {
      title: t("faq_9"),
      description: t("faq_ans_9")
    },
    {
      title: t("faq_10"),
      description: t("faq_ans_10")
    },
    {
      title: t("faq_11"),
      description: (
        <>
        {t('faq_ans_11_13')}
        {t('faq_ans_11_1')}
        <div className="indented_text">
          {t('faq_ans_11_2')}
          <a href="tel:1300-22-5542">{t('faq_ans_11_3')}</a>
          {t('faq_ans_11_4')}
          <a href="mailto: customer.service@allianz.com.my">{t('faq_ans_11_5')}</a>
        </div>
        {t('faq_ans_11_6')}
        <div className="indented_text">
            {t('faq_ans_11_7')}
            <a href="tel: 1-300-88-6222">{t('faq_ans_11_8')}</a>
            {t('faq_ans_11_9')}
            <a href="tel: 03-2109 6000">{t('faq_ans_11_10')}</a>
            {t('faq_ans_11_11')}
            <a href="mailto: callcentre@zurich.com.my">{t('faq_ans_11_12')}</a></div>
        {t('faq_ans_11_14')}
        <div className="indented_text">
          {t('faq_ans_11_14.1')}
          {t('faq_ans_11_14.2')}
          <a href="1-300-220007">{t('faq_ans_8_8')}</a>
          {t('faq_ans_11_15')}
          <a href="mailto: hello.my@tuneprotect.com">{t('faq_ans_11_16')}</a>
        </div>
        {t('faq_ans_11_17')}
        <div className="indented_text">
          {t('faq_ans_11_18')}
          {t('faq_ans_11_18.1')}
          <a href="https://wa.me/60126031978" target="blank">{t('faq_ans_11_18.2')}</a>
          {t('faq_ans_11_19')}
          <a href="1-800-88-5753">{t('faq_ans_11_22')}</a>
          {t('faq_ans_11_15')}
          <a href="mailto: rhbi.general@rhbgroup.com">{t('faq_ans_11_21')}</a>
        </div>
        {t('faq_ans_11_23')}
        <div className="indented_text">
          {t('faq_ans_11_24')}
          {t('faq_ans_11_19')}
          <a href="1-800-88-6491">{t('faq_ans_8_12')}</a>
          {t('faq_ans_11_27')}
          <a href="1-800-88-6491">{t('faq_ans_11_26')}</a>
          {t('faq_ans_11_15')}
          <a href="mailto: info@etiqa.com.my">{t('faq_ans_8_13')}</a> 
        </div>
        </>
      )
    },
    {
      title: t("faq_12"),
      description: t("faq_ans_12")
    },
    {
      title: t("faq_13"),
      description: (
        <>
        {t("faq_ans_13_1")} <a href="https://www.pidm.gov.my/" target="blank">www.pidm.gov.my</a> {t("faq_ans_13_2")}
        </>
        )
    }
  ]
  
  return (
    <>
      {
        faq?.length ? (
          <Accordion defaultActiveKey="0" alwaysOpen>
            {faq.map((data, i) => 
              <Accordion.Item className="item" key={i} eventKey={i}>
              <Accordion.Header className="header medium" onClick={() => handleExpandEvent(data.title)}>
                {data.title}
              </Accordion.Header>
              <Accordion.Body className="body">
                {data.description}
              </Accordion.Body>
            </Accordion.Item>
            )}
          </Accordion>
        ) : <div>No data found</div>
      }
    </>
  );
}

function Faq() {
  const { t } = useTranslation();
  return (
    <div className="faqContainer" id="faq">
      <div className="logo-faq medium">
        <div className="title d-flex text-left">{t("faq_title")}</div>
      </div>
      <div className="ExpandCollapse d-flex">
        <ExpandCollapse />
      </div>
      {/* <div className="see-more medium">{t('faq_footer')}</div> */}
    </div>
  );
}

export default Faq;
