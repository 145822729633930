import "../assets/styles/policyDropdown.scss";
import React, { useState } from "react";
import DownArrow from "../assets/images/arrow_down.svg";
import PolicyType from "./PolicyTypeModal";
import PriceSortModal from "./PriceSortModal";
import { useTranslation } from "react-i18next";


function PolicyDropdown(props) {
  const { t } = useTranslation();
  const [, setActive] = useState("");
  const [selectedType, setSelectedType] = useState(localStorage.getItem("oldCar") === "true" ? `${t("third_party_ft")}` : `${t("comprehensive")}`);
  const [selectedPriceType, setSelectedPriceType] = useState(`${t("sort")}: ${t("default")}`);
  // const [total, setTotal] = useState(0);
  function handleClick(buttonIndex) {
    setActive(buttonIndex);
  }
  const handleType = (index) => {
    setSelectedType(index === "01" ? `${t("comprehensive")}` : index === "02" ? `${t("third_party")}` : `${t("third_party_ft")}`)
    props.typeSelection(index);
  }
  const handlePriceSort = (index) => {
    setSelectedPriceType(index === "asc" ? `${t("price_up")}` : `${t("price_down")}`)
    props.priceSortSelection(index);
  }
  const vehData = localStorage.getItem("UserVerification");
  const parsedVehData = JSON.parse(vehData);
  
  return (
    <div className="policy-dropdown-container d-lg-flex d-block">
      <div className="mx-2 d-flex flex-lg-column">
        <p className="mb-0">{localStorage.getVehicleName ? localStorage.getVehicleName : ""} {parsedVehData.makeYear} - <span className="smallPlate">{parsedVehData.vehRegNo}</span></p>
        <p className="largePlate">{parsedVehData.vehRegNo}</p>
      </div>
      <div className="d-flex overflow">
        <div className="policy-dropdown d-flex">
          <button
            type="button"
            // className={isActive === 0 ? "active" : ""}
            onClick={() => handleClick(0)}
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop-2"
            id="policy"
          >
            {t("p_type")}:  {selectedType}{" "}
            <img src={DownArrow} alt="#" width={15} className="m-2" />
          </button>
          <button
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop-1"
            // className={isActive === 1 ? "active" : ""}
            onClick={() => handleClick(1)}
            id="addons"
          >
              {selectedPriceType}{" "}
            <img src={DownArrow} alt="#" width={15} className="m-2" />
          </button>
        </div>
        <PolicyType selection={handleType} vehicleType={props.vehicleType} />
        <PriceSortModal selectionPriceSort={handlePriceSort} />
      </div>
    </div>
  );
}

export default PolicyDropdown;
