import "../../assets/styles/RadioBoxContainer.scss";
import React, { useState } from "react";

function RadioBoxContainer(props) {
  const [check, setCheck] = useState(false);

  // const handleChange = () => {
  //   if(check === false){
  //     setCheck(true)
  //   } else {
  //     setCheck(false)
  //   }
  // }
  const handleChange = (event) => {
    event.stopPropagation()
    props.handleChangeEvent(props.index);
    setCheck(!check);
  };

  return (
    <div className={`d-flex radioBox-container ${check ? "checked" : ""}`}>
      <div className="form-check">
        <input
          className={`form-check-input`}
          type="radio"
          name="flexRadioDefault"
          id={props.id}
          value={props.value}
          onChange={(event) => handleChange(event)}
          defaultChecked={localStorage.getItem("oldCar") === "true" ? props.id === '03' : props.id === '01'}
        />
        <label className="form-check-label" htmlFor={props.id}>
          {props.title && props.title !== "" && (
            <>
              <div className="header" onClick={props.divClick}>
                <div
                  className={`mb-0 mt-1 ${props.isTitleBold ? "medium" : ""}`}
                >
                  {props.title}
                </div>
                {props.amount && <div className="medium">{props.amount}</div>}
              </div>
            </>
          )}
          {props.pop && props.pop !== "" && (
            <p className="popular mt-1">{props.pop}</p>
          )}

          {props.info && props.info !== "" && (
            <p className="info mt-2">{props.info}</p>
          )}
        </label>
      </div>
    </div>
  );
}

export default RadioBoxContainer;
