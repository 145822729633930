import { useLocation } from "react-router-dom";
import { Facebook } from "react-bootstrap-icons";
import { Instagram } from "react-bootstrap-icons";
import { Youtube } from "react-bootstrap-icons";
import TwitterX from "../../assets/images/icon/twitter-x.svg";
import { Tiktok } from "react-bootstrap-icons";
import GooglePlay from "../../assets/images/googlePlay.png";
import AppStore from "../../assets/images/appStore.png";
import AppGallery from "../../assets/images/appGallery.png";
import Drhb from "../../assets/images/DRB_HiCOM_Logo 2.png";
import "../../assets/styles/bottom-footer.scss";


function BottomFooter() {
  const { pathname } = useLocation();
  const currentPathname = pathname.split("/")[1];

  // Function to handle the click event and push data to the dataLayer.
  const handleDownloadAppEvent = (platform) => {
    // On Click of Download app (button)
    window.dataLayer.push({
      event: 'download_app',
      event_category: 'Menu',
      event_action: 'Click Download App',
      event_label: platform,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
    });
  };

  // Function to handle the click event and push data to the dataLayer.
  const handleSocialMediaEvent = (platform) => {
    // On Click of Social Media icon
    window.dataLayer.push({
      event: 'go_to_social_media',
      event_category: 'Menu',
      event_action: 'Go To Social Media',
      event_label: platform,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
    });
  };


  return (
    <>
      {currentPathname === "" &&
        <div className="bottom-footer">
          <div className="container-bottom">
            <div className="download">
              <div className="title medium">Download Our App</div>
              <div className="linksbinder">
                <a href="https://apps.apple.com/my/app/pos-malaysia/id1325952009" target="_blank" rel="noreferrer" onClick={() => handleDownloadAppEvent('Apple App Store')}>
                  <img src={AppStore} alt="app-gallery" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=my.com.pos.posmobile.posmobileapps" target="_blank" rel="noreferrer" onClick={() => handleDownloadAppEvent('Google Play')}>
                  <img src={GooglePlay} alt="app-gallery" />
                </a>
                <a href="https://appgallery.huawei.com/#/app/C101649649" target="_blank" rel="noreferrer" onClick={() => handleDownloadAppEvent('App Gallery')}>
                  <img
                    className="app-gallery"
                    src={AppGallery}
                    alt="app-gallery"
                  />
                </a>
              </div>
            </div>
            <div className="follow">
              <div className="title text-center medium">Follow us on</div>
              <div className="social">
                <a href="https://www.instagram.com/pos4you/" target="_blank" rel="noreferrer" onClick={() => handleSocialMediaEvent('Instagram')}>
                  <Instagram size={25} />
                </a>
                <a href="https://www.youtube.com/c/posmalaysiaberhadofficial" target="_blank" rel="noreferrer" onClick={()=>handleSocialMediaEvent('Youtube')}>
                  <Youtube size={25} />
                </a>
                <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fposmalaysia" target="_blank" rel="noreferrer" onClick={()=>handleSocialMediaEvent('Twitter')}>
                  <img src={TwitterX} alt="Twitter X" style={{ height: 24, width: 24 }}/>
                </a>
                <a href="https://www.facebook.com/posmalaysia" target="_blank" rel="noreferrer" onClick={()=> handleSocialMediaEvent('Facebook')}>
                  <Facebook size={25} />
                </a>
                <a href="https://www.tiktok.com/@posmalaysia" target="_blank" rel="noreferrer" onClick={()=> handleSocialMediaEvent('TikTok')}>
                  <Tiktok size={25} />
                </a>
              </div>
            </div>
          </div>
          <div className="copyright-container">
            <FooterCopyRight />
          </div>
        </div>
      }
    </>
  )
}

export default BottomFooter;

const FooterCopyRight = () => {
    return (
      <div className="footer-copyright">
        <p>
          © 2022 Pos Malaysia Berhad 199101019653 (229990-M). All Rights Reserved.
          A member of{" "}
          <span>
            <img src={Drhb} alt="drhb" />
          </span>
        </p>
      </div>
    );
  };