import PaymentBox from "../../Components/Elements/PaymentBox";
import "../../assets/styles/Checkout.scss";
import { useEffect, useState } from "react";
import StatusModal from "../../Components/StatusModal";
import { useTranslation } from 'react-i18next';
import Stepper from "../../Components/Elements/Stepper";
import axios from "axios";
// import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoaderModal2 from "../../Components/LoaderModal2";

function Checkout(props) {
  if(localStorage.userInitilData === undefined) {window.location.replace('/');}
  const {t} = useTranslation();
  let navigate = useNavigate();
  const originUrl = window.location.origin;
  const [status, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t('all_error_req'));
  const [type, setType] = useState("error");
  const [showLoader , setShowLoader ] = useState(true);
  const [attention, setAttention] =useState(false);
  const [expired, setExpired] = useState(false);
  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  const localData = JSON.parse(localStorage.PaymentboxDetails);
  const localDataVixncd = JSON.parse(localStorage.UserVerification);
  const url = process.env.REACT_APP_AWS_URL;
  const payment = {
    title: "Premium Details",
    plan: "Allianz Longname Comprehensive",
    coverage: "30/12/2022 - 30/12/2023",
    sum_insured: "RM 10,000",
    total: "RM834.000",
    total_highlight: "Price breakdown",
    buttonLabel: `${t("pay")}`,
    sections: [
      {
        details: [
          {
            label: "Basic Premium/Contribution",
            value: "RM 1,718.69",
          },
          {
            label: "NCD (55%)",
            value: "-RM 845.28",
          },
          {
            label: "Personal Accident Cover",
            value: "RM 10.00",
          },
        ],
      },
      {
        title: "Add-Ons:",
        details: [
          {
            label: "Windscreen",
            value: "RM 25.00",
          },
          {
            label: "Additional Driver",
            value: "RM 10.00",
          },
          {
            label: "Legal Liability Passenger",
            value: "RM 15.00",
          },
          {
            label: "For Negligence Acts Special Peril",
            value: "RM 20.00",
          },
        ],
      },
      {
        details: [
          {
            label: "Gross Premium Contribution",
            value: "RM 967.99",
          },
          {
            label: "Rebate Amount",
            value: "-RM 18.10",
          },
          {
            label: "SST (8%)",
            value: "RM 23.22",
          },
          {
            label: "Stamp Duty",
            value: "RM 10.00",
          },
        ],
      },
      {
        details: [
          {
            label: "Total",
            value: "RM 1,234.00",
            isLabelBold: true,
            isValueBold: true,
          },
        ],
      },
    ],
  };
  for(let i=0; i<localData.addBenData?.item.length; i++){
    if(localData.addBenData.item[i].benCode === "ALLD") localData.addBenData.item[i].benDesc = "Unlimited Drivers"
    if(localData.addBenData.item[i].benCode === "AND") localData.addBenData.item[i].benDesc = "Additional Drivers"
    if(localData.addBenData.item[i].benCode === "WSC") localData.addBenData.item[i].benDesc = "Windscreen"
    if(localData.addBenData.item[i].benCode === "CVPALL01FLD") localData.addBenData.item[i].benDesc = "Special Peril Coverage"
    if(localData.addBenData.item[i].benCode === "LLOP") localData.addBenData.item[i].benDesc = "Legal Liability of Passengers"
    if(localData.addBenData.item[i].benCode === "CVPALL02LLTP") localData.addBenData.item[i].benDesc = "Legal Liability of Passengers"
    if(localData.addBenData.item[i].benCode === "LLTP") localData.addBenData.item[i].benDesc = "Legal Liability of Passengers for Acts of Negligence"
    if(localData.addBenData.item[i].benCode === "CVPALL02LLOP") localData.addBenData.item[i].benDesc = "Legal Liability of Passengers for Acts of Negligence"
  }
  const [firstLineAddress, secondLineAddress, thirdLineAddress, fourthLineAddress] = customerDetails?.address?.split(",");
  const mobile = customerDetails.mobile.slice(0, 1) + "-" + customerDetails.mobile.slice(1);
  const fullName = customerDetails.fullName.split(" ").map((name) => name.charAt(0).toUpperCase() + name.slice(1)).join(" ");
  const newIc = localData.newIc.slice(0, 6) + "-" + localData.newIc.slice(6, 8) + "-" + localData.newIc.slice(8);
  // const model = localDataVixncd.arrResExtraParam?.item[3]?.value.charAt(0) + localDataVixncd.arrResExtraParam?.item[3]?.value.slice(1).toLowerCase();
  var gender = localData.newIc !== '' ? parseInt(localData.newIc.at(-1)) % 2 === 0 ? 'F' : 'M' : localData.gender.at(0);
  const getSelectedVariant = localStorage.selectedVariant?.split(',');
  let selectedVariant;
  if(localStorage.selectedVariant !== undefined){
    if(getSelectedVariant[2] === ''){getSelectedVariant.pop()}
    selectedVariant = getSelectedVariant?.join('*');
  }
  var makeCodeMinor = localDataVixncd?.arrResExtraParam.item[localDataVixncd.arrResExtraParam.item.findIndex(item => item.indicator === "DISTRIBUTOR_MODELCODE")];
  makeCodeMinor = getSelectedVariant ? selectedVariant : localStorage.getItem('userSelectedMinorCode') ? localStorage.getItem('userSelectedMinorCode') : makeCodeMinor.value.split('|')[0];

  const handleCloseStatusModal = () => {
    setStatus(false)
    if(expired === true){
      navigate("/");
    } 
  }

  let dateTempArray = localDataVixncd.expiryDate.split("/");
  let expiryDateFormatted = `${dateTempArray[2]}-${dateTempArray[1]}-${dateTempArray[0]}`;

  const requestBodyInsurance = {
    "compCode": localData.compCode,
    "actPrem": localData.actPrem,
    "address1": customerDetails.address.toUpperCase(),
    "address2": localStorage.city,
    "agentCode":"POS",
    "flquoteNo": localStorage.quote,
    "coverCode":localData.coverCode,
    "address3": localStorage.state.toUpperCase(),
    "antitd": localData.antitd,
    "name": customerDetails.fullName,
    "bankAppCode": "", //try find from callback
    "ccardExpdt": "", //make empty if couldnt find
    "ccardType": "", //same above
    "newIc": localData.newIc,
    "passportNo": localData.passportNo,
    "channel": localData.channel,
    "claimAmt": localData.claimAmt,
    "cnCondition": "n",
    "cnPaystatus:": "c",
    "commGstAmt": localData.commGstAmt,
    "commiamt": localData.commiamt,
    "commiPerc": localData.commiPerc,
    "discountAmt": localData.discountAmt,
    "discountPerc": localData.discountPerc,
    "driveExp": localData.driveExp,
    "email": customerDetails.email,
    "excess": localData.excess,
    "garage": localData.garage,
    "grossDue": localData.grossDue,
    "grossDue2": localData.grossDue2,
    "grossPrem": localData.grossPrem,
    "gstAmt": localData.gstAmt,
    "gstClaimPerc": localData.gstClaimPerc,
    "hPhoneNo": customerDetails.mobile,
    "insertStmp": localData.insertStmp,
    "loadingAmt": localData.loadingAmt,
    "loadingPerc": localData.loadingPerc,
    "ncdAmt": localData.ncdAmt,
    "netDue": localData.netDue,
    "netDue2": localData.netDue2,
    "netPrem": localData.netPrem,
    "occupMajor": localData.occupMajor,
    "payAmt": 0,
    "payType": "", //change based on the response kipple
    "preInsNcd": localData.preInsNcd,
    "preinsRegNo": localData.preinsRegNo,
    "pScoreOriLoading": 0.0,
    "purchasePrice": localData.purchasePrice,
    "purpose": localData.purpose,
    "serviceTaxAmt": localData.serviceTaxAmt,
    "serviceTaxPerc": localData.serviceTaxPerc,
    "stampDuty": localData.stampDuty,
    "stateCode": localData.stateCode.toUpperCase(),
    "tariffPremium": localData.tariffPremium,
    "theftClaim": localData.theftClaim,
    "thirdClaim": localData.thirdClaim,
    "townDesc": localData.townDesc,
    "variantName": localStorage?.getItem("getVehicleName") ? localStorage?.getItem("getVehicleName") : "",
    "vehBody": localData.vehBody,
    "vehBodyCode": localData.vehBodyCode,
    "vehClaim": localData.vehClaim,
    "receiptNo": "A18390230",
    "gender": gender,
    "birthDate": localData.birthDate ? localData.birthDate : localStorage.getItem("dateOfBirth"),
    "postCode": localData.postCode,
    "maritalStatus": localData.maritalStatus === "M" ? "M" : "S",
    "makeYear": localDataVixncd.makeYear,
    "regNo": localData.regNo,
    "chassisNo": localDataVixncd.chassisNo,
    "sumInsured": localData.sumInsured,
    "engineNo": localDataVixncd.engineNo,
    "makeCodeMajor": localDataVixncd.ismMakeCode,
    "makeCodeMinor": makeCodeMinor, 
    "vehCapacity": parseInt(localDataVixncd.vehCapaCity),
    "vehCapacityCode": localData.vehCapacityCode,
    "seatCapacity": JSON.parse(localStorage.isCar) !== true ? "2" : "5",
    "vehTypeCode": localDataVixncd.vehTypeCode,
    "effectiveDate": localStorage.getItem("effectiveDate"),
    "effectiveTime": localStorage.getItem("effectiveTime"),
    "expiryDate": localStorage.getItem("expiryDateFormatted"),
    "piamDrv": JSON.parse(localStorage.isCar) ? "03" : "05",
    "ncdPerc": localDataVixncd.ncdPerc,
    "ownershipType": "I",
    "oldExpiryDate": expiryDateFormatted,
    "useCode": localDataVixncd.veHuseCode,
    "benefit": localData.addBenData?.item,
    "addon": localData.addDrvData?.item,
    "drvAge": localStorage.customerAge,
    "drvGender": localStorage.customerGender,
    "posStaffDisc": customerDetails.referralCode
  }

  const requestBodyInitpayment = {
    "payment_gateway": "kiplepay",
    "application_name": "samurai",
    "return_url": `${process.env.REACT_APP_PGW_URL}process_payment`,
    "redirect_success_url": `${originUrl}/payment-status?status=success`,
    "redirect_failure_url": `${originUrl}/payment-status?status=error`,
    "redirect_abort_url": localData.regNo,
    "order_total_amount": localData.grossDue.toFixed(2),
    "order_gst_amount": "0",
    "order_ship_name": customerDetails.fullName,
    "order_ship_country": "Malaysia",
    "order_telephone": customerDetails.mobile,
    "order_email": customerDetails.email,
    "order_delivery_charges": "0",
    "order_service_charges": "0",
    "order_reference": "AXIS-7",
    "generate_html_form": "true",
    "dynamic_callback_url": `${process.env.REACT_APP_AWS_URL}payment/callback-url`,
  }

  const header = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem('api_token'),
      "X-Api-Key": process.env.REACT_APP_AUTH_KEY
    }
  };

  const selectedAddons = JSON.parse(localStorage.getItem("addonsBenefit"));
  var found = false;
  if(selectedAddons !== null){
    for( let i=0; i<selectedAddons.length; i++){
      if(selectedAddons[i].benCode === "AND"){
        found = true;
        break;
      } 
    }
  }

  const requestBody = {
    "actualMarketValue" : localStorage.getItem("actMarket")?localStorage.getItem("actMarket") : "",
    "actualSumInsured" : localStorage.getItem("actSum")? localStorage.getItem("actSum") : "",
    "compCode": localStorage.getItem("selectedCode"),
    "agentCode":"POS",
    "name": customerDetails.fullName,
    "newIc": localData.newIc,
    "address1": customerDetails.address.toUpperCase(),
    "address2": localStorage.city,
    "email": customerDetails.email,
    "passportNo": localData.passportNo,
    "coverCode": localStorage.getItem("coverCode"), 
    "channel": "POS",
    "gender": gender,
    "hphoneNo": customerDetails.mobile,
    "birthDate": localData.birthDate ? localData.birthDate : localStorage.getItem("dateOfBirth"),
    "postCode": localData.postCode,
    "maritalStatus": localData.maritalStatus === "S" ? "S" : "M",
    "makeYear": localDataVixncd.makeYear,
    "regNo": localData.regNo,
    "chassisNo": localDataVixncd.chassisNo,
    "sumInsured": localStorage.getItem("chosedCoverage") === null ? localStorage.actSum : localStorage.chosedCoverage === "0" ? localStorage.actSum : localStorage.actMarket,
    "engineNo": localDataVixncd.engineNo,
    "makeCodeMajor": localDataVixncd.ismMakeCode,
    "makeCodeMinor": makeCodeMinor,
    "vehCapacity": parseInt(localDataVixncd.vehCapaCity),
    "requestId": localStorage.getItem("requestID")? localStorage.getItem("requestID") : "",
    "keepFlag":"Y",
    "vehCapacityCode": "CC",
    "seatCapacity": JSON.parse(localStorage.isCar) !== true ? "2" : "5",
    "vehTypeCode": localDataVixncd.vehTypeCode,
    "effectiveDate": localStorage.getItem("effectiveDate"),
    "effectiveTime": localStorage.getItem("effectiveTime"),
    "expiryDate": localStorage.getItem("expiryDateFormatted"),
    "piamDrv": JSON.parse(localStorage.isCar) ? "03" : "05",
    "ncdPerc": localDataVixncd.ncdPerc,
    "ownershipType": "I",
    "useCode": localDataVixncd.veHuseCode,
    "addBenData":{
      "item": JSON.parse(localStorage.getItem("addonsBenefit"))
    },
    "addDrvData": {
      "item": found ? JSON.parse(localStorage.getItem("driversName")): []
    }
  }

  const fetchpremium = (requestBody) => {
    axios
        .post(`${url}api/premium`, requestBody, header)
        .then(function (data) {
          localStorage.setItem('quote', data.data.data.item[0].flQuoteNo)
          if(data.data.data.item[0].vehTypeCode === "CVM"){
            localStorage.isCar = false;
            localStorage.isBike = true;
          } else {
            localStorage.isCar = true;
            localStorage.isBike = false;
          }
          localStorage.setItem("PaymentboxDetails", JSON.stringify(data?.data.data.item[0]));
          setShowLoader(false);
        })
        .catch(function (error) {
          if(error.code === "ERR_NETWORK"){
            setShowLoader(false);
            setErrorMessage(t("expired"));
            setStatus(true);
            setExpired(true);
          } else {
            setErrorMessage(t("error1"));
            setStatus(true);
            setShowLoader(false);
          }
          
        });
      }

  useEffect(()=>{
    fetchpremium(requestBody);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handlePay = () => {
    fetch(`${url}api/insurance`, {
      method: "POST",
      headers: header.headers,
      body: JSON.stringify(requestBodyInsurance)
    })
      .then(response => response.json())
      .then(data => {
        let response = data.data;
        localStorage.setItem("refId", JSON.stringify(response.id))
        requestBodyInitpayment.order_reference = response.id;
        //requestBodyInitpayment.return_url = response.callbackUrl;
        handleInitpayment(requestBodyInitpayment)
      }).catch(error => {
      });
  };

  const handleInitpayment = (rqstBody) => {
    fetch(`${process.env.REACT_APP_AWS_URL}payment/submit-to-pgw`, {
      method: "POST",
      headers: header.headers,
      body: JSON.stringify(rqstBody)
    })
      .then(response => response.json())
      .then(data => {
        handlePaymentGatewayrequest(data);
        localStorage.setItem("ordRef", data.data.ord_mercref);
      }).catch(error => {
        setStatus(true);
      });
  }

  const handlePaymentGatewayrequest = (data) => {
    //the below one line is only for test purpose, production doesn't needed the replace method uncomment the line below that.
    var paymentForm = data.data.payment_gateway_form.replace("https://uat.kiplepay.com/wcgatewayinit.php", "https://intstaging.webcash.com.my/Gateway")
    //var paymentForm = data.data.payment_gateway_form;
    document.body.innerHTML += paymentForm;
    document.querySelector('input[name="submit"]').setAttribute("id", "submit_btn");
    document.getElementById('submit_btn').click();
  }

  localStorage.setItem('purchaseFired', 0);

  // const [finishStatus, setfinishStatus] = useState(false);

//   const onBackButtonEvent = (e) => {
//     e.preventDefault();
//     setStatus(true)
//     setErrorMessage(t("error8"))
//     // if (!finishStatus) {
//     //     if (window.confirm("Do you want to go back ? You will lose all you data and start from the beginning")) {
//     //         setfinishStatus(true)
//     //         // your logic
//     //         window.history.back();
//     //         // props.history.push("/");
//     //     } else {
//     //         window.history.pushState(null, null, window.location.pathname);
//     //         setfinishStatus(false)
//     //     }
//     // }
// }
// useEffect(() => {
//   window.history.pushState(null, null, window.location.pathname);
//   window.addEventListener('popstate', onBackButtonEvent);
//   return () => {
//     window.removeEventListener('popstate', onBackButtonEvent);  
//   };
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

const initialSteps = [
  { value: 1, label: "Plans", isActive: false, isRenderComponent: false },
  { value: 2, label: "Add-Ons", isActive: false, isRenderComponent: false },
  {
    value: 3,
    label: "Customer Details",
    isActive: false,
    isRenderComponent: false,
  },
  { value: 4, label: "Checkout", isActive: true, isRenderComponent: true },
];

  return (
    <>
      <div
        className="mt-4 subBlueTitle cursor-pointer "
        onClick={() => navigate(-1)}
      >
        {t("bck_button")}
      </div>

      <div className="quotation-wrapper-stepper">
        <Stepper steps={initialSteps} activeStep={3} />
      </div>
      <div className="checkout">
        <div className="options">
          <div className="information">
            <div className="title">
              <span className="medium subBlueTitle">
                {t("confirm_customer")}{" "}
              </span>{" "}
              {/* <small className="small">(for Road Tax delivery)</small> */}
            </div>
            <div className="section">
              <div className="section">
                <small className="small">{t("full_name")}</small>
                <div className="medium">{fullName}</div>
              </div>
              <div className="section">
                <small className="small">
                  {t("owner")} {newIc !== "--" ? "NRIC" : "Passport ID"}
                </small>
                <div className="medium">
                  {newIc !== "--" ? newIc : localData.passportNo}
                </div>
              </div>
              <small className="small">{t("current_address")}</small>
              <div className="address">
                <div className="medium">
                  {firstLineAddress}{" "}
                  {secondLineAddress ? "," + secondLineAddress : ""}
                </div>
                <div className="medium">
                  {thirdLineAddress ? thirdLineAddress + "," : ""}{" "}
                  {fourthLineAddress ? fourthLineAddress + "," : ""}{" "}
                  {customerDetails.postcode}
                </div>
                <div className="medium">
                  {localStorage.city}, {localStorage.state.toUpperCase()}.
                </div>
              </div>
            </div>
            <div className="section">
              <small className="small">{t("mobile")}</small>
              <div className="medium">+6{mobile}</div>
            </div>
            <div className="section">
              <small className="small">{t("email")}</small>
              <div className="medium">{customerDetails.email}</div>
            </div>
            <div className="section">
              <small className="small">{t("marital_status")}</small>
              <div className="medium">
                {localData.maritalStatus === "M"
                  ? t(t("v_f_marital_m"))
                  : t(t("v_f_marital_s"))}
              </div>
            </div>

            <div className="section">
              <small className="small">{t("vehicle_reg")}</small>
              <div className="medium">{localDataVixncd.vehRegNo}</div>
            </div>
            <div className="section">
              <small className="small">{t("vehicle_details")}</small>
              <div className="medium">
                {localStorage.getVehicleName} {localDataVixncd.makeYear}
              </div>
            </div>
            {customerDetails.referralCode && (
              <div className="section">
                <small className="small">Referral Code</small>
                <div className="medium">{customerDetails.referralCode}</div>
              </div>
            )}
          </div>
          <div className="information">
            {/* <div className="title">
              <span className="medium subBlueTitle">Vehicle Details</span>
            </div> */}
          </div>
        </div>

        {/* Payment Box Section */}
        {/* <PaymentBox
          handlePrev={() => handlePay}
          payment={payment}
          premiumdetails={JSON.parse(localStorage.getItem("PaymentboxDetails"))}
          logoarray={props.logoArray}
          isAmountReady={true}
          pay={true}
          check={true}
        /> */}
        <PaymentBox
          payment={payment}
          premiumdetails={JSON.parse(localStorage.getItem("PaymentboxDetails"))}
          handlePrevious={() => {
            // if(process.env.REACT_APP_STAGE === "prod"){
            //   setStatus(false);
            // } else {
            setStatus(true);
            setErrorMessage(t("pay_attention"));
            setType("");
            setAttention(true);
            // }
          }}
          logoarray={props.logoArray}
          isAmountReady={true}
          pay={true}
        />
      </div>
      <div className={`declarations small`}>
        By clicking the “Pay” button, you hereby expressly consent to share you
        Personal Data subject to the following{" "}
        <span className="subBlueTitle">
          <a href="terms">Terms and Conditions</a>
        </span>
        , accept the Partner's, read and understood the{" "}
        <span className="subBlueTitle">
          <a href="terms">Product Disclosure Sheet</a>
        </span>{" "}
        by the Partner and acknowledge you do not have 2 or more claims in the
        past 3 years.
      </div>
      {showLoader && <LoaderModal2 show={showLoader} />}
      <StatusModal
        status={status}
        handleClose={() => handleCloseStatusModal()}
        type={type}
        message={errorMessage}
        attention={attention}
        pay={handlePay}
      />
    </>
  );
}

export default Checkout;
