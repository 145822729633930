import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent'
 
function relicDev() {
  const licenseK = process.env.REACT_APP_RELIC_LICENSEKEY
  const relicAccID = process.env.REACT_APP_RELIC_ACCID
  const relicAppID = process.env.REACT_APP_RELIC_APPID
  const relicTrustKey = process.env.REACT_APP_RELIC_TRUSTKEY
  const options = {
    init: {
      distributed_tracing:{enabled:true,cors_use_newrelic_header:true,cors_use_tracecontext_headers:true,allowed_origins:['https://d01-fin-ins.uat-pos.com']},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]},
    }, // NREUM.init
    info: {
      beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:licenseK,applicationID:relicAppID,sa:1
    }, // NREUM.info
    loader_config: {
      accountID:relicAccID,trustKey:relicTrustKey,agentID:relicAppID,licenseKey:licenseK,applicationID:relicAppID
    } // NREUM.loader_config
  };

  new BrowserAgent(options)
}

export default relicDev;


  // const relicID = process.env.REACT_APP_STAGE === "prod" ? new BrowserAgent(optionsProd) : new BrowserAgent(options);
  // console.log(relicID)

