import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import StatusModal from "../Components/StatusModal"
import { useTranslation } from 'react-i18next';

function PaymentStatus() {
  const {t} = useTranslation();
  const [searchParams,] = useSearchParams();
  const paymentStatus = searchParams.get('status');
  const [status, setStatus] = useState(true);
  const [type,] = useState(paymentStatus);
  const premiumDetails = JSON.parse(localStorage.getItem("PaymentboxDetails"));

  const [pStatus,setPStatus] = useState('');

  useEffect(()=> {
    if(paymentStatus === 'error' || paymentStatus === 'success'){
      setPStatus(paymentStatus)
    }
  },[pStatus, paymentStatus])

  const logoArray = {
    "13": { "name": "ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD"},
    "18": { "name": "ZURICH GENERAL INSURANCE MALAYSIA BERHAD" },
    "97": { "name": "ZURICH GENERAL TAKAFUL MALAYSIA BERHAD"},
    "96": { "name": "ETIQA GENERAL TAKAFUL BERHAD"},
    "91": { "name": "TOKIO MARINE INSURANS (MALAYSIA) BERHAD"},
    "21": { "name": "RHB INSURANCE BERHAD" },
    "40": { "name": "TUNE PROTECT MALAYSIA" },
  }
  const [policyTypes] = useState({
    "01": `${t("comprehensive")}`,
    "02": `${t("third_party")}`,
    "03": `${t("third_party_ft")}`,
  });

  const selectedItem = logoArray[premiumDetails?.compCode]?.name.split(" ")[0]+" " + logoArray[premiumDetails?.compCode]?.name.split(" ")[1]+" "+ policyTypes[localStorage.coverCode]

  if (paymentStatus === "error" || paymentStatus === "success") {
    if (!window.dataLayer) {
      window.dataLayer = []; // Initialize the dataLayer array if it's undefined
    }
  }
  if(paymentStatus === 'success' && localStorage.purchaseFired !== "1"){
    window.dataLayer.push({
      event: "purchase",
      event_category: "Payment Status",
      event_action: "Purchase",
      event_label: selectedItem,
      transaction_id: JSON.parse(localStorage.getItem("refId")),
      currency: "MYR",
      value: premiumDetails?.grossDue,
      vehicle_type: localStorage.isCar === "true" ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
      vehicle_make: localStorage.vehicleMake,
      vehicle_variant: localStorage.getVehicleName,
      items: [
        {
          item_id: premiumDetails?.compCode,
          item_name: selectedItem,
          item_brand: logoArray[premiumDetails?.compCode]?.name.split(" ")[0],
          item_category: "Vehicle Insurance",
          item_category_2: localStorage.isCar === 'true' ? "Car" : "Motorcycle",
          item_category_3: policyTypes[localStorage.coverCode],
          item_variant: "Market Value ",

          currency: "MYR",
          price: premiumDetails?.grossDue,
          quantity: "1",
        },
      ],
    });
    localStorage.setItem('purchaseFired', 1);
  } else if(paymentStatus === 'error' && localStorage.purchaseFired !== "1"){
    window.dataLayer.push({
      event: "purchase_failure",
      event_category: "Payment Status",
      event_action: "Purchase Failure",
      event_label: selectedItem,
      transaction_id: JSON.parse(localStorage.getItem("refId")),
      currency: "MYR",
      value: premiumDetails?.grossDue,
      vehicle_type: localStorage.isCar === "true" ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
      items: [
        {
          item_id: premiumDetails?.compCode,
          item_name: selectedItem,
          item_brand: logoArray[premiumDetails?.compCode]?.name.split(" ")[0],
          item_category: "Vehicle Insurance",
          item_category_2: localStorage.isCar === "true" ? "Car" : "Motorcycle",
          item_category_3: policyTypes[localStorage.coverCode],
          item_variant: "Market Value ",

          currency: "MYR",
          price: premiumDetails?.grossDue,
          quantity: "1",
        },
      ],
    });
    localStorage.setItem('purchaseFired', 1);
  }

  const [message,] = useState(paymentStatus === "error" ? t('all_error_req') :  <div>{t("payment_msg_1")}</div>);
  return (
    <>
      <StatusModal paymentStatus={pStatus} status={status} handleClose={() => setStatus(false)} type={type} message={message} triggeredfrom={"payment"} refId={JSON.parse(localStorage.getItem("refId"))} copyMessage = {<div className="bold">{t("payment_msg_2")}<br/>{t("payment_msg_3")}</div>}/>
    </>

  );
}

export default PaymentStatus;