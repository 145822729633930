import "../../assets/styles/Toggle.scss"

function Toggle(props) {
  return (
    <>
        <div className="toggleComponent">
          {props.label && <span className="m-3 label">{props.label}</span>}
          <label className="switch">
              <input type="checkbox" onChange={()=> props.toggle()} checked={props.check}/>
              <span className="slider round"></span>
          </label>
        </div>
    </>
  );
}

export default Toggle;
