import { Stepper as ReactStepper } from 'react-form-stepper';
import "../../assets/styles/Stepper.scss"
import { t } from 'i18next';

function Stepper(props) {
  return (
    <>
      <div className="stepper">
        <ReactStepper
          steps={[{ label: t("stepper1") }, { label: t("stepper2") }, { label: t("stepper3") }, { label: t("stepper4") }]}
          // activeStep={props.steps.find(step => step.isRenderComponent).value - 1}
          activeStep={props.activeStep}
        />
      </div>
    </>
  );
}

export default Stepper;
