import { Modal ,ProgressBar } from "react-bootstrap";
import React from 'react';
import "../assets/styles/Loader.Modal.scss";
import sedan from "../assets/images/sedan 1.svg";
import moped from "../assets/images/moped 1.svg";
import { useTranslation } from "react-i18next";
// import Spinner from 'react-bootstrap/Spinner';

function LoaderModal2(props) {

  const { t } = useTranslation();
  const [countOfProgess, setCountOfProgess] = React.useState(0);
  const maxLeftPosition = 78;
  const imageLeftPosition = `${Math.min(countOfProgess, maxLeftPosition)}%`;
 
  React.useEffect(() => {
    const timer = setInterval(() => {
      setCountOfProgess((oldProgress) => {
        if (100 === oldProgress) return 0;
        return Math.min(oldProgress + Math.random() * 10, 100);
      });
    }, 499);
 
    return () => {
      clearInterval(timer);
    };
  }, []);

  const isCar = localStorage.getItem('isCar') === 'true';
  // const isBike = localStorage.getItem('isBike') === 'true';

  if (isCar) {
    return (
      <>
        <Modal show={props.show} className="loader-modal-container-2">
        <div className="image-wrapper" style={{ left: imageLeftPosition }}>
            {isCar ? <img src={sedan} alt="sedan" /> : <img src={moped} alt="moped" />}
          </div>
          <div className="progress-container"> 
            <ProgressBar now={countOfProgess} variant="primary" />
            {/* <h5 className="text-percent">{parseInt(countOfProgess)} %</h5> */}
            <br />
            <h6 className="text">{t("loading_otw")}</h6>
          </div>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Modal show={props.show} className="loader-modal-container-2">
        <div className="image-wrapper" style={{ left: imageLeftPosition }}>
            {isCar ? <img src={sedan} alt="sedan" /> : <img src={moped} alt="moped" />}
          </div>
          <div className="progress-container"> 
            <ProgressBar now={countOfProgess} variant="primary" />
            {/* <h5 className="text-percent">{parseInt(countOfProgess)} %</h5> */}
            <br />
            <h6 className="text">{t("loading_otw")}</h6>
          </div>
        </Modal>
      </>
    );
  }
}

export default LoaderModal2;
