import AddOnsContainer from "../Components/AddOnsContainer";
import AdvantageContainer from "../Components/AdvantageContainer";
import AnimArrow from "../Components/AnimArrow";
import BrandList from "../Components/BrandList";
import CompContainer from "../Components/CompContainer";
import Faq from "../Components/Faq";
import ProcessContainer from "../Components/ProcessContainer";
import RoadtaxContainer from "../Components/Roadtax";
import StepsContainer from "../Components/StepsContainer";
import VhlTypeContainer from "../Components/VhlTypeContainer";
import "../assets/styles/Home.scss";

function Home() {
     return (
          <>
               <div className="">
                    <div className="bg">
                         <VhlTypeContainer />
                    </div>
               </div>
               <BrandList />
               <AnimArrow />
               <AdvantageContainer/>
               <StepsContainer/>
               <CompContainer />
               <AddOnsContainer/>
               <RoadtaxContainer/>
               <ProcessContainer/>
               <Faq/>
          </>
     );
}

export default Home;