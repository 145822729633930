import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import React,{useEffect} from 'react';
import TagManager from 'react-gtm-module';


import About from "./Pages/About";
import Home from "./Pages/Home";
import Profile from "./Pages/Profile";
import Header from './Components/Common/Header';
import ErrorPage from "./Pages/404";

import "./App.scss";
import Footer from "./Components/Common/Footer";
import "./assets/styles/styles.scss";
import BottomFooter from "./Components/Common/BottomFooter";
import Quotation from "./Pages/Quotation";
import UserVerification from "./Features/UserVerification";
import PaymentStatus from "./Pages/PaymentStatus"
import Plans from "./Features/Quotation/Plans";
import AddOns from "./Features/Quotation/AddOns";
import CustomerDetails from "./Features/Quotation/CustomerDetails";
import Checkout from "./Features/Quotation/Checkout";
import Stepper from "./Components/Elements/Stepper";

import relicDev from "./relicDev";
import relicProd from "./relicProd";

function App() {

  const initialSteps = [
    { value: 1, label: "Plans", isActive: true, isRenderComponent: true },
    { value: 2, label: "Add-Ons", isActive: false, isRenderComponent: false },
    {
      value: 3,
      label: "Customer Details",
      isActive: false,
      isRenderComponent: false,
    },
    { value: 4, label: "Checkout", isActive: false, isRenderComponent: false },
  ];

  var currentURL = window.location.search;
  var urlParams = new URLSearchParams(currentURL);
  var status = false;
  var paramsToUpdate = {};
  urlParams.forEach(function(value, paramName) {
    paramsToUpdate[paramName] = value;
    if(paramName === 'status'){
      status = true;
    }
  });

  if(!status){
    localStorage.setItem('gclid', JSON.stringify(paramsToUpdate))
  }

  if (process.env.REACT_APP_STAGE === "prod") {
    relicProd()
  }else{
    relicDev()
  }

urlParams.forEach(function(value, paramName) {
  paramsToUpdate[paramName] = value;
  if(paramName === 'status'){
    status = true;
  }
});
  // Check if the "gclid" parameter is present
  if(!status){
    localStorage.setItem('gclid', JSON.stringify(paramsToUpdate))
  }

  useEffect(()=> { 
    TagManager.initialize({gtmId:'GTM-TPJJT9F'});
  },[]);
  return (
    <>
      <Router>

        <div className="container">
        <Header></Header>
          <div className='quotation-wrapper-stepper display-none'>
        <Stepper steps={initialSteps} activeStep={3} />
      </div>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/user-verification/:id" element={<UserVerification />} />
            <Route path="/quotation/:regNo" element={<Quotation />} />
            <Route path="/quotation/plans" element={<Plans />} />
            <Route path="/quotation/addons" element={<AddOns />} />
            <Route path="/quotation/customer-details" element={<CustomerDetails />} />
            <Route path="/quotation/checkout" element={<Checkout />} />
            <Route path="/payment-status" element={<PaymentStatus />} />
            <Route path="*" element={<ErrorPage />} />
          </Routes>
          <Footer />
        </div>
        <BottomFooter />
      </Router>
    </>
  );
}

export default App;
