import { Button, Modal } from "react-bootstrap";
import "../assets/styles/Status.Modal.scss";
import { ExclamationCircle } from "react-bootstrap-icons";
import { Check2Circle } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { hashString } from 'react-hash-string';
import { resetLocalHost } from "../utils/LocalhostUtils";
import { t } from "i18next";

function StatusModal(props) {
  //const [color] = useState(props.type);
  let navigate = useNavigate();
  // const premiumDetails = JSON.parse(localStorage.getItem("PaymentboxDetails"));
  const color = props.type;
  // const localUserData = localStorage.userInitilData && JSON.parse(localStorage.userInitilData);
  var userId = 0;
  if (localStorage?.userInitilData) {
    const localUserData = JSON.parse(localStorage.userInitilData);
    userId = localStorage?.userInitilData?.newIc !== '' ? localUserData.newIc : localUserData?.passportNo;
  }

  const plansInitialLoad = localStorage?.plansLoadingFirstTime && JSON.parse(localStorage?.plansLoadingFirstTime);
  const handlePaymentStatus = () => {
    if (props.triggeredfrom && props.triggeredfrom === "payment") {
      resetLocalHost()
      window.dataLayer.push({
        event: "go_to_home",
        event_category: "Payment Status",
        event_action: "Go To Home",
        event_label: "Home",
        vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      });
      window.location.replace('https://roadtax.pos.com.my/');

    } else {
      props.handleClose();
    }
  }

  const handleDoneRetry = () =>{
    // On Click of Try Again
    window.dataLayer.push({
      event: "go_to_page",
      event_category: "Payment Status",
      event_action: "Go To Page",
      event_label: "Home",
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
    });
  }

  // const logoArray = {
  //   "13": { "name": "ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD"},
  //   "18": { "name": "ZURICH GENERAL INSURANCE MALAYSIA BERHAD" },
  //   "97": { "name": "ZURICH GENERAL TAKAFUL MALAYSIA BERHAD"},
  //   "96": { "name": "ETIQA GENERAL TAKAFUL BERHAD"},
  //   "91": { "name": "TOKIO MARINE INSURANS (MALAYSIA) BERHAD"},
  //   "21": { "name": "RHB INSURANCE BERHAD" },
  // }
  // const [policyTypes] = useState({
  //   "01": `${t("comprehensive")}`,
  //   "02": `${t("third_party")}`,
  //   "03": `${t("third_party_ft")}`,
  // });

/**
  useEffect (() =>{

    // const selectedItem = logoArray[premiumDetails?.compCode]?.name.split(" ")[0]+" " + logoArray[premiumDetails?.compCode]?.name.split(" ")[1]+" "+ policyTypes[localStorage.coverCode]

    if (props.paymentStatus === "error" || props.paymentStatus === "success") {
      if (!window.dataLayer) {
        window.dataLayer = []; // Initialize the dataLayer array if it's undefined
      }
    }
    if (props.paymentStatus === "error") {
      window.dataLayer.push({
        event: "purchase_failure",
        event_category: "Payment Status",
        event_action: "Purchase Failure",
        event_label: selectedItem,
        transaction_id: props.refId,
        currency: "MYR",
        value: premiumDetails?.grossDue,
        vehicle_type: localStorage.isCar === "true" ? "car" : "motorcycle",
        customer_age: localStorage.customerAge,
        customer_gender: localStorage.customerGender,
        items: [
          {
            item_id: premiumDetails?.compCode,
            item_name: selectedItem,
            item_brand: logoArray[premiumDetails?.compCode]?.name.split(" ")[0],
            item_category: "Vehicle Insurance",
            item_category_1: localStorage.isCar === true ? "Motorcycle" : "Car",
            item_category_2: policyTypes[localStorage.coverCode],
            item_variant: "Market Value ",

            currency: "MYR",
            price: premiumDetails?.grossDue,
            quantity: "1",
          },
        ],
      });
    } else if (props.paymentStatus === "success") {
      window.dataLayer.push({
        event: "purchase",
        event_category: "Payment Status",
        event_action: "Purchase",
        event_label: selectedItem,
        transaction_id: props.refId,
        currency: "MYR",
        value: premiumDetails?.grossDue,
        vehicle_type: localStorage.isCar === "true" ? "car" : "motorcycle",
        customer_age: localStorage.customerAge,
        customer_gender: localStorage.customerGender,
        vehicle_make: localStorage.vehicleMake,
        vehicle_variant: localStorage.getVehicleName,
        items: [
          {
            item_id: premiumDetails?.compCode,
            item_name: selectedItem,
            item_brand: logoArray[premiumDetails?.compCode]?.name.split(" ")[0],
            item_category: "Vehicle Insurance",
            item_category_1: localStorage.isCar === true ? "Motorcycle" : "Car",
            item_category_2: policyTypes[localStorage.coverCode],
            item_variant: "Market Value ",

            currency: "MYR",
            price: premiumDetails?.grossDue,
            quantity: "1",
          },
        ],
      });
    }
  }) */
  return (
    <>
      <Modal show={props.status} onHide={props.handleClose} className="status-modal" backdrop="static">
        <Modal.Header>
          <Modal.Title className={color === "error" ? "modal-title-red" : color === "success" ? "modal-title-green" : color === "notAvailable" ? "modal-title-red" : "modal-title-blue"}>
            <div className={color === "error" ? "icon-red" : color === "success" ? "icon-green" : color === "notAvailable" ? "icon-red" : "icon-blue"}>
              {color === "success" ? <Check2Circle size={60} /> : <ExclamationCircle size={60} />}
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="status-body">
            <p className="medium message">{color === "success" ? t('fl_error_success') : color === "error" ? t('fl_error_sorry'): color === "notAvailable" ? t('fl_error_sorry') : t("attention")}</p>
            {props.message !== "" ? <span>{props.message}</span> : <p>Your transaction has failed due to <span className="colored-message">insufficient account balance</span> Please contact us for assistance</p>
            }</div>
            {props.copyMessage && props.paymentStatus !== "error" &&(<div className="copyMessage">{props.copyMessage}</div>)}
        </Modal.Body>
        <Modal.Footer className="d-flex flex-column">
          <div>
          {plansInitialLoad !== true && <Button variant="secondary" className="m-1" onClick ={handlePaymentStatus}>
            {color === "success" ? t('fl_error_done') : t('fl_error_close')}
          </Button>}
          {props.attention === true && <Button variant="secondary" className="m-1" onClick={() => {props.pay();}}>{t("confirm")}</Button>}
          {plansInitialLoad === true && <Button variant="secondary"  className="m-1" onClick={() => {navigate(`/user-verification/${hashString(userId)}`); props.handleClick();}}>
            Back
          </Button>}
          {props.paymentStatus === "error" && <Button variant="secondary" className="m-1"  onClick={() => {
            navigate(`/`);
            navigate(`/user-verification/${hashString(userId)}`);
            navigate("/quotation/plans");
            navigate("/quotation/addons");
            navigate("/quotation/customer-details");
            // navigate("/quotation/",);
            navigate('/quotation/checkout');
            localStorage.setItem("fromRetryPage", true)
            handleDoneRetry();
            }}>{color === "success" ? t('fl_error_done') : t('fl_error_retry')}</Button>}
          </div>
          {props.refId && props.paymentStatus !== "error" && (<><div className="idMessage">{t("your_id")}</div><div className="refId">{props.refId}</div></>)}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default StatusModal;
