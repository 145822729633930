import "../assets/styles/arrow.scss";

function AnimArrow() {
  return (
    <div
      className="arrow_container"
      // onClick={() => {
      //   setTimeout(function () {
      //     document.location = "#section3";
      //   }, 500);
      // }}
    >
      <div className="chevron"></div>
      <div className="chevron"></div>
      <div className="chevron"></div>
    </div>
  );
}

export default AnimArrow;
