import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { FormProvider } from "react-hook-form";
import InputCalendar from "./fields/inputCalendar";
import InputDropdownText from "./fields/inputDropdownText";
import InputText from "./fields/inputText";
import { useForm } from "react-hook-form";
import info from "../../src/assets/images/info.svg";
import "../assets/styles/AddOnsPopup.scss";
import { useTranslation } from "react-i18next";

function PopupModal(props) {
  const { t } = useTranslation();
  const [isShowDOBField, setIsShowDOBField] = useState(localStorage.dob === "true" ? true : false );
  const [isShowDOBField2, setIsShowDOBField2] = useState(localStorage.dob2 === "true" ? true : false );
  const [isShowDOBField3, setIsShowDOBField3] = useState(localStorage.dob3 === "true" ? true : false );
  const [require, setRequire] = useState(false);
  const [require1, setRequire1] = useState(false);
  const [require2, setRequire2] = useState(false);
  const [, setName] = useState(false)
  const [maxYear, setMaxYear] = useState("");
  const [name1, setName1] = useState('')
  const [name2, setName2] = useState('')
  const [defaultOpt , setDefaultOpt] = useState(localStorage.dob === "true" ? "2" : "1")
  const [defaultOpt1 , setDefaultOpt1] = useState(localStorage.dob2 === "true" ? "2" : "1")
  const [defaultOpt2 , setDefaultOpt2] = useState(localStorage.dob3 === "true" ? "2" : "1")
  // const [drivers, setDrivers] = useState([]);

  const premiumDetails = JSON.parse(localStorage.PaymentboxDetails);

  const logoArray = {
    "13": { "name": "ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD"},
    "18": { "name": "ZURICH GENERAL INSURANCE MALAYSIA BERHAD" },
    "97": { "name": "ZURICH GENERAL TAKAFUL MALAYSIA BERHAD"},
    "96": { "name": "ETIQA GENERAL TAKAFUL BERHAD"},
    // "91": { "name": "TOKIO MARINE INSURANS (MALAYSIA) BERHAD"},
    "21": { "name": "RHB INSURANCE BERHAD" },
    "40": { "name": "TUNE PROTECT MALAYSIA" },
  }

  const [policyTypes] = useState({
    "01": `${t("comprehensive")}`,
    "02": `${t("third_party")}`,
    "03": `${t("third_party_ft")}`,
  });

  const selectedItem = logoArray[premiumDetails?.compCode]?.name.split(" ")[0]+" " + logoArray[premiumDetails?.compCode]?.name.split(" ")[1]+" "+ policyTypes[localStorage.coverCode]

  const handleScreenLoad =()=>{
    window.dataLayer.push({
      event: "view_item",
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      event_category: "Premium Details",
      event_action: "View Item",
      // event_label: logoArray[premiumDetails?.compCode]?.name.split(" ")[0],
      event_label: selectedItem,
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
      vehicle_make:localStorage.vehicleMake,
      vehicle_variant: localStorage.getVehicleName ,
      currency: "MYR",
      value: premiumDetails?.grossDue,
      item: [{
        item_id: premiumDetails?.compCode,
        item_name:  selectedItem,
        item_brand: logoArray[premiumDetails?.compCode]?.name.split(" ")[0],
        item_category: "Vehicle Insurance",
        item_category_2: localStorage.isCar===true?"Motorcycle":"Car",
        item_category_3: policyTypes[localStorage.coverCode],
        item_variant: "Market Value",
        currency: "MYR",
        price: premiumDetails?.grossDue,
        quantity: "1",}]
    });

  }
  useEffect(() => {
    handleScreenLoad();
    if (premiumDetails.addDrvData?.item) {
      // let count = 0;
      // for (let i = 0; i < premiumDetails.addDrvData?.item?.length; i++) {
      //   count++;
      // }
      //   if (count === 3) {
      //     setDrivers([
      //     ]);
      //   } else if (count === 2) {
      //     setDrivers([
      //       {
      //         id: 1,
      //         name: "",
      //         nric: "",
      //         passport: false,
      //         isShowDOBField: false,
      //         passportNo: "",
      //         dob: "",
      //       },
      //       {
      //         id: 2,
      //         name: "",
      //         nric: "",
      //         passport: false,
      //         isShowDOBField: false,
      //         passportNo: "",
      //         dob: "",
      //       },
      //     ]);
      //   }
      // } else {
      //   setDrivers([
      //     {
      //       id: 1,
      //       name: "",
      //       nric: "",
      //       passport: false,
      //       isShowDOBField: false,
      //       passportNo: "",
      //       dob: "",
      //     },
      //   ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [sumInsured, setSumInsured] = useState(props.actualSumInsured);
  const [coverageType, setCoverageType] = useState('')
  const [isActive, setActive] = useState(localStorage.getItem("chosedCoverage")=== "0" ? 0 : localStorage.getItem("chosedCoverage") === null ? 0 : 1);
  const [sumins, setIns] = useState(localStorage.getItem("chosedCoverage") === "0" ? true : localStorage.getItem("chosedCoverage") === null ? true : false);
  const [market, setMarket] = useState(localStorage.getItem("chosedCoverage") === "1" ? true : localStorage.getItem("chosedCoverage") === null ? false : false);
  function handleClick(buttonIndex, event) {
    setSumInsured(event.target.value);
    setActive(buttonIndex);
    setShowRange(true);
    setshowDrivers(true);
    setMarket(false);
    setIns(true);
    if (buttonIndex === 1) {
      setActive(buttonIndex);
      setShowRange(false);
      setMarket(true);
      setIns(false);
      setCoverageType("Lowest Available Coverage")
      localStorage.setItem("chosedCoverage", buttonIndex)
    }
    if (buttonIndex === 5) {
      setActive(buttonIndex);
      setshowDrivers(false);
      setMaxDrivers(false);
    }
    if (buttonIndex === 0) {
      localStorage.setItem("chosedCoverage", buttonIndex)
      setCoverageType("Recommended Coverage")
    }
  }

  const [, setMaxDrivers] = useState(false);

  const [, setShowRange] = useState(true);
  const [, setshowDrivers] = useState(true);

  const rangeInputs = document.querySelectorAll('input[type="range"]');

  function handleInputChange(e) {
    let target = e.target;
    if (e.target.type !== "range") {
      target = document.getElementById("range");
    }
    const min = target.min;
    const max = target.max;
    const val = target.value;
    target.style.backgroundSize = ((val - min) * 100) / (max - min) + "% 100%";
  }
  rangeInputs.forEach((input) => {
    input.addEventListener("input", handleInputChange);
  });

  const useFormMethod = useForm({ mode: "all" });
  const [formError, setFormError] = useState(null);
  const [dataNum] = useState(1);

  const onSubmit = (data) => {
    let payload;
    if (isShowDOBField) {
      payload = data;
    } else {
      const { dateBirth, ...rest } = data;
      payload = rest;
    }
    props.handleHideAdditionalDriver(payload, dataNum);
    handleAddDriver();
  };
  const onError = (error) => setFormError(error);

  const [disable] = useState(false);

  const idTypeDobFieldValidationState = (data) => {
    return {
      type: data.type,
      rules: {
        // required: "This input is required.",
        pattern: {
          value: data.patternData.value,
          message: data.patternData.message,
        },
        maxLength: {
          value: data.maxLength,
          message: `This input max ${data.maxLength} characters`,
        },
        minLength: {
          value: data.minLength,
          message: `This input min ${data.minLength} characters`,
        },
      },
    };
  };

  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years);
    var dateN =
      date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    var monthN =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : `${date.getMonth() + 1}`;
    var maxDateParam = date.getFullYear() + "-" + monthN + "-" + dateN;
    var maxDateParamString = maxDateParam.toString();
    setMaxYear(maxDateParamString);
  }

  const [idTypeDobFieldValidation, setIdTypeDobFieldValidation] = useState(
    localStorage.dob === "true" ?
    idTypeDobFieldValidationState({
      type: "text",
      patternData: {
        // value:/^[A-Za-z]{1,}[A-Za-z0-9]{4,10}$/,
        value: /^[A-Za-z]\w*\d/,
        message: t("v_f_passport_error_2"),
      },
      masLength: 15,
      minLength: 4,
    }) : idTypeDobFieldValidationState({
      type: "text",
      patternData: {
        value: /^\d{12,}$/,
        message: t("v_f_ic_error_2"),
      },
      maxLength: 12,
      minLength:12
    })
    
  );
  const [idTypeDobFieldValidation2, setIdTypeDobFieldValidation2] = useState(
    localStorage.dob2 === "true" ?
    idTypeDobFieldValidationState({
      type: "text",
      patternData: {
        // value:/^[A-Za-z]{1,}[A-Za-z0-9]{4,10}$/,
        value: /^[A-Za-z]\w*\d/,
        message: t("v_f_passport_error_2"),
      },
      masLength: 15,
      minLength: 4,
    }) : idTypeDobFieldValidationState({
      type: "text",
      patternData: {
        value: /^\d{12,}$/,
        message: t("v_f_ic_error_2"),
      },
      maxLength: 12,
      minLength:12
    })
    
  );
  const [idTypeDobFieldValidation3, setIdTypeDobFieldValidation3] = useState(
    localStorage.dob3 === "true" ?
    idTypeDobFieldValidationState({
      type: "text",
      patternData: {
        // value:/^[A-Za-z]{1,}[A-Za-z0-9]{4,10}$/,
        value: /^[A-Za-z]\w*\d/,
        message: t("v_f_passport_error_2"),
      },
      masLength: 15,
      minLength: 4,
    }) : idTypeDobFieldValidationState({
      type: "text",
      patternData: {
        value: /^\d{12,}$/,
        message: t("v_f_ic_error_2"),
      },
      maxLength: 12,
      minLength:12
    })
  );

  // const callBackDropdown = (index) => {
  //   var currentDrivers = drivers;
  //   currentDrivers[index].isShowDOBField = true;
  //   setDrivers(currentDrivers);
  // };
  const callBackIDType = (selected, id) => {
    if (selected.type_id === "1") {
      if(id === 0) {
        setIsShowDOBField(false);
        localStorage.setItem("dob", false)
        setDefaultOpt("1");
        setIdTypeDobFieldValidation(
          idTypeDobFieldValidationState({
            type:"text",
            patternData: {
              value: /^\d{12,}$/,
              message: t("v_f_ic_error_2"),
            },
            maxLength: 12,
            minLength: 12,
          })
        );
      };
      if(id === 1)  {
        setIsShowDOBField2(false);
        localStorage.setItem("dob2", false)
        setDefaultOpt1("1");
        setIdTypeDobFieldValidation2(
          idTypeDobFieldValidationState({
            type: "text",
            patternData: {
              value: /^\d{12,}$/,
              message: t("v_f_ic_error_2"),
            },
            maxLength: 12,
            minLength: 12,
          })
        );
      };
      if(id === 2)  {
        setIsShowDOBField3(false);
        localStorage.setItem("dob3", false)
        setDefaultOpt2("1");
        setIdTypeDobFieldValidation3(
          idTypeDobFieldValidationState({
            type: "text",
            patternData: {
              value: /^\d{12,}$/,
              message: t("v_f_ic_error_2"),
            },
            maxLength: 12,
            minLength: 12,
          })
        );
      };
    } else {
      subtractYears(new Date(), 18);
      if(id === 0) { 
        setIsShowDOBField(true); 
        localStorage.setItem("dob", true)
        setDefaultOpt("2");
        setIdTypeDobFieldValidation(
          idTypeDobFieldValidationState({
            type: "text",
            patternData: {
              // value:/^[A-Za-z]{1,}[A-Za-z0-9]{4,10}$/,
              value: /^[A-Za-z]\w*\d/,
              message: t("v_f_passport_error_2"),
            },
            masLength: 15,
            minLength: 4,
          })
        );
      };
      if(id === 1)  {
        setIsShowDOBField2(true);
        localStorage.setItem("dob2", true)
         setDefaultOpt1("2");
         setIdTypeDobFieldValidation2(
          idTypeDobFieldValidationState({
            type: "text",
            patternData: {
              // value:/^[A-Za-z]{1,}[A-Za-z0-9]{4,10}$/,
              value: /^[A-Za-z]\w*\d/,
              message: t("v_f_passport_error_2"),
            },
            masLength: 15,
            minLength: 4,
          })
        );
        };
      if(id === 2)  {
        setIsShowDOBField3(true); 
        localStorage.setItem("dob3", true)
        setDefaultOpt2("2");
        setIdTypeDobFieldValidation3(
          idTypeDobFieldValidationState({
            type: "text",
            patternData: {
              // value:/^[A-Za-z]{1,}[A-Za-z0-9]{4,10}$/,
              value: /^[A-Za-z]\w*\d/,
              message: t("v_f_passport_error_2"),
            },
            masLength: 15,
            minLength: 4,
          })
        );
      }
      };
  };

  const [placeholder, setPlaceHolder] = useState(localStorage.getItem("wscCoverage") ? localStorage.wscCoverage : 500);

  const onApply = (event) => {
    event.preventDefault();
    setPlaceHolder(event.target.coverage.value);
    const amount = parseInt(event.target.coverage.value);
    if (amount >= 500 && amount <= 5000) {
      props.handleHideCoverage(event.target.coverage.value);
    } else if (amount < 500) {
      event.target.coverage.value = 500;
      props.handleHideCoverage(event.target.coverage.value);
      setPlaceHolder(500)
    } else if (amount > 5000) {
      event.target.coverage.value = 5000;
      props.handleHideCoverage(event.target.coverage.value);
      setPlaceHolder(5000)
    }
  };

  // const sendData = (event) => {
  //   event.preventDefault();
  //   setAgreeValue(event.target.amount.value)
  //   props.handleHideInsured(event.target.amount.value)
  // }
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  var nowDate = new Date();
  const month = nowDate.getMonth();
  var date =
    nowDate.getDate() + " " + monthNames[month] + " " + nowDate.getFullYear();

  const handleDoneBtn = () => {
    // On Click of Done button
    window.dataLayer.push({
      event: "motor_edit_coverage_value_preference",
      event_category: "Premium Details",
      event_action: "Select Preferred Coverage",
      event_label: "Coverage Value Preference",
      coverage_type: coverageType,
      currency: "MYR",
      value: sumInsured,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
    });
  };
  const handleWSCDoneBtn = (WSCPrice) => {
    // On Click of Done
    window.dataLayer.push({
      event: "motor_edit_vehicle_glass_coverage",
      event_category: "Premium Details",
      event_action: "Edit Vehicle Glass Coverage",
      event_label: "RM "+WSCPrice,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
    });
  };


  const handleAddDriver = () => {
    // On Click of Done
    window.dataLayer.push({
      event: "motor_edit_additional_drivers",
      event_category: "Premium Details",
      event_action: "Edit Additional Drivers",
      event_label:  "Additional Named Drivers",
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
    });
  };

  return (
    <div className="addonspopup">
      <Modal
        show={props.showInsured}
        onHide={props.closeInsured}
        backdrop="static"
        className="modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title className="add-on-modal-title">
            {t("as_of")} {date}, {t("as_of_2")}{" "}
            {props.actualSumInsured.toLocaleString()}
          </Modal.Title>
          <div>
            <h5 className="bold ml-2">{t("confirm_coverage")}</h5>
            <div className="d-flex justify-content-around">
              <div>
                <div
                  className="market-value form-check agreed-value"
                  id={isActive === 0 ? "active" : ""}
                >
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    value={props.actualSumInsured}
                    id="flexRadioDefault1"
                    onClick={(event) => handleClick(0, event)}
                    checked={sumins}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1"
                  >
                    {/* <h5 className="bold add-on-modal-info-title">Market Value</h5> */}
                    <span className="bold">
                      RM {props.actualSumInsured.toLocaleString()}
                    </span>
                  </label>
                </div>
                <div className="prefered-amount bold">{t("recommended_coverage")}</div>
              </div>
            <div>
              <div
                className="market-value form-check agreed-value"
                id={isActive === 1 ? "active" : ""}
              >
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  value={props.actualMarketValue}
                  id="flexRadioDefault2"
                  onClick={(event2) => handleClick(1, event2)}
                  checked={market}
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  {/* <h5 className="bold add-on-modal-info-title">Agreed Value</h5> */}
                    <span
                      className="text-black title-16 bold"
                      id="value-display"
                    >
                      RM {props.actualMarketValue.toLocaleString()}
                    </span>
                </label>
              </div>
              <div className="prefered-amount bold">{t("lowest_coverage")}</div>
              </div>
            </div>
          </div>
          <div></div>
          <div className="coverage mt-3">
            <div className="d-flex">
              <div className="mx-1">
                <span className="image">
                  <img src={info} alt="info" />
                </span>
              </div>
              <div className="info-modal1">
                <h5 className="line-height-25">{t("market_value")}</h5>
                <p>{t("market_value_info")}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer d-flex justify-content-center">
          <Button
            variant="secondary"
            type="submit"
            form="coverage-form"
            onClick={() => {
              props.handleModal(sumInsured);
              handleDoneBtn();
            }}
          >
            {t("Done")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal 2 */}
      {/* <Button variant="primary" onClick={handleShowModal2}>
        Launch Modal 2
      </Button> */}

      <Modal
        show={props.showCoverage}
        onHide={props.handleCloseCoverage}
        backdrop="static"
        className="modal"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title bold title-18">
            {t("coverage_title")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payable title-16">
            <p>
              {t("coverage_title_info")}
              <br />
              <br />
              {t("coverage_title_info_2")}
            </p>
            {/* <h4 className="mt-5 bold">
              I would like a coverage of{" "}
              <span className="subBlueTitle" id="value-display">
                RM {value}
              </span>
            </h4>
            <h4 className="bold">
              My payable amount is{" "}
              <span className="green">RM 500</span>
            </h4> */}
            {/* <input
                  type="range"
                  className="styled-slider slider-progress"
                  id="formControlRange"
                  min="500"
                  max="5000"
                  step="1"
                  value={value}
                  onChange={handleChange}
                /> */}
            <Form className="form" id="wsc-form" onSubmit={onApply}>
              <div className="d-flex inputBorder">
                <div className="col-6">
                  <label
                    htmlFor="exampleInputEmail1"
                    className="form-label small m-0"
                  >
                    {t("coverage_value")}
                  </label>
                  <div className="d-flex">
                    <span className="input-group-text" id="basic-addon1">
                      RM
                    </span>
                    <input
                      type="number"
                      className="form-control noBorder left-form"
                      name="coverage"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      // placeholder={placeholder}
                      defaultValue={placeholder}
                      prefix="RM"
                    />
                  </div>
                </div>
                <div className=" col-6 right-form">
                  <label
                    htmlFor="exampleInputPassword1"
                    className="form-label small m-0 right-form"
                  >
                    {t("payable_amt")}
                  </label>
                  <div className="d-flex">
                    <span
                      className="input-group-text bold green"
                      id="basic-addon1"
                    >
                      RM
                    </span>
                    <input
                      type="text"
                      className="form-control noBorder right-form bold green"
                      id="exampleInputPassword1"
                      placeholder={
                        premiumDetails?.addBenData?.item?.find(
                          (data) => data.benCode === "WSC"
                        )?.benPremium
                          ? premiumDetails?.addBenData?.item
                              ?.find((data) => data.benCode === "WSC")
                              ?.benPremium.toFixed(2)
                          : "0"
                      }
                      disabled
                    />
                  </div>
                </div>
              </div>
              {/* <Button type="submit" className="btn btn-primary apply-btn mt-3">
                Apply
              </Button> */}
            </Form>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer d-flex justify-content-center">
          <span
            onClick={() => {
              // Get the input value from the user
              const inputElement =
                document.getElementById("exampleInputEmail1");
              const WSCPrice = inputElement.value;

              // Call the handleWSCDoneBtn function with the user input value
              handleWSCDoneBtn(WSCPrice);
            }}
          >
            <Button
              className="hover"
              variant="secondary"
              form="wsc-form"
              type="submit"
            >
              {t("confirm")}
            </Button>
          </span>
        </Modal.Footer>
      </Modal>

      {/* Modal 3 */}
      {/* <Button variant="primary" onClick={handleShowModal3}>
        Launch Modal 3
      </Button> */}

      <Modal
        show={props.showAdditionalDriver}
        onHide={props.closeDriverModal}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title bold title-18">
            {t("additional_driver")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payable title-16">
            <p>{t("additional_driver_info")}</p>
            <div>
              {/* {showDrivers === true ? null : ( */}
              <div className="range-container">
                <div className="range">
                  <div>
                    <div className="d-flex justify-content-between">
                      <p className="additional-driver bold">
                        {t("additional_driver")} {1}
                      </p>
                    </div>

                    <FormProvider {...useFormMethod}>
                      {/*pass all methods into the context */}
                      <Form
                        id="driver-form"
                        onSubmit={useFormMethod.handleSubmit(onSubmit, onError)}
                      >
                        <Row className="mb-3">
                          {/* postcode field */}
                          <InputText
                            fieldLg="12"
                            fieldControlId={1}
                            floatingControlId="floatingInput"
                            floatingLabel={t("add_driver_name")}
                            fieldType="text"
                            detectEmpty={(e) =>
                              e === "" ? setRequire(false) : setRequire(true)
                            }
                            defaultValue={
                              premiumDetails?.addDrvData?.item
                                ? premiumDetails?.addDrvData?.item[1]?.name
                                : ""
                            }
                            formError={formError}
                            register={{
                              control: `name${0}`,
                              rules: {
                                required: t("add_driver_error1"),
                                minLength: {
                                  value: 3,
                                  message: t("add_driver_error2"),
                                },
                              },
                             
                            }}
                          ></InputText>
                        </Row>
                        {/* NRIC or PASSPORT field */}
                        <Row className="mb-3" onChange={(e)=>{ if(e.target.value.length > 1){setName(true)}else{setName(true)}}}>
                          <InputDropdownText
                            fieldLg="12"
                            fieldControlId={0}
                            firstOption="NRIC"
                            // optionsDisable={true}
                            floatingControlId="floatingInput"
                            floatingLabel={t("v_f_ic_type")}
                            defaultOption={defaultOpt}
                            maxLength={isShowDOBField ? 15 : 12}
                            defaultValue={
                              premiumDetails.addDrvData?.item
                                ? (localStorage.dob === "false" ? premiumDetails?.addDrvData?.item[1]?.icNumber :  localStorage.dob === undefined ? premiumDetails?.addDrvData?.item[1]?.icNumber : premiumDetails?.addDrvData?.item[1]?.drvPassport)
                                : ""
                            }
                            fieldType={idTypeDobFieldValidation.type}
                            fieldPlaceholder={t("v_f_ic_num")}
                            formError={formError}
                            handleCallBack={(selected)=>callBackIDType(selected,0)}
                            register={{
                              control: isShowDOBField? `drvPassport0` : `icNumber${0}`,
                              rules: {
                                ...idTypeDobFieldValidation.rules,
                                format: true,
                                // minLength: {
                                //   value: 12,
                                //   message: "Minimum length is 12 characters",
                                // },
                                required: require
                                  ? t("add_driver_error1")
                                  : "",
                              },
                            }}
                          ></InputDropdownText>
                        </Row>
                        {isShowDOBField && (
                          <Row className="mb-3 mt-3">
                            {/* Date of Birth field */}
                            <InputCalendar
                              fieldLg="12"
                              fieldControlId={1}
                              maxYear={maxYear}
                              floatingControlId="floatingInput"
                              floatingLabel={t("v_f_birthDate")}
                              fieldType="text"
                              defaultValue={premiumDetails?.addDrvData?.item ? premiumDetails?.addDrvData?.item[1]?.drvDob : ""}
                              fieldPlaceholder={t("v_f_birthDate")}
                              formError={formError}
                              register={{
                                control: `drvDob${0}`,
                                rules: {
                                  required: require ? t("add_driver_error1"): "",
                                },
                              }}
                            ></InputCalendar>
                          </Row>
                        )}
                        <div className="d-flex justify-content-between">
                          <p className="additional-driver bold">
                            {t("additional_driver")} {2}
                          </p>
                        </div>
                        <Row className="mb-3">
                          {/* postcode field */}
                          <InputText
                            fieldLg="12"
                            fieldControlId={1}
                            detectEmpty={(e) =>
                              e === "" ? setRequire1(false) : setRequire1(true)
                            }
                            floatingControlId="floatingInput"
                            floatingLabel={t("add_driver_name")}
                            fieldType="text"
                            defaultValue={
                              premiumDetails?.addDrvData?.item
                                ? premiumDetails?.addDrvData?.item[2]?.name
                                : ""
                            }
                            formError={formError}
                            register={{
                              control: `name${1}`,
                              rules: {
                                required: name1 ? t("add_driver_error1") : "",
                                minLength: {
                                  value: 3,
                                  message: t("add_driver_error2"),
                                },
                              },
                            }}
                          ></InputText>
                        </Row>
                        {/* NRIC or PASSPORT field */}
                        <Row className="mb-3" onChange={(e)=>{ if(e.target.value.length > 1){setName1(true)}else{setName1(false)}}}>
                          <InputDropdownText 
                            fieldLg="12"
                            fieldControlId={1}
                            firstOption="NRIC"
                            defaultOption={defaultOpt1}
                            maxLength={isShowDOBField2 ? 15 : 12}
                            floatingControlId="floatingInput"
                            floatingLabel={t("v_f_ic_type")}
                            defaultValue={
                              premiumDetails.addDrvData?.item
                                ? (localStorage.dob2 === "false" ? premiumDetails?.addDrvData?.item[2]?.icNumber : localStorage.dob2 === undefined ? premiumDetails?.addDrvData?.item[2]?.icNumber : premiumDetails?.addDrvData?.item[2]?.drvPassport)
                                : ""
                            }
                            fieldType={idTypeDobFieldValidation2.type}
                            fieldPlaceholder={t("v_f_ic_num")}
                            formError={formError}
                            handleCallBack={(selected)=>callBackIDType(selected,1)}
                            register={{
                              control: isShowDOBField2 ? `drvPassport1` : `icNumber${1}`,
                              rules: {
                                ...idTypeDobFieldValidation2.rules,
                                format: true,
                                // minLength: {
                                //   value: 12,
                                //   message: "Minimum length is 12 characters",
                                // },
                                required: require1
                                  ? t("add_driver_error1")
                                  : "",
                              },
                            }}
                          ></InputDropdownText>
                        </Row>
                        {isShowDOBField2 && (
                          <Row className="mb-3 mt-3">
                            {/* Date of Birth field */}
                            <InputCalendar
                              fieldLg="12"
                              fieldControlId={1}
                              maxYear = {maxYear}
                              floatingControlId="floatingInput"
                              floatingLabel={t("v_f_birthDate")}
                              defaultValue={premiumDetails?.addDrvData?.item ? premiumDetails?.addDrvData?.item[2]?.drvDob : ""}
                              fieldType="text"
                              fieldPlaceholder={t("v_f_birthDate")}
                              formError={formError}
                              register={{
                                control: `drvDob${1}`,
                                rules: {
                                  required: require1 ? t("add_driver_error1") : "",
                                },
                              }}
                            ></InputCalendar>
                          </Row>
                        )}
                        {premiumDetails.compCode !== "13" && premiumDetails.compCode !== "40" ? <>
                        <div className="d-flex justify-content-between">
                          <p className="additional-driver bold">
                            {t("additional_driver")} {3}
                          </p>
                        </div>
                        <Row className="mb-3">
                          {/* postcode field */}
                          <InputText
                            fieldLg="12"
                            // disabled = {premiumDetails.compCode === "13" ? true : false}
                            fieldControlId={2}
                            detectEmpty={(e) => e === "" ? setRequire2(false) : setRequire2(true)}
                            floatingControlId="floatingInput"
                            floatingLabel={t("add_driver_name")}
                            fieldType="text"
                            defaultValue={premiumDetails?.addDrvData?.item
                              ? premiumDetails?.addDrvData?.item[3]?.name
                              : ""}
                            formError={formError}
                            register={{
                              control: `name${2}`,
                              rules: {
                                required: name2 ? t("add_driver_error1") : "",
                                minLength: {
                                  value: 3,
                                  message: t("add_driver_error2"),
                                },
                              },
                            }}
                          ></InputText>
                        </Row><Row className="mb-3" onChange={(e) => { if (e.target.value.length > 1) { setName2(true); } else { setName2(false); } } }>
                            <InputDropdownText
                              fieldLg="12"
                              fieldControlId={2}
                              firstOption="NRIC"
                              defaultOption={defaultOpt2}
                              // disabled = {premiumDetails.compCode === "13" ? true : false}
                              // optionsDisable = {premiumDetails.compCode === "13" ? true : false}
                              maxLength={isShowDOBField3 ? 15 : 12}
                              floatingControlId="floatingInput"
                              floatingLabel={t("v_f_ic_type")}
                              defaultValue={premiumDetails.addDrvData?.item
                                ? (localStorage.dob3 === "false" ? premiumDetails?.addDrvData?.item[3]?.icNumber : localStorage.dob3 === undefined ? premiumDetails?.addDrvData?.item[3]?.icNumber : premiumDetails?.addDrvData?.item[3]?.drvPassport)
                                : ""}
                              fieldType={idTypeDobFieldValidation3.type}
                              fieldPlaceholder={t("v_f_ic_num")}
                              formError={formError}
                              handleCallBack={(selected) => callBackIDType(selected, 2)}
                              register={{
                                control: isShowDOBField3 ? `drvPassport2` : `icNumber${2}`,
                                rules: {
                                  ...idTypeDobFieldValidation3.rules,
                                  format: true,
                                  // minLength: {
                                  //   value: 12,
                                  //   message: "Minimum length is 12 characters",
                                  // },
                                  required: require2
                                    ? t("add_driver_error1")
                                    : "",
                                },
                              }}
                            ></InputDropdownText>
                          </Row></> : ""}
                        
                        {/* NRIC or PASSPORT field */}
                        
                        {isShowDOBField3 && (
                          <Row className="mb-3 mt-3">
                            {/* Date of Birth field */}
                            <InputCalendar
                              fieldLg="12"
                              fieldControlId={1}
                              maxYear = {maxYear}
                              floatingControlId="floatingInput"
                              floatingLabel={t("v_f_birthDate")}
                              defaultValue={premiumDetails?.addDrvData?.item ? premiumDetails?.addDrvData?.item[3]?.drvDob : ""}
                              fieldType="text"
                              fieldPlaceholder={t("v_f_birthDate")}
                              formError={formError}
                              register={{
                                control: `drvDob${2}`,
                                rules: {
                                  required: require2 ? t("add_driver_error1") : "",
                                },
                              }}
                            ></InputCalendar>
                          </Row>
                        )}
                      </Form>
                    </FormProvider>
                  </div>
                  {disable === false ? null : (
                    <div className=" mt-3">
                      <p className="maxed-text text-start">
                        {t("max_driver")}{" "}
                        <span className="yes" onClick={props.unlimitedDrivers}>
                          {t("unlimited")}
                        </span>
                        ?
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-footer d-flex justify-content-center hover">
          <Button
            variant="secondary"
            type="submit"
            form="driver-form"
          >
            {t("Done")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PopupModal;
