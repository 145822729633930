import React, { useState, useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import { useForm, FormProvider } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'

import InputText from './fields/inputText'
import InputDropdownText from './fields/inputDropdownText'
import InputCalendar from './fields/inputCalendar'
import '../assets/styles/VehicleForm.scss'
import { ErrorMessage } from '@hookform/error-message'
import iconSuccess from '../assets/images/field-icon-success.svg'
import iconError from '../assets/images/field-icon-error.svg'
import StatusModal from './StatusModal'
import LoaderModal from './LoaderModal'
import { resetLocalHost } from '../utils/LocalhostUtils'
import { hashString } from 'react-hash-string'
import { t } from 'i18next'
import { item } from '../Features/Quotation/AddOns'
import { Amplify, Auth } from 'aws-amplify'
import moment from 'moment'

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_APPCLIENTID
  }
})

async function signUp() {
  try {
    await Auth.signIn({
      username: process.env.REACT_APP_USERNAME,
      password: process.env.REACT_APP_PASSWORD
    }).then((data) => {
      localStorage.setItem('api_token', data.signInUserSession.idToken.jwtToken)
    })
  } catch (error) {
    console.log('error signing up:', error)
  }
}

let emptyMobile

function VehicleForm({ isOpen }) {
  // // signIn(process.env.REACT_APP_USERNAME, process.env.REACT_APP_PASSWORD)
  // new CognitoUserPool(poolData);
  // signIn(process.env.REACT_APP_USERNAME, process.env.REACT_APP_PASSWORD);
  const useFormMethod = useForm({ mode: 'all' })
  const [formError, setFormError] = useState(null)
  const [isShowDOBField, setIsShowDOBField] = useState(false)
  const [active1, setActive1] = useState(false)
  const [active2, setActive2] = useState(false)
  const [active3, setActive3] = useState(false)
  const [active4, setActive4] = useState(false)
  const [status, setStatus] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [type, setType] = useState('error')
  let [fieldBgColor, setFieldBgColor] = useState('')
  const [maxYear, setMaxYear] = useState('')
  let [fieldIconStatus, setFieldIconStatus] = useState(null)
  let [fieldBgColorGender, setFieldBgColorGender] = useState('')
  let [fieldIconStatusGender, setFieldIconStatusGender] = useState(null)
  const [showLoader, setShowLoader] = useState(false)
  const {
    register,
    getValues,
    // Read the formState before render to subscribe the form state through the Proxy
    formState: { errors, dirtyFields }
  } = useFormMethod
  useEffect(() => {
    resetLocalHost()
    item.length = 0
  }, [])
  useEffect(() => {
    /* handle field background style */
    if (formError) {
      if (!errors['maritalStatus'] && Boolean(getValues('maritalStatus'))) {
        setFieldBgColor('success')
        setFieldIconStatus(iconSuccess)
      } else if (errors['maritalStatus']) {
        setFieldBgColor('error')
        setFieldIconStatus(iconError)
      } else {
        setFieldBgColor('')
        setFieldIconStatus(null)
      }
    }
  }, [formError, dirtyFields, errors, getValues])

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emptyMobile])

  useEffect(() => {
    /* handle field background style */
    if (formError) {
      if (!errors['gender'] && Boolean(getValues('gender'))) {
        setFieldBgColorGender('success')
        setFieldIconStatusGender(iconSuccess)
      } else if (errors['gender']) {
        setFieldBgColorGender('error')
        setFieldIconStatusGender(iconError)
      } else {
        setFieldBgColorGender('')
        setFieldIconStatusGender(null)
      }
    }
  }, [formError, dirtyFields, errors, getValues])

  function subtractYears(date, years) {
    date.setFullYear(date.getFullYear() - years)
    var dateN = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    var monthN = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    var maxDateParam = date.getFullYear() + '-' + monthN + '-' + dateN
    var maxDateParamString = maxDateParam.toString()
    setMaxYear(maxDateParamString)
  }

  function selectMaritalStatus(mStatus) {
    setFieldBgColor('success')
    setFieldIconStatus(iconSuccess)
    if (mStatus === 's') {
      setActive1(true)
      setActive2(false)
    }
    if (mStatus === 'm') {
      setActive2(true)
      setActive1(false)
    }
  }
  function genderActive(buttonIndex) {
    setFieldBgColorGender('success')
    setFieldIconStatusGender(iconSuccess)
    if (buttonIndex === 3) {
      setActive3(true)
      setActive4(false)
    }
    if (buttonIndex === 4) {
      setActive4(true)
      setActive3(false)
    }
  }
  let navigate = useNavigate()

  let delayInMilliseconds = 2000
  const onSubmit = (data) => {
    signUp().then(() => {
      const header = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': localStorage.getItem('api_token'),
          'X-Api-Key': process.env.REACT_APP_AUTH_KEY
        }
      }
      const token = process.env.REACT_APP_MDM_TOKEN
      const _config = {
        headers: { Authorization: `Bearer ${token}` }
      }

      setTimeout(function () {
        axios
          .get(
            `${process.env.REACT_APP_MDMAPI_URL}api/options/cities/v1/querybypostcode?country=MY&postcode=${data.postcode}`,
            _config
          )
          .then(function (response) {
            if (response?.data?.data !== null) {
              if (requestBody.passportNo) {
                let passportYear = moment().diff(requestBody.birthDate, 'years')
                localStorage.setItem('customerAge', passportYear)
              }

              if (requestBody.newIc) {
                let icYear = parseInt(requestBody.newIc.slice(0, 2))
                let gender = requestBody.newIc.charAt(11)
                gender = gender % 2 === 0 ? 'Female' : 'Male'
                localStorage.setItem('customerGender', gender)

                let icDate = requestBody.newIc.slice(2, 6)
                let _yrs = moment().year().toString()
                let curYear = parseInt(_yrs.slice(2, 4))

                if (icYear > curYear) {
                  icYear = parseInt(19 + '' + icYear)
                } else {
                  icYear = parseInt(20 + '' + icYear)
                }
                icYear = moment(icYear + icDate, 'YYYYMMDD').format('YYYY-MM-DD')
                icYear = moment().diff(icYear, 'years')
                localStorage.setItem('customerAge', icYear)
              }
              handleNextButtonClick()
              axios
                .post(`${process.env.REACT_APP_AWS_URL}api/vixncd`, requestBody, header)
                .then(function (response) {
                  setShowLoader(false)
                  localStorage.setItem('UserVerification', JSON.stringify(response.data.data))
                  if (response.data.data.vehTypeCode === 'CVP') {
                    localStorage.setItem('isCar', true)
                    localStorage.setItem('isBike', false)
                  } else {
                    localStorage.setItem('isCar', false)
                    localStorage.setItem('isBike', true)
                  }
                  localStorage.setItem('userInitilData', JSON.stringify(data))
                  const vehicleMake = response.data.data.arrResExtraParam.item.find(
                    (data) => data.indicator === 'DISTRIBUTOR_MAKE_DESCRIPTION'
                  )
                  localStorage.setItem('vehicleMake', vehicleMake.value)

                  emptyMobile = 2
                  navigate(`/user-verification/${data.newIc ? hashString(data.newIc) : hashString(data.passportNo)}`)
                  // const timeStamp = Date.now();
                })
                .catch(function (error) {
                  const errorCode = error.response?.data.errors[0].code
                  setShowLoader(false)
                  setType('error')
                  setStatus(true)
                  if (errorCode === '001') {
                    setErrorMessage(t('error1'))
                  } else if (errorCode === '008') {
                    setErrorMessage(t('error2'))
                  } else if (errorCode === '0') {
                    setErrorMessage(t('error3'))
                  } else if (errorCode === '077') {
                    setErrorMessage(t('error4'))
                  } else if (errorCode === '003') {
                    setErrorMessage(t('error3'))
                  } else if (errorCode === '009') {
                    setErrorMessage(t('error10'))
                  } else if (errorCode === '031') {
                    setErrorMessage(t('error3'))
                  } else if (errorCode === '033') {
                    setErrorMessage(t('error11'))
                  } else if (errorCode === '034') {
                    setErrorMessage(t('error10'))
                  } else if (errorCode === '035' || errorCode === '077') {
                    setErrorMessage(t('error4'))
                  } else {
                    setErrorMessage(t('error4'))
                  }
                  // setErrorMessage(t('all_error_req'));
                })
            } else {
              setShowLoader(false)
              setType('error')
              setStatus(true)
              setErrorMessage(t('error5'))
            }
          })
          .catch(function (error) {
            setShowLoader(false)
            setType('error')
            setStatus(true)
            setErrorMessage(t('error5'))
          })
      }, delayInMilliseconds)
      setShowLoader(true)
    })

    const requestBody = {
      regNo: data.regNo,
      postcode: data.postcode
    }
    if (data.passportNo) {
      requestBody.passportNo = data.passportNo
      data.newIc = ''
    }
    if (data.newIc) {
      requestBody.newIc = data.newIc
      data.passportNo = ''
    }
    const datebirth = data.newIc.substring(0, 6)
    const iyear = parseInt(datebirth.slice(0, 2))
    const currentYear = new Date().getFullYear()
    const twoDigitYear = currentYear.toString().substring(2)
    const iTwoDigitYear = parseInt(twoDigitYear)
    let startYear = ''
    if (iyear < iTwoDigitYear) {
      startYear = '20'
    } else {
      startYear = '19'
    }

    const year = startYear + datebirth.slice(0, 2)
    const month = datebirth.slice(2, 4)
    const day = datebirth.slice(4)

    const dateOfBirth = `${year}-${month}-${day}`
    localStorage.setItem('dateOfBirth', dateOfBirth)
  }
  const onError = (error) => {
    setFormError(error)
  }
  const idTypeDobFieldValidationState = (data) => {
    return {
      type: data.type,
      rules: {
        required: data.requiredData.message,
        pattern: {
          value: data.patternData.value,
          message: data.patternData.message
        },
        maxLength: {
          value: data.maxLengthData.maxLength,
          message: data.maxLengthData.message
        },
        minLength: {
          value: data.minLengthData.minLength,
          message: data.minLengthData.message
        }
      }
    }
  }
  const [idTypeDobFieldValidation, setIdTypeDobFieldValidation] = useState(
    idTypeDobFieldValidationState({
      type: 'text',
      requiredData: {
        message: t('v_f_ic_error_1')
      },
      patternData: {
        value: /^\d{12,}$/,
        message: t('v_f_ic_error_2')
      },
      maxLengthData: {
        maxLength: 12,
        message: t('v_f_ic_error_3')
      },
      minLengthData: {
        minLength: 12,
        message: t('v_f_ic_error_4')
      }
    })
  )

  const callBackIDType = (selected) => {
    if (selected.type_id === '1') {
      setIsShowDOBField(false)
      setIdTypeDobFieldValidation(
        idTypeDobFieldValidationState({
          type: 'text',
          requiredData: {
            message: t('v_f_ic_error_1')
          },
          patternData: {
            value: /^\d{12,}$/,
            message: t('v_f_ic_error_2')
          },
          maxLengthData: {
            maxLength: 12,
            message: t('v_f_ic_error_3')
          },
          minLengthData: {
            minLength: 12,
            message: t('v_f_ic_error_4')
          }
        })
      )
    } else {
      subtractYears(new Date(), 16)
      setIsShowDOBField(true)
      setIdTypeDobFieldValidation(
        idTypeDobFieldValidationState({
          type: 'text',
          requiredData: {
            message: t('v_f_passport_error_1')
          },
          patternData: {
            value: /^[A-Za-z]\w*\d/,
            message: t('v_f_passport_error_2')
          },
          maxLengthData: {
            maxLength: 15,
            message: t('v_f_passport_error_3')
          },
          minLengthData: {
            minLength: 6,
            message: t('v_f_passport_error_4')
          }
        })
      )
    }
  }

  const [show, setShow] = useState(false)
  const divRef = useRef(null)

  const showCode = () => {
    setShow(true)
  }

  const handleClickOutside = (event) => {
    if (divRef.current && !divRef.current.contains(event.target)) {
      if (emptyMobile === 1) {
        setShow(true)
      } else {
        setShow(false)
      }
    }
  }

  // Function to handle the click event and push data to the dataLayer.
  const handleNextButtonClick = () => {
    // On Click of Next button
    window.dataLayer.push({
      event: 'submit_vehicle_details',
      event_category: 'Home',
      event_action: 'Submit Vehicle Details',
      event_label: 'Vehicle Details',
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
      vehicle_type: localStorage.isCar === 'true' ? 'car' : 'motorcycle'
    })
  }

  return (
    <div
      id='section2'
      className={`vehicle-form d-flex flex-column vhlBg pt-4 pt-md-5 px-4 px-md-5 pb-5 25/08:2:11am ${
        isOpen ? 'show' : 'hide'
      }`}
    >
      <h5 className='mb-4'>{t('v_f_title')}</h5>
      <FormProvider {...useFormMethod}>
        {' '}
        {/*pass all methods into the context */}
        <Form onSubmit={useFormMethod.handleSubmit(onSubmit, onError)}>
          <Row className='mb-3'>
            {/* vehicle registration no field */}
            <InputText
              fieldLg='4'
              fieldMg='6'
              detectEmpty={() => null}
              maxLength='20'
              fieldControlId='validationCustom01'
              floatingControlId='floatingInput'
              floatingLabel={t('v_f_reg_no')}
              fieldType='text'
              fieldPlaceholder={t('v_f_reg_no')}
              formError={formError}
              register={{
                control: 'regNo',
                rules: {
                  pattern: {
                    value: /^[A-Za-z][A-Za-z0-9]{0,19}$/,
                    message: t('v_f_reg_no_error_1')
                  },
                  trim: true,
                  uppercase: true,
                  required: t('v_f_reg_no_error_2'),
                  maxLength: {
                    value: 20,
                    message: t('v_f_reg_no_error_3')
                  },
                  minLength: {
                    value: 2,
                    message: t('v_f_reg_no_error_4')
                  }
                }
              }}
            ></InputText>
            {/* postcode field */}
            <InputText
              fieldLg='4'
              fieldMg='6'
              maxLength='5'
              detectEmpty={() => null}
              fieldControlId='validationCustom02'
              floatingControlId='floatingInput'
              floatingLabel={t('v_f_poscode')}
              fieldType='text'
              fieldPlaceholder={t('v_f_poscode')}
              formError={formError}
              register={{
                control: 'postcode',
                rules: {
                  required: t('v_f_poscode_error_1'),
                  // pattern: {
                  //   value: /^(?!.*(\d)\1{4})[0-9]{5}$/,
                  //   message: t("v_f_poscode_error_2"),
                  // },
                  maxLength: {
                    value: 5,
                    message: t('v_f_poscode_error_3')
                  }
                }
              }}
            ></InputText>
            {/* NRIC or PASSPORT field */}
            <InputDropdownText
              fieldLg='4'
              fieldMg='6'
              firstOption='NRIC'
              fieldControlId='validationCustom03'
              floatingControlId='floatingInput'
              floatingLabel={t('v_f_ic_type')}
              fieldType={idTypeDobFieldValidation.type}
              fieldPlaceholder={t('v_f_ic_num')}
              formError={formError}
              handleCallBack={callBackIDType}
              maxLength={isShowDOBField ? '15' : '12'}
              isPassport={isShowDOBField}
              register={
                isShowDOBField === false
                  ? {
                      control: 'newIc',
                      rules: {
                        ...idTypeDobFieldValidation.rules,
                        format: true
                      }
                    }
                  : {
                      control: 'passportNo',
                      rules: { ...idTypeDobFieldValidation.rules }
                    }
              }
            ></InputDropdownText>
            {isShowDOBField && (
              <InputCalendar
                fieldLg='4'
                fieldMg='6'
                fieldControlId='validationCustom04'
                floatingControlId='floatingInput'
                floatingLabel={t('v_f_birthDate')}
                fieldType='text'
                fieldPlaceholder={t('v_f_birthDate')}
                formError={formError}
                maxYear={maxYear}
                register={{
                  control: 'birthDate',
                  rules: {
                    required: t('v_f_birthDate_error')
                  }
                }}
              ></InputCalendar>
            )}
            {isShowDOBField && (
              <div className='col-lg-4 col-md-6 form-element-container'>
                <div className='gender-inner-container'>
                  <p className='gender-title'>{t('v_f_gender')}</p>
                  <div className='gender-container d-flex'>
                    <div className={`gender ${active3 ? 'active' : ''}`}>
                      <input
                        onClick={() => genderActive(3)}
                        className={`form-check-input ${fieldBgColorGender}`}
                        type='radio'
                        value={t('v_f_gender_m')}
                        name='gender'
                        id='gender-male'
                        {...register('gender', {
                          required: t('v_f_gender_error')
                        })}
                      />
                      <label className='form-check-label' htmlFor='gender-male'>
                        {t('v_f_gender_m')}
                      </label>
                    </div>
                    <div className={`gender ${active4 ? 'active' : ''}`}>
                      <input
                        onClick={() => genderActive(4)}
                        className={`form-check-input ${fieldBgColorGender}`}
                        type='radio'
                        value={t('v_f_gender_f')}
                        name='gender'
                        id='gender-female'
                        {...register('gender', {
                          required: t('v_f_gender_error')
                        })}
                      />
                      <label className='form-check-label' htmlFor='gender-female'>
                        {t('v_f_gender_f')}
                      </label>
                    </div>
                    {fieldIconStatusGender && (
                      <img className='icon' src={`${fieldIconStatusGender}`} alt='icon-error' />
                    )}
                  </div>
                </div>
                <ErrorMessage
                  errors={errors}
                  name='gender'
                  render={({ messages }) => {
                    return <span className='error-label'>{t('v_f_gender_error')}</span>
                  }}
                />
              </div>
            )}
            <div className='col-lg-4 col-md-6 form-element-container'>
              <div className='marital-inner-container'>
                <div className={`small marital-title`}>{t('v_f_marital')}</div>
                <div className='wrapper-marital d-flex'>
                  <div className={`marital-status ${active1 ? 'active' : ''}`}>
                    <input
                      onClick={() => selectMaritalStatus('S')}
                      className={`form-check-input ${fieldBgColor}`}
                      type='radio'
                      value={t('v_f_marital_s')}
                      name='maritalStatus'
                      id='marital-single'
                      {...register('maritalStatus', {
                        required: true
                      })}
                    />
                    <label className='form-check-label' htmlFor='marital-single'>
                      {t('v_f_marital_s')}
                    </label>
                  </div>
                  <div className={`marital-status ${active2 ? 'active' : ''}`}>
                    <input
                      onClick={() => selectMaritalStatus('M')}
                      className={`form-check-input ${fieldBgColor}`}
                      type='radio'
                      value={t('v_f_marital_m')}
                      name='maritalStatus'
                      id='marital-married'
                      {...register('maritalStatus', {
                        required: true
                      })}
                    />
                    <label className='form-check-label' htmlFor='marital-married'>
                      {t('v_f_marital_m')}
                    </label>
                  </div>
                  {fieldIconStatus && <img className='icon' src={`${fieldIconStatus}`} alt='icon-error' />}
                </div>
              </div>
              <ErrorMessage
                errors={errors}
                name='maritalStatus'
                render={({ messages }) => {
                  return <span className='error-label'>{t('v_f_marital_error')}</span>
                }}
              />
            </div>
            <div
              className='col-lg-4 col-md-6'
              onClick={showCode}
              ref={divRef}
              onChange={(event) => {
                event.target.value.length === 0 ? (emptyMobile = 2) : (emptyMobile = 1)
                console.log(emptyMobile)
              }}
            >
              <InputText
                showCountryCode={true}
                fieldLg='12'
                maxLength='10'
                show={show} // Pass the show state to InputText component
                detectEmpty={() => null}
                countryClass='countryClass'
                fieldControlId='validationCustom02'
                floatingControlId='floatingInput'
                floatingLabel={t('v_f_mobile')}
                fieldType='text'
                fieldPlaceholder={t('v_f_mobile')}
                countryCode='+60'
                formError={formError}
                register={{
                  control: 'mobileNumber',
                  rules: {
                    required: t('v_f_mobile_error_1'),
                    pattern: {
                      value: /^[0-9+]{5,}$/,
                      message: t('v_f_mobile_error_2')
                    },
                    minLength: {
                      value: 9,
                      message: t('v_f_mobile_error_3')
                    },
                    no0: true,
                    trim: true
                  }
                }}
              ></InputText>
            </div>
          </Row>
          <Row>
            <div className='next-button-container footer d-flex justify-content-center justify-content-lg-end flex-wrap flex-xl-row flex-column'>
              <div className='d-flex flex-column-reverse flex-xl-row mt-xl-0'>
                <div className='desc mt-2 d-flex flex-column align-items-center align-items-xl-end'>
                  <div className='label'>{t('v_f_b_label_1')}</div>
                  <div className='label'>
                    {t('v_f_b_label_2')}
                    <span className='highlight subBlueTitle'>
                      <a
                        href='https://www.pos.com.my/legal/terms-and-conditions-motor-insurance'
                        target='_blank'
                        rel='noreferrer'
                      >
                        {t('v_f_b_label_3')}
                      </a>
                    </span>
                    <span className='highlight'>and</span>
                    <span className='highlight subBlueTitle'>
                      <a href='https://www.pos.com.my/legal/privacy-statement' target='_blank' rel='noreferrer'>
                        {t('v_f_b_label_4')}
                      </a>
                    </span>
                    .
                  </div>
                </div>
                <div className='d-flex justify-content-center next-button-wrapper'>
                  <Button type='submit' className='button-next hover'>
                    {t('v_f_b')}
                  </Button>
                </div>
              </div>
            </div>
          </Row>
        </Form>
      </FormProvider>
      {status && (
        <StatusModal status={status} handleClose={() => setStatus(false)} type={type} message={errorMessage} />
      )}
      <LoaderModal show={showLoader ? 1 : 0} />
    </div>
  )
}

export default VehicleForm
