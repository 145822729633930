import React, { useState, useEffect } from 'react';
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { InputGroup } from 'react-bootstrap';
import iconError from '../../assets/images/field-icon-error.svg';
import iconSuccess from '../../assets/images/field-icon-success.svg';

import "../../assets/styles/inputCalendar.scss";

function InputCalendar(state) {
  let [fieldBgColor, setFieldBgColor] = useState('');
  let [fieldIconStatus, setFieldIconStatus] = useState(null);

  const {
    register,
    getValues,
    formState: { errors, dirtyFields }
  } = useFormContext({ defaultValues: { [state.register.control]: "" } }); // retrieve all hook methods

  useEffect(() => {
    /* handle field background style */
    if (state.formError || dirtyFields[state.register.control]) {
      if (!errors[state.register.control] && Boolean(getValues(state.register.control))) {
        setFieldBgColor('success');
        setFieldIconStatus(iconSuccess);
      } else if (Boolean(Object.keys(errors).length > 0 && errors[state.register.control])) {
        setFieldBgColor('error');
        setFieldIconStatus(iconError)
      } else {
        setFieldBgColor('');
        setFieldIconStatus(null);
      }
    }
  }, [state, dirtyFields, errors, getValues]);

  return (
    <Form.Group as={Col} lg={state.fieldLg} md={state.fieldMg} controlId={state.fieldControlId} className='form-element-container'>
      <InputGroup>
        <FloatingLabel
          controlId={state.floatingControlId}
          label={state.floatingLabel}
          className="input-calendar"
        >
          {fieldIconStatus && <img className="icon calendar-icon" src={`${fieldIconStatus}`} alt="icon-error" />}
          <Form.Control
            type="date"
            max={state.maxYear}
            placeholder={state.fieldPlaceholder}
            defaultValue={state.defaultValue}
            className={`date-wrapper ${fieldBgColor}`}
            {...register(state.register.control, state.register.rules)}
          />
        </FloatingLabel>

      </InputGroup>
      <ErrorMessage
        errors={errors}
        name={state.register.control}
        render={({ messages }) => {
          return !Boolean(getValues(state.register.control)) ?
            <span className="error-label">{errors[state.register.control].message}</span>
            : ''
        }}
      />
    </Form.Group>
  )
}

export default InputCalendar;