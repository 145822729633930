import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.min.css';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languagedetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import App from './App';
import './assets/styles/index.scss';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(languagedetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['en', 'my'],
    fallbackLng: "en",
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie']
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json'
    }
  });

const loadingMarkup = (
  <div className="py-4 text-center">
    <h2>Loading...</h2>
  </div>
);
const root = createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={loadingMarkup}>
    <App />
  </Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
