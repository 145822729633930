/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
// import { t } from "i18next";
import "../assets/styles/CompContainer.scss";
import womandesktop from "../assets/images/asian-businesswoman-using-smart-phone-against-car-desktop.png";
// import womanmobile from "../assets/images/chose-insurance-woman.svg";
import twogirl from "../assets/images/2girls.png";
import { ArrowRight } from "react-bootstrap-icons";

function CompContainer() {
  const { t } = useTranslation();
  return (
    <div id="section3">
      <div className="CompContainer d-flex flex-row-reverse flex-wrap row pt-lg-5 pt-md-5 pt-sm-1 pt-xs-1 mx-0">
        <div className="right-sec col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12 ">
          <picture>
            <source media="(min-width:1200px)" srcSet={twogirl}  alt="womandesktop"/>
            <source media="(max-width:1200px)" srcSet={twogirl}  alt="womanmobile"/>
            <img src={womandesktop} alt="person"/>
          </picture>
        </div>
        <div className="left-sec col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div className="title pr-1">
            <h1 className="comp-title bold">
              {t("comp_title_1")}
              <span>{t("comp_title_2")}</span>.
            </h1>
            <br />
            <p className="comp-title-3">{t("comp_title_3")}</p>
          </div>
          <div className="scroll">
            <div className="parag">
              <div className="medium">{t('comp_title_3_1')}</div>
              <div className="medium">{t('comp_title_3_2')}</div>
              <div className="medium">{t('comp_title_3_3')}</div>
            </div>
          </div>
          <div className="link hover">
            <a className="medium goFaq" onClick={() => {

              // On Click of Need to know more? Read our FAQ
              window.dataLayer.push({
                event: "go_to_page",
                event_category: "Home",
                event_action: "Go To Page",
                event_label: "Need to know more? Read our FAQ",
                customer_age: localStorage.customerAge,
                customer_gender: localStorage.customerGender,
              });
              setTimeout(function () {
                document.location = "#faq";
              }, 500);
        }}><span className="p-2"><ArrowRight/></span>{t('comp_link')}</a>
          </div>
        </div>
        {/* <div className="right-sec col-xl-7 col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
          <img src={person} alt="person"/>
        </div> */}
      </div>
    </div>
  );
}

export default CompContainer;