import React, { useState } from "react";
import "../../assets/styles/CheckBoxContainer.scss";
import arrow_right from "../../assets/images/arrow-right.svg";
// import Toggle from "../fields/inputToggle";

let arr = localStorage?.addonsBenefit ? JSON.parse(localStorage?.addonsBenefit) : [];
function CheckboxContainer(props) {
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (index, event) => {
    event.stopPropagation();
    // event.preventDefault();
    props.handleChangeEvent(index);
    setIsChecked(!isChecked);
    if (arr.some((id) => id === props.val)) {
      arr = arr.filter((data) => data !== props.val);
    } else {
      arr.push(props.val);
      if(props.val === "AND") {

        arr = arr.filter(data => data !== "ALLD")
      }else if(props.val === "ALLD"){
        arr = arr.filter(data => data !== "AND")
        window.dataLayer.push({
        event: "motor_edit_additional_drivers",
        event_category: "Premium Details",
        event_action: "Edit Additional Drivers",
        event_label: "Unlimited Driver",
        vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
        customer_age: localStorage.customerAge,
        customer_gender: localStorage.customerGender,
      });
      }
    }
  }
  // const sendData = () => {
  //   props.sendDataToParent(isChecked);
  // }

  /*const handleDriverDone =(maxDrivers) =>{
    // On Click of Done button
    const eventLabel = maxDrivers ? "Unlimited Drivers" : "Additional Named Drivers";

    window.dataLayer.push({
      event: "motor_edit_additional_drivers",
      event_category: "Premium Details",
      event_action: "Edit Additional Drivers",
      event_label: eventLabel,
    });
  };*/

  return (

    <div
      className={`checkbox-container ${props.isChecked ? "selected" : "list"}`}
      style={{ width: props.boxWidth ? props.boxWidth : '100%' }}>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          value={props.val}
          id={props.val}
          onChange={(event) => handleChange(props.val, event)}
          // checked={arr.includes(props.val)}
          checked={props.checked} />
        <label className="form-check-label" htmlFor={props.val}>
          {props.title && props.title !== "" && (
            <>
              <div className="header">
                <div className={`mb-0 mt-1 title ${props.isTitleBold ? 'medium' : ''}`}>{props.title}</div>
                {props.amount &&
                  <div className="medium">{`RM ${props.amount}`}</div>}
              </div>
            </>
          )}
          {props.pop && props.pop !== "" && (
            <p className="popular mt-1">{props.pop}</p>
          )}

          {props.info && props.info !== "" && (
            <p className="info mt-2">{props.info}</p>
          )}

          {props.options && (
            <>
              <div className="option" onClick={props.handleClick}>
                <div className="option-label">{props.options.label}: <span className="medium subBlueTitle">{props.options.highlight}</span></div>
                <div className="option-callback"> <span className="subBlueTitle">Edit</span> <img src={arrow_right} alt="arrow-right" /> </div>
              </div>
            </>
          )}
        </label>
      </div>
    </div>
  );
}

export default CheckboxContainer;