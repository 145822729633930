import { useEffect, useState } from "react";
import PolicyDropdown from "../../Components/PolicyDropdown";
import PlanBox from "../../Components/Elements/PlanBox";
import notMatchBg from "../../assets/images/insurer/not-match-bg.svg";
import "../../assets/styles/Plans.scss";
// import PaymentBox from "../../Components/Elements/PaymentBox";
// import LoaderModal from "../../Components/LoaderModal";
import StatusModal from "../../Components/StatusModal";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LoaderModal2 from "../../Components/LoaderModal2";
import { drivers, item } from "./AddOns";
import Stepper from "../../Components/Elements/Stepper";
import moment from "moment";
import axios from "axios";



function Plans(props) {
  if(localStorage.UserVerification === undefined) {window.location.replace('/');}
  const resetLocalHost = () => {
    let keysToRemove = ["addonsBenefit","allBen","chosedCoverage","wscCoverage","driversName","dob","dob2","dob3"];
    keysToRemove.forEach((k) => localStorage.removeItem(k));
  };
  resetLocalHost();

  const localData = JSON.parse(localStorage.userInitilData);
  const localDataVixncd = JSON.parse(localStorage.UserVerification);
  let dateTempArray = localDataVixncd.expiryDate.split("/");
  let expiryDateFormatted = `${dateTempArray[2]}-${dateTempArray[1]}-${dateTempArray[0]}`;
  const givenDate = new Date(expiryDateFormatted);
  const currentDate = new Date();
  var effectiveDate = expiryDateFormatted;
  var effectiveTime = "00:00:00";
  // moment(currentDate).format("YYYY-MM-DD")
  if (moment(givenDate).format("YYYY-MM-DD") > moment(currentDate).format("YYYY-MM-DD") || moment(givenDate).format("YYYY-MM-DD") === moment(currentDate).format("YYYY-MM-DD")) {
    // Add a day
    let d = new Date(expiryDateFormatted);
    // var dt = moment(d).format("YYYY-MM-DD");
    var dt =  moment(d).add(1,'years');
    dt = moment(dt).format("YYYY-MM-DD");
    d.setUTCDate(d.getUTCDate() + 1);
    effectiveDate = d.toISOString().substr(0, 10);
    let c = effectiveDate.split("-");
    let cyear = parseInt(c[0]) + 1;
    // let cday = parseInt(c[2]);
    c[0] = cyear.toString();
    // c[2] = cday;
    const minusDay = givenDate.getDate()
    var newStartDate = `${c[0]}-${c[1]}-${(minusDay < 10 ? "0" : "") + minusDay}`;
    expiryDateFormatted = dt;
    localStorage.setItem("effectiveDate", effectiveDate)
    localStorage.setItem("effectiveTime", effectiveTime)
    localStorage.setItem("expiryDateFormatted", expiryDateFormatted)
  } else {
    effectiveDate = moment().format("YYYY-MM-DD")
    let time = moment().format('HH:mm:ss');
    effectiveTime = time;
    let c = effectiveDate.split("-");
    let cyear = parseInt(c[0]) + 1;
    c[0] = cyear.toString();
    const minusDay = moment().subtract(1, 'day');
    newStartDate = moment(minusDay._d).add(1,'years').format('YYYY-MM-DD');
    expiryDateFormatted = newStartDate;
    localStorage.setItem("effectiveDate", effectiveDate)
    localStorage.setItem("effectiveTime", effectiveTime)
    localStorage.setItem("expiryDateFormatted", expiryDateFormatted)
  }

  // const getSelectedVariant = localStorage.selectedVariant?.split(',').join("*");
  const getSelectedVariant = localStorage.selectedVariant?.split(',');
  let selectedVariant;
  if(localStorage.selectedVariant !== undefined){
    if(getSelectedVariant[2] === ''){getSelectedVariant.pop()}
    selectedVariant = getSelectedVariant?.join('*');
  }
  
  var gender =
    localData.newIc !== ""
      ? parseInt(localData.newIc.at(-1)) % 2 === 0
        ? "F"
        : "M"
      : localData.gender.at(0);
  var makeCodeMinor =
    localDataVixncd?.arrResExtraParam.item[
      localDataVixncd.arrResExtraParam.item.findIndex(
        (item) => item.indicator === "DISTRIBUTOR_MODELCODE"
      )
    ];
  makeCodeMinor = getSelectedVariant ? selectedVariant : localStorage.getItem("userSelectedMinorCode")
    ? localStorage.getItem("userSelectedMinorCode")
    : makeCodeMinor.value.split("|")[0];
  const [reqBody, setReqBody] = useState({});

  const requestBody = {
    compCode: "POS",
    agentCode: "POS",
    newIc: localData.newIc,
    passportNo: localData.passportNo,
    coverCode: localStorage.getItem("oldCar") === "true" ? "03" : "01",
    channel: "POS",
    gender: gender,
    birthDate: localData.birthDate
      ? localData.birthDate
      : localStorage.getItem("dateOfBirth"),
    address1: "DEFAULT",
    postCode: localData.postcode,
    maritalStatus: localData.maritalStatus === "SINGLE" ? "S" : "M",
    makeYear: localDataVixncd.makeYear,
    regNo: localData.regNo,
    chassisNo: localDataVixncd.chassisNo,
    sumInsured:
      localDataVixncd.variation && localStorage.getMinorCode !== ""
        ? localDataVixncd.variation.find(
            (data) => data.minor === localStorage.getMinorCode
          )?.sumInsured
        : localDataVixncd.variation[0]?.sumInsured,
    engineNo: localDataVixncd.engineNo,
    makeCodeMajor: localDataVixncd.ismMakeCode,
    makeCodeMinor: makeCodeMinor,
    vehCapacity: parseInt(localDataVixncd.vehCapaCity),
    requestId: localStorage.getItem("requestId")
      ? localStorage.getItem("requestId")
      : "",
    keepFlag: "Y",
    vehCapacityCode: "CC",
    seatCapacity: JSON.parse(localStorage.isCar) !== true ? "2" : "5",
    vehTypeCode: localDataVixncd.vehTypeCode,
    effectiveDate: effectiveDate,
    effectiveTime: effectiveTime,
    expiryDate: expiryDateFormatted,
    piamDrv: JSON.parse(localStorage.isCar) ? "03" : "05",
    ncdPerc: localDataVixncd.ncdPerc,
    ownershipType: "I",
    useCode: localDataVixncd.veHuseCode,
    addBenData: {
      item: [],
    },
    addDrvData: {
      item: [],
    },
  };

  //   const onBackButtonEvent = (e) => {
  //     e.preventDefault();
  //     setStatus(true)
  //     setErrorMessage(t("error8"))
  //     // if (!finishStatus) {
  //     //     if (window.confirm("Do you want to go back ? You will lose all you data and start from the beginning")) {
  //     //         setfinishStatus(true)
  //     //         // your logic
  //     //         window.history.back();
  //     //         // props.history.push("/");
  //     //     } else {
  //     //         window.history.pushState(null, null, window.location.pathname);
  //     //         setfinishStatus(false)
  //     //     }
  //     // }
  // }
  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener('popstate', onBackButtonEvent);
  //   return () => {
  //     window.removeEventListener('popstate', onBackButtonEvent);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  item.length = 0;
  typeof drivers !== 'undefined' && (drivers.length = 0);
  const { t } = useTranslation();
  const [home, setHome] = useState(false);
  let navigate = useNavigate();
  const [plans, setPlans] = useState([]);
  const [, setOtherPlans] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const benefits0 = ['Not Available']
  const benefits1 =[
    t("allianze_car_comprehensive_1"),
    t("allianze_car_comprehensive_2"),
    t("allianze_car_comprehensive_3"),
  ];
  const benefits2 = [
    t("allianze_car_third_party_1"),
    t("allianze_car_third_party_2"),
  ];
  const benefits3 = [
    t("allianze_car_fire_theft_1"),
    t("allianze_car_fire_theft_2"),
    t("allianze_car_fire_theft_3"),
  ];
  const benefits4 = [
    t("allianze_motor_comprehensive_1"),
    t("allianze_motor_comprehensive_2"),
    t("allianze_motor_comprehensive_3"),
    t("allianze_motor_comprehensive_4"),
    t("allianze_motor_comprehensive_5"),
    t("allianze_motor_comprehensive_6"),
    t("allianze_motor_comprehensive_7"),
    t("allianze_motor_comprehensive_8"),
  ];
  const benefits5 = [
    t("allianze_motor_third_party_1"),
    t("allianze_motor_third_party_2"),
    t("allianze_motor_third_party_3"),
    t("allianze_motor_third_party_4"),
  ];
  const benefits6 = [
    t("allianze_motor_fire_theft_1"),
    t("allianze_motor_fire_theft_2"),
    t("allianze_motor_fire_theft_3"),
  ];
  const benefits7 = [
    t("zurich_g_car_comprehensive_1"),
    t("zurich_g_car_comprehensive_2"),
    t("zurich_g_car_comprehensive_3"),
    t("zurich_g_car_comprehensive_4"),
    t("zurich_g_car_comprehensive_5"),
    t("zurich_g_car_comprehensive_6"),
    t("zurich_g_car_comprehensive_7"),
  ];
  const benefits8 = [
    t("zurich_g_car_third_party_1"),
    t("zurich_g_car_third_party_2"),
  ];
  const benefits9 = [
    t("zurich_g_car_fire_theft_1"),
    t("zurich_g_car_fire_theft_2"),
    t("zurich_g_car_fire_theft_3"),
  ];
  const benefits10 = [
    t("zurich_g_motor_comprehensive_1"),
    t("zurich_g_motor_comprehensive_2"),
    t("zurich_g_motor_comprehensive_3"),
    t("zurich_g_motor_comprehensive_4"),
    t("zurich_g_motor_comprehensive_5"),
    t("zurich_g_motor_comprehensive_6"),
  ];
  const benefits11 = [
    t("zurich_g_motor_third_party_1"),
    t("zurich_g_motor_third_party_2"),
  ];
  const benefits12 = [
    t("zurich_g_motor_fire_theft_1"),
    t("zurich_g_motor_fire_theft_2"),
    t("zurich_g_motor_fire_theft_3"),
  ];
  const benefits13 = [
    t("zurich_t_car_comprehensive_1"),
    t("zurich_t_car_comprehensive_2"),
    t("zurich_t_car_comprehensive_3"),
    t("zurich_t_car_comprehensive_4"),
    t("zurich_t_car_comprehensive_5"),
    t("zurich_t_car_comprehensive_6"),
    t("zurich_t_car_comprehensive_7"),
  ];
  const benefits14 = [
    t("zurich_t_car_third_party_1"),
    t("zurich_t_car_third_party_2"),
  ];
  const benefits15 = [
    t("zurich_t_car_fire_theft_1"),
    t("zurich_t_car_fire_theft_2"),
    t("zurich_t_car_fire_theft_3"),
  ];
  const benefits16 = [
    t("zurich_t_motor_comprehensive_1"),
    t("zurich_t_motor_comprehensive_2"),
    t("zurich_t_motor_comprehensive_3"),
    t("zurich_t_motor_comprehensive_4"),
    t("zurich_t_motor_comprehensive_5"),
    t("zurich_t_motor_comprehensive_6"),
  ];
  const benefits17 = [
    t("zurich_t_motor_third_party_1"),
    t("zurich_t_motor_third_party_2"),
  ];
  const benefits18 = [
    t("zurich_t_motor_fire_theft_1"),
    t("zurich_t_motor_fire_theft_2"),
    t("zurich_t_motor_fire_theft_3"),
  ];
  const benefits19 = [
    t("tune_car_comprehensive_1"),
    t("tune_car_comprehensive_2"),
    t("tune_car_comprehensive_3"),
    t("tune_car_comprehensive_4"),
    t("tune_car_comprehensive_5"),
    t("tune_car_comprehensive_6"),
    t("tune_car_comprehensive_7"),
  ];
  const benefits20 = [
    t("tune_car_fire_theft_1"),
    t("tune_car_fire_theft_2"),
    t("tune_car_fire_theft_3"),
  ];
  const benefits21 = [
    t("tune_motor_comprehensive_1"),
    t("tune_motor_comprehensive_2"),
    t("tune_motor_comprehensive_3"),
    t("tune_motor_comprehensive_4"),
    t("tune_motor_comprehensive_5")
  ]
  const benefits22 = [
    t("tune_motor_third_party_1"),
    t("tune_motor_third_party_2")
  ]
  const benefits23 = [
    t("rhb_car_comprehensive_1"),
    t("rhb_car_comprehensive_2"),
    t("rhb_car_comprehensive_3"),
    t("rhb_car_comprehensive_4"),
  ]
  const benefits24 = [
    t("rhb_car_fire_theft_1"),
    t("rhb_car_fire_theft_2"),
  ]
  const benefits25 = [
    t("rhb_motor_comprehensive_1"),
    t("rhb_motor_comprehensive_2"),
    t("rhb_motor_comprehensive_3"),
    t("rhb_motor_comprehensive_4"),
  ]
  const benefits26 = [
    t("rhb_motor_fire_theft_1"),
    t("rhb_motor_fire_theft_2"),
    t("rhb_motor_fire_theft_3"),
  ]
  const benefits27 = [
    t("etiqa_car_comprehensive_1"),
    t("etiqa_car_comprehensive_2"),
    t("etiqa_car_comprehensive_3"),
    t("etiqa_car_comprehensive_4"),
    t("etiqa_car_comprehensive_5"),
    t("etiqa_car_comprehensive_6"),
    t("etiqa_car_comprehensive_7"),
    t("etiqa_car_comprehensive_8"),
  ]
  const benefits28 = [
    t("etiqa_car_fire_theft_1"),
    t("etiqa_car_fire_theft_2"),
    t("etiqa_car_fire_theft_3"),
    t("etiqa_car_fire_theft_4"),
  ]
  const benefits29 = [
    t("etiqa_motor_comprehensive_1"),
    t("etiqa_motor_comprehensive_2"),
    t("etiqa_motor_comprehensive_3"),
    t("etiqa_motor_comprehensive_4"),
    t("etiqa_motor_comprehensive_5"),
  ]
  const [status, setStatus] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t("all_error_req"));
  const [type] = useState("error");
  const sumInsured =
    localDataVixncd.variation && localStorage.getMinorCode !== ""
      ? localDataVixncd.variation.find(
          (data) => data.minor === localStorage.getMinorCode
        )?.sumInsured
      : localDataVixncd.variation[0]?.sumInsured;
  useEffect(() => {
    localStorage.setItem("priceSort", "default");
    localStorage.setItem("compcode", "01");
    fetchpremium(requestBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const fetchpremium = (requestBody) => {
    setShowLoader(true);

    const header = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": localStorage.getItem('api_token'),
        "X-Api-Key": process.env.REACT_APP_AUTH_KEY
      }
    };
    requestBody.compCode = "POS";
    requestBody.sumInsured = sumInsured;

    axios
    .post(`${process.env.REACT_APP_AWS_URL}api/premium`, requestBody, header)
    .then(function (data) {
      if (data.code === "E") {
        setErrorMessage(data.errors[0].message);
        setStatus(true);
        setShowLoader(false);
      } else {
        setPlans(data?.data.data.item);
        setShowLoader(false);
        if (data.length) {
          data.map((plan) => {
            if (plan.benefits.length > 5) plan["isBenefitExpand"] = false;
            return plan;
          });
          const other = data.filter((plan) => plan.slug === "tune");
          setOtherPlans(other);
        }
        localStorage.setItem("plansLoadingFirstTime", false);
        if (
          data.data.data.item[0].respDesc ===
          "DT_ERR:DetariffException - Tariff Region Code is BLANK."
        ) {
          setStatus(true);
          setErrorMessage(t("mdm_postcode_error"));
          setHome(true);
        }
      }
    })
    .catch(function (error) {
      if(error.code === "ERR_NETWORK"){
        setErrorMessage(t("expired"));
        setStatus(true);
        setHome(true)
        setShowLoader(false);
      } else if(error.response.data.errors[0].code === '3'){
        setErrorMessage(t("fl_error_refered"));
        setStatus(true);
        setShowLoader(false);
      } else {
        setErrorMessage(t("error1"));
        setStatus(true);
        setShowLoader(false);
      }
    });
   
    // axios
    //   .post(`${process.env.REACT_APP_API_URL}api/premium`, JSON.stringify(requestBody))
  //   fetch(`${process.env.REACT_APP_AWS_URL}api/premium`, {
  //     method: "POST",
  //     headers: header.headers,
  //     body: JSON.stringify(requestBody),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       if (data.code === "E") {
  //         setErrorMessage(data.errors[0].message);
  //         setStatus(true);
  //         setShowLoader(false);
  //       } else {
  //         setPlans(data?.data.item);
  //         setShowLoader(false);
  //         if (data.length) {
  //           data.map((plan) => {
  //             if (plan.benefits.length > 5) plan["isBenefitExpand"] = false;
  //             return plan;
  //           });
  //           const other = data.filter((plan) => plan.slug === "tune");
  //           setOtherPlans(other);
  //         }
  //         localStorage.setItem("plansLoadingFirstTime", false);
  //         if (
  //           data.data.item[0].respDesc ===
  //           "DT_ERR:DetariffException - Tariff Region Code is BLANK."
  //         ) {
  //           setStatus(true);
  //           setErrorMessage("Please enter a valid postcode.");
  //           setHome(true);
  //         }
  //       }
  //     })
  //     .catch((error) => {
  //       setErrorMessage(t("expired"));
  //       setStatus(true);
  //       setShowLoader(false);
  //     });
  };

  const selectedPlan = (plan) => {
    setReqBody({
      ...reqBody,
      compCode: plan.compCode,
      coverCode: plan.coverCode,
      actualSumInsured: plan.sumInsured,
      actualMarketValue: plan.marketValue,
      requestId: plan.requestId,
      keepFlag: "Y",
    });
    localStorage.setItem("actMarket", plan.marketValue);
    localStorage.setItem("actSum", plan.sumInsured);
    localStorage.setItem("selectedCode", plan.compCode);
    localStorage.setItem("coverCode", plan.coverCode);
    localStorage.setItem("requestID", plan.requestId);
    navigate("/quotation/addons");
  };

  const showHide = (index) => {
    const newPlans = [...plans];
    newPlans[index].isBenefitExpand = !newPlans[index].isBenefitExpand;
    setPlans(newPlans);
  };

  // const handleNextStep = () => {}
  const handleTypeSelection = (index) => {
    setTimeout(() => {
      requestBody.coverCode = index;
      fetchpremium(requestBody);
      localStorage.setItem("compcode", index);
    }, 500);
  };
  const handlePriceSort = (index) => {
    setTimeout(() => {
      requestBody.orderBy = index;
      fetchpremium(requestBody);
    }, 500);
  };
  let count = 0;
  for (let i = 0; i < plans.length; i++) {
    if (plans[i].respCode === "1") count = count + 1;
  }
  const redirect = () => {
    if (home === true) {
      navigate("/");
    }
    setStatus(false);
  };
  const initialSteps = [
    { value: 1, label: "Plans", isActive: true, isRenderComponent: true },
    { value: 2, label: "Add-Ons", isActive: false, isRenderComponent: false },
    {
      value: 3,
      label: "Customer Details",
      isActive: false,
      isRenderComponent: false,
    },
    { value: 4, label: "Checkout", isActive: false, isRenderComponent: false },
  ];

  return (
    <>
      <div
        className="mt-4 subBlueTitle cursor-pointer "
        onClick={() => navigate(-1)}
      >
        {t("bck_button")}
      </div>

      <div className="quotation-wrapper-stepper">
        <Stepper steps={initialSteps} activeStep={0}/>
      </div>
      <PolicyDropdown
        typeSelection={handleTypeSelection}
        priceSortSelection={handlePriceSort}
        vehicleType = {localDataVixncd.vehTypeCode}
      />
      {!showLoader && (
        <div className="plans">
          <div className="total">
            {count} {t("plan")}
          </div>
          {plans.length > 0 &&
            plans.map((plan, index) => {
              return (
                <PlanBox
                  key={index}
                  plans={plans}
                  benefits={
                    plan.coverCode === "01" && plan.compCode === "13" && plan.vehTypeCode === "CVP"
                      ? benefits1
                      : plan.coverCode === "01" && plan.compCode === "18" && plan.vehTypeCode === "CVP"
                      ? benefits7
                      : plan.coverCode === "01" && plan.compCode === "97" && plan.vehTypeCode === "CVP"
                      ? benefits13
                      : plan.coverCode === "02" && plan.compCode === "13" && plan.vehTypeCode === "CVP"
                      ? benefits2
                      : plan.coverCode === "02" && plan.compCode === "18" && plan.vehTypeCode === "CVP"
                      ? benefits8
                      : plan.coverCode === "02" && plan.compCode === "97" && plan.vehTypeCode === "CVP"
                      ? benefits14
                      : plan.coverCode === "03" && plan.compCode === "13" && plan.vehTypeCode === "CVP"
                      ? benefits3
                      : plan.coverCode === "03" && plan.compCode === "18" && plan.vehTypeCode === "CVP"
                      ? benefits9
                      : plan.coverCode === "03" && plan.compCode === "97" && plan.vehTypeCode === "CVP"
                      ? benefits15
                      : plan.coverCode === "01" &&
                        plan.compCode === "13" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits4
                      : plan.coverCode === "01" &&
                        plan.compCode === "18" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits10
                      : plan.coverCode === "01" &&
                        plan.compCode === "97" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits16
                      : plan.coverCode === "02" &&
                        plan.compCode === "13" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits5
                      : plan.coverCode === "02" &&
                        plan.compCode === "18" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits11
                      : plan.coverCode === "02" &&
                        plan.compCode === "97" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits17
                      : plan.coverCode === "03" &&
                        plan.compCode === "13" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits6
                      : plan.coverCode === "03" &&
                        plan.compCode === "18" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits12
                      : plan.coverCode === "03" &&
                        plan.compCode === "97" &&
                        plan.vehTypeCode === "CVM"
                      ? benefits18
                      : plan.coverCode === "01" && plan.compCode === "40" && plan.vehTypeCode === "CVP" ? benefits19
                      : plan.coverCode === "03" && plan.compCode === "40" && plan.vehTypeCode === "CVP" ? benefits20
                      : plan.coverCode === "01" && plan.compCode === "40" && plan.vehTypeCode === "CVM" ? benefits21
                      : plan.coverCode === "02" && plan.compCode === "40" && plan.vehTypeCode === "CVM" ? benefits22
                      : plan.coverCode === "01" && plan.compCode === "21" && plan.vehTypeCode === "CVP" ? benefits23
                      : plan.coverCode === "03" && plan.compCode === "21" && plan.vehTypeCode === "CVP" ? benefits24
                      : plan.coverCode === "01" && plan.compCode === "21" && plan.vehTypeCode === "CVM" ? benefits25
                      : plan.coverCode === "02" && plan.compCode === "21" && plan.vehTypeCode === "CVM" ? benefits26
                      : plan.coverCode === "01" && plan.compCode === "96" && plan.vehTypeCode === "CVP" ? benefits27
                      : plan.coverCode === "03" && plan.compCode === "96" && plan.vehTypeCode === "CVP" ? benefits28
                      : plan.coverCode === "01" && plan.compCode === "96" && plan.vehTypeCode === "CVM" ? benefits29
                      :benefits0
                  }
                  available={plan.respCode === "1" ? true : false}
                  plan={plan}
                  index={index}
                  handleSelectedPlan={selectedPlan}
                  handleShowHide={showHide}
                />
              );
            })}
          {count === 0 && (
            <div className="not-match text-center">
              <img src={notMatchBg} alt="not-match" />
              <div className="label">{t("error7")}</div>
            </div>
          )}
        </div>
      )}
      <LoaderModal2 show={showLoader ? 1 : 0} />
      <StatusModal
        handleClick={()=>''}
        status={status}
        handleClose={() => redirect()}
        type={type}
        message={errorMessage}
      />
    </>
  );
}

export default Plans;
