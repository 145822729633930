import { useLocation } from "react-router-dom";
import Logo from "./Logo";
import drhb from "../../assets/images/DRB_HiCOM_Logo 2.svg"
import "../../assets/styles/footer.scss";
import { useTranslation } from 'react-i18next';

const listing = [
  {
    title: "COURIER",
    id: 101,
    data: [
      {
        id: 1,
        name: "Track Shipments",
        link: "https://tracking.pos.com.my/tracking?_ga=2.263545988.902781466.1689770803-1714398369.1677133152",
      },
      {
        id: 2,
        name: "Book a Shipment",
        link: "https://send.pos.com.my/home?lg=en&_ga=2.23873970.902781466.1689770803-1714398369.1677133152",
      },
      {
        id: 3,
        name: "Shipment Rate Calculator",
        link: "https://www.pos.com.my/send/ratecalculator.html",
      },
      {
        id: 4,
        name: "SendParcel",
        link: "https://www.pos.com.my/send/sendparcel.html?_ga=2.92320286.267146719.1658716763-123051764.1658451817",
      },
      {
        id: 5,
        name: "Fulfilment Services",
        link: "https://www.pos.com.my/business-business-submit/pos-malaysia-efs.html",
      },
      {
        id: 6,
        name: "Business Enquiries",
        link: "https://www.pos.com.my/send/business.html#business_submit",
      },
      {
        id: 7,
        name: "Pos Laju",
        link: "https://www.pos.com.my/send/pos-laju.html",
      },
      {
        id: 8,
        name: "Mail",
        link: "https://www.pos.com.my/send/mail.html",
      },
      {
        id: 9,
        name: "Shop",
        link: "https://www.pos.com.my/shop",
      },
    ],
  },
  {
    title: "FINANCE",
    id: 102,
    data: [
      {
        id: 1,
        name: "Pay Bills",
        link: "https://www.posonline.com.my/PosOnline.Web.Portal/",
      },
      {
        id: 2,
        name: "Buy Insurance",
        link: "https://www.posonline.com.my/PosOnline.Web.Portal/Home/LandingpageTakafulInsurance",
      },
      {
        id: 3,
        name: "JPJ / Road Tax",
        link: "https://www.pos.com.my/jpj",
      },
      {
        id: 4,
        name: "Invest in ASNB",
        link: "https://www.pos.com.my/asnb",
      },
    ],
  },
  {
    title: "COMPANY",
    id: 103,
    data: [
      {
        id: 1,
        name: "About Us",
        link: "https://www.pos.com.my/about-us",
      },
      {
        id: 2,
        name: "Press Room",
        link: "https://www.pos.com.my/pressroom_category/categories.html",
      },
      {
        id: 3,
        name: "Investor Relations",
        link: "https://www.pos.com.my/investor-relations",
      },
      {
        id: 4,
        name: "Corporate Governance",
        link: "https://www.pos.com.my/corporate-governance/",
      },
      {
        id: 5,
        name: "Sustainability",
        link: "https://www.pos.com.my/sustainability/",
      },
      {
        id: 6,
        name: "Legal",
        link: "https://www.pos.com.my/legal/disclaimer",
      },
      {
        id: 7,
        name: "Promotions",
        link: "https://www.pos.com.my/updates/promo.html/",
      },
      {
        id: 8,
        name: "Service Update",
        link: "https://www.pos.com.my/news-info/",
      },
    ],
  },
  {
    title: "HELP",
    id: 104,
    data: [
      {
        id: 1,
        name: "Contact Us",
        link: "https://www.pos.com.my/contact-us//#AskPos",
      },
      {
        id: 2,
        name: "FAQs",
        link: "https://www.pos.com.my/faq/",
      },
      {
        id: 3,
        name: "Our Locations",
        link: "https://www.pos.com.my/pos-outlet-finder",
      },
      {
        id: 4,
        name: "Scam Alerts",
        link: "https://www.pos.com.my/news-info/scam.html",
      },
    ],
  },
];

function Lists({ items }) {
  const handleFooterMenuEvent = (category) => {
    // On Click of Footer Menu item list
    window.dataLayer.push({
      event: 'footer_menu',
      event_category: 'Menu',
      event_action: 'Click Footer Menu',
      event_label: category,
    });
  };
  return (
    <div className="row">
      {items.map((item, index) => (
        <ul className="col-lg-3 col-md-6 col-sm-6 col-6" key={index}>
          <li key={index} className="footer-title-list mb-4 medium">{item.title}</li>
          <ul>
            {item.data.map((c, i) => (
              <li key={i} className="footer-item-list my-2">
                <a href={c.link} onClick={() => handleFooterMenuEvent(item.title)} >{c.name}</a>
              </li>
            ))}
          </ul>
        </ul>
      ))}
    </div>
  );
}

function Footer() {
  const { pathname } = useLocation();
  const currentPathname = pathname.split("/")[1];

  return (
    <>
      {currentPathname === "" &&
        <div className="footer">
          <div className="upper-footer">
            <UpperFooter />
          </div>
          <div className="footerContainer">
            <div className="pos col-sm-2 col-md-2 mb-5">
              <div>
                <Logo width={159.99} height={68.66} />
              </div>
              <div className="mt-5 drhb">
                <p className="mb-0">A member of</p>
                <img src={drhb} alt="drhb"/>
              </div>
              <div>

              </div>
            </div>
            <div className="list col-sm-8 col-md-8">
              <Lists items={listing} />
            </div>
          </div>
        </div>
      }
    </>
  );
}

export default Footer;

const UpperFooter = () => {
  const { t } = useTranslation();

  // Function to handle the click event and push data to the dataLayer.
  const handleChatEvent = () => {
    // On Click of Chat With Us
    window.dataLayer.push({
      event: 'go_to_page',
      event_category: 'Home',
      event_action: 'Go To Page',
      event_label: 'Chat with Us',
    });
  };

  return (
    <>
      <div className="d-flex flex-wrap justify-content-center">
        <h2 className="upper-footer-h2 mt-2">{t('chat_title_1')}</h2>
        
        <a href="https://www.pos.com.my/contact-us#AskPos" target="_blank" rel="noreferrer" onClick={handleChatEvent}>
          <div className="chat mt-2 mt-sm-0 medium">{t('chat_title_2')}</div>
          </a>
      </div>
    </>
  );
};
