import allianz_logo from '../../assets/images/allianz_logo.svg'
import zurich_logo from '../../assets/images/zurich_logo.svg'
import zurich_t_logo from '../../assets/images/zurich_t_logo.svg'
import tune_logo from '../../assets/images/tune_logo.svg'
// import rhb_logo from '../../../assets/images/insurer/rhb.svg';
import etiqa_logo from '../../assets/images/insurer/etiqa.svg'
import rhb_promo from '../../assets/images/rhb_promo.svg'
import './BrandList.scss'

function BrandList() {
  return (
    <div className='row justify-content-md-center brandlist'>
      <div className='logo-container col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12'>
        <ul className='d-flex justify-content-evenly mt-4'>
          <li>
            <img src={allianz_logo} width={'150px'} alt='Motor' />
          </li>
          <li
            className='clickable'
            onClick={() => window.open('https://www.pos.com.my/rhbi-customer-campaign', '_blank')}
          >
            <img src={rhb_promo} width={'150px'} alt='Motor' className='hover-effect' />
          </li>
          {/* <li>
                <img src={rhb_logo} width={'150px'} alt="Motor" />
              </li> */}
          <li>
            <img src={zurich_logo} width={'150px'} alt='Motor' />
          </li>
          <li>
            <img src={zurich_t_logo} width={'140px'} alt='Motor' />
          </li>
          <li>
            <img className='tune' src={tune_logo} width={'40px'} alt='Motor' />
          </li>
          <li>
            <img className='etiqa' src={etiqa_logo} width={'100px'} alt='Motor' />
          </li>
        </ul>
      </div>
    </div>
  )
}

export default BrandList
