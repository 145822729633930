import React, { useState, useEffect } from 'react';
import Col from "react-bootstrap/Col";
import Form from 'react-bootstrap/Form'
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { InputGroup } from 'react-bootstrap';
import iconError from '../../assets/images/field-icon-error.svg';
import iconSuccess from '../../assets/images/field-icon-success.svg';
import checkCirlce from '../../assets/images/check-circle-gray.svg';
import "../../assets/styles/inputStyles.scss";

function InputText(state) {
  let [fieldBgColor, setFieldBgColor] = useState('');
  let [fieldIconStatus, setFieldIconStatus] = useState(null);
  const {
    register,
    setValue,
    getValues,
    formState: { errors, dirtyFields }
  } = useFormContext({ defaultValues: { [state.register.control]: "" } }); // retrieve all hook methods

  useEffect(() => {
    /* handle field background style */
    if (state.formError || dirtyFields[state.register.control]) {
      if (!errors[state.register.control] && Boolean(getValues(state.register.control))) {
        setFieldBgColor('success');
        setFieldIconStatus(iconSuccess);
      } else if (Boolean(Object.keys(errors).length > 0 && errors[state.register.control])) {
        setFieldBgColor('error');
        setFieldIconStatus(iconError)
      } else {
        setFieldBgColor('');
        setFieldIconStatus(null);
      }
    }
  }, [state, dirtyFields, errors, getValues]);

  return (
    <Form.Group as={Col} lg={state.fieldLg} md={state.fieldMg} controlId={state.fieldControlId} className="field-wrapper form-element-container">
      <InputGroup>
        <FloatingLabel controlId={state.floatingControlId} label={state.floatingLabel}>
          {state.show && <span className='countryCode'>{state.countryCode}</span>}
          <Form.Control
            maxLength={state.maxLength}
            readOnly={state.read}
            type={state.fieldType}
            placeholder={state.fieldPlaceholder}
            className={`${fieldBgColor} ${state.countryClass}`}
            value={state.fieldValue}
            disabled={state.disabled}
            defaultValue={state.defaultValue}
            onChange={(e) => {
              let value = e.target.value
              if (state.register?.rules?.trim) value = value.replace(" ", "");
              // if (state.register?.rules?.no0) value = value.replace("0", "");
              if (state.register?.rules?.uppercase) value = value.toUpperCase();
              setValue(state.register?.control, value);
            }}
            onSelect={(e) => {
              let value = e.target.value
              state.detectEmpty(value);
              if (state.register?.rules?.trim) value = value.replace(" ", "");
              if (state.register?.rules?.no0) {
                if(value.charAt(0) === "0")
                value = value.charAt(0).replace("0", "");
              };
              if (state.register?.rules?.uppercase) value = value.toUpperCase();
              setValue(state.register.control, value);
            }}
            {...register(state.register.control, state.register.rules)}
          />
        </FloatingLabel>
        {fieldIconStatus && <img className="icon" src={`${fieldIconStatus}`} alt="icon-error" />}
        {state.validate && <img className="icon checkCircleGray" src={checkCirlce} alt="icon-error" />}
      </InputGroup>
      <ErrorMessage
        errors={errors}
        name={state.register.control}
        render={({ messages }) => {
          return <span className="error-label">{errors[state.register.control].message}</span>
        }}
      />
    </Form.Group>
  )
}

export default InputText;