import React, { useEffect, useState } from "react";
// import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
// import axios from "axios";
import InfoPop from "../Components/Tooltip";
// import PaymentBox from "../Components/Elements/PaymentBox";
import "../assets/styles/UserVerification.scss";
import { ScrollToTop } from "../Services/ScrollToTop";
import axios from "axios";
import StatusModal from "../Components/StatusModal";
import { useTranslation } from "react-i18next";
import moment from "moment";

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
// });

export default function UserVerification(props) {
  let navigate = useNavigate();
  const { t } = useTranslation();

  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   window.history.pushState(null, null, window.location.pathname);
  // };
  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  // }, []);

  if(localStorage.UserVerification === undefined) {window.location.replace('/');}
  const localDataVixncd = localStorage.UserVerification !== undefined ? JSON.parse(localStorage.UserVerification) : localStorage.UserVerification;
  const [status, setStatus] = useState(false);
  const [getQuote, setGetQuote] = useState({});
  const [startingDate, setStartingDate] = useState('');
  const [expDate, setExpDate] = useState('');
  //const localDataVixncd = JSON.parse(localStorage.UserVerification);
  // const [Error, setError] = useState("");
  // const { id } = useParams();
  localStorage.removeItem('userSelectedMinorCode')
  
  useEffect(() => {
    
    if(!localStorage.UserVerification){
      navigate(`/`)
    }
    if(!localDataVixncd?.variation){
      setStatus(true)
    } else if(localDataVixncd?.variation[0]?.desc === ""){
      setStatus(true)
    }

    localStorage.setItem("plansLoadingFirstTime", true);
    ScrollToTop();
    //const localDataVixncd = JSON.parse(localStorage.UserVerification);
    const api = localStorage.getItem("UserVerification");
    const localData = localStorage.userInitilData ? JSON.parse(localStorage.userInitilData) : [];
    const parsedApi = JSON.parse(api);
    setGetQuote(parsedApi);
    let startDate = parsedApi?.expiryDate;
    const [day, month, year] = startDate?.split("/");


    var dateObj = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10)
    );

    dateObj.setFullYear(dateObj.getFullYear() - 1);
    dateObj = moment(dateObj).add(1,'days').toDate();

    var monthShort = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
    const newExpiryDate = `${dateObj.getDate()} ${monthShort[parseInt(dateObj.getMonth())]
      } ${dateObj.getFullYear()}`;
    startDate = newExpiryDate;
    setStartingDate(startDate);

    const exp = parsedApi.expiryDate.split('/')
    const expd = exp[0] + " " + monthShort[parseInt(exp[1])-1] + " " + exp[2]
    setExpDate(expd)

    if(localData.passportNo){
      localStorage.setItem('customerGender', localData.gender);

      let passportYear = moment().diff(localData.birthDate, 'years');
      localStorage.setItem('customerAge', passportYear);
    }

    if(localData.newIc){
      let icYear = localData.newIc.slice(0,2);
      let gender = localData.newIc.charAt(11);
      gender = (gender % 2  === 0) ? "Female" : "Male";
      localStorage.setItem('customerGender', gender);

      let icDate = localData.newIc.slice(2,6);
      let _yrs = moment().year().toString();
      let curYear = parseInt(_yrs.slice(2,4));

      if(parseInt(icYear) > curYear){
        icYear ='19' + icYear;
      } else {
        icYear ='20' + icYear;
      }
      icYear = moment(icYear+icDate, 'YYYYMMDD').format('YYYY-MM-DD')
      icYear = moment().diff(icYear, 'years')
      localStorage.setItem('customerAge', icYear);
    }

    const token = process.env.REACT_APP_MDM_TOKEN;
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };

    axios
      .get(`${process.env.REACT_APP_MDMAPI_URL}api/options/cities/v1/querybypostcode?country=MY&postcode=${localData.postcode}`, config)
      .then(function (response) {
        localStorage.setItem("city", response.data?.data[0].city_name);
        localStorage.setItem("state", response.data?.data[0].state_name);
      })
      .catch(function (error) {
      });

      localStorage.setItem('getMinorCode', "");
      if(localDataVixncd.variation){
        localStorage.setItem('getVehicleName', localDataVixncd?.variation[0]?.desc);
      }

    // function getDefaultModel() {
    //   const found = localDataVixncd.variation.find(obj => {
    //     return (
    //       obj.minor === ""
    //     )
    //   })
    //   localStorage.setItem("model", found.desc);
    // }
    // getDefaultModel();
    const carAge = parseInt(localDataVixncd.makeYear)
    const currentYear = new Date().getFullYear();
    if(currentYear - carAge > 15){
      localStorage.setItem("oldCar", true)
    } else {
      localStorage.setItem("oldCar", false)
    }
    // eslint-disable-next-line
  }, []);

  const redirect = () => {
    navigate("/");
  };

  localStorage.setItem("coveragePeriod" ,startingDate +" - "+ expDate )

  function getModel(state) {
    const localDataVixncd = JSON.parse(localStorage.UserVerification);
    // const found = localDataVixncd.variation.find(obj => {
    //   return (
    //     obj.minor === state.target.value
    //   )
    // })
    // localStorage.setItem("model", found.desc);
    var minorCode = state.target.value.split(',').filter((val) => val !== "").join('*');
    var minor = state.target.value.split(',').filter((val) => val !== "");
    if(minor.length < 3){ minor.push('')}
    const getSumInsured = localDataVixncd.variation.find(data => data.minor === minor[2])
    localStorage.setItem('userSelectedMinorCode', minorCode)
    localStorage.setItem('getMinorCode', minor[2])
    localStorage.setItem('getVehicleName', getSumInsured.desc)
    localStorage.setItem('selectedVariant', state.target.value)
    // localStorage.setItem("minorCode", JSON.stringify(state.target.value));
  };


  // Function to handle the click event and push data to the dataLayer.
  const handleInfoIconClick = () => {
    // On Click of info icon
    window.dataLayer.push({
      event: 'go_to_pop_up',
      event_category: 'Policy Details',
      event_action: 'Go To Pop Up',
      event_label: 'No Claim Discount Details',
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender
    });
  };
  // Function to handle the click event and push data to the dataLayer.
  const handleYesButtonClick = () => {
    // On Click of Yes button
    window.dataLayer.push({
      event: 'submit_vehicle_policy_details',
      event_category: 'Policy Details',
      event_action: 'Submit Vehicle Policy Details',
      event_label: 'Vehicle Policy Details',
      vehicle_variant: localStorage.getVehicleName ,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      vehicle_make:localStorage.vehicleMake,
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender
    });
  };

  return (
    <>
      <div className="user-verification p-sm-0 mt-4 mt-md-4 ">
        <div className="policy-box p-4 p-sm-4 px-sm-5">
          <div className="policy-information">
            <p className="blue-title medium mb-1">{t("policy_info")}</p>
            <div className="">
              <div className="percentage-box col-12 col-md-9 p-2 mt-2">
                <div className="tip d-flex">
                {t("ncd_info_1")} {getQuote.ncdPerc+"%"} {t("ncd_info_2")}{" "}
                  <div onClick={handleInfoIconClick}>
                    <InfoPop
                      msg={t("info_pop")}
                    />
                  </div>
                </div>

                {/* <div>
                  <div>
                    <p className="bold mb-1">
                      {getQuote?.ncdPerc ? getQuote?.ncdPerc + "%" : "-"}
                    </p>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="col-12  mt-2">
              {/* <div className="tip d-flex">
                Next Renewal No Claims Discount (NCD){" "}
                <InfoPop
                  msg={
                    getQuote?.policy_info?.ncd_info.info
                      ? getQuote?.policy_info?.ncd_info.info
                      : "-"
                  }
                />
              </div> */}

              {/* <div>
                <div>
                  <p className="bold mb-1">
                    {getQuote?.ncdPerc ? getQuote?.ncdPerc : "-"}
                  </p>
                </div>
              </div> */}
              <div className="mt-2">{t("policy_duration")}</div>
              {/* <p className="bold">28/10/2023 - 28/12/2024</p> */}
              <div>
                <p className="bold mb-1">
                  {startingDate ? startingDate
                    : ""}{" "}
                  - {getQuote?.expiryDate ? expDate : ""}
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="blue-title col-12 mt-2 mb-2 medium">
              {t("vehicle_info")}
            </div>
            <div className="info-1 col-12 col-md-6">
              <div className="mb-2">
                <div>{t("vehicle_reg")}</div>
                {/* <p className="bold">VBH1234</p> */}
                <div>
                  <div>
                    <p className="bold mb-1">
                      {getQuote?.vehRegNo ? getQuote?.vehRegNo : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div>{t("vehicle_make")}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      { (Object.keys(getQuote?.arrResExtraParam || {}).length !== 0) ? getQuote?.arrResExtraParam?.item[3]?.value : "-"}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="">
                <div>Vehicle Model</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {getQuote?.ismModelCode ? getQuote.ismModelCode : "-"}
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="mb-2">
                <div>{t("vehicle_var")}</div>
                <div className="dropdown">
                  <div>
                    {getQuote.variation ? (
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={getModel}
                        defaultValue={localStorage.selectedVariant ?? ""}
                      >
                        {getQuote.variation.map((data, i) => (
                          <option value={`${data.model},${data.major},${data.minor}`} key={i} >
                            {data.desc}
                          </option>
                        ))}
                      </select>
                    ) : (
                      <div>No data found</div>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div>{t("engine_num")}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {getQuote?.engineNo ? getQuote?.engineNo : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="info-2 col-12 col-md-4 mt-3 mt-md-0">
              <div className="mb-2">
                <div>{t("chasis_num")}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {getQuote?.chassisNo ? getQuote?.chassisNo : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="mb-2">
                <div>{t("cubic_cap")}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {getQuote?.vehCapaCity ? getQuote?.vehCapaCity : "-"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="">
                <div>{t("prod_year")}</div>
                <div>
                  <div>
                    <p className="bold mb-1">
                      {getQuote?.makeYear ? getQuote?.makeYear : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer text-center mt-5">
          <p>{t("your_vehicle")}</p>
          <div>
            <button className="no-button hover" onClick={redirect}>
              {t("no")}
            </button>
            <button
              className={localDataVixncd?.variation ? "yes-button hover" : "disabled-button"}
              onClick={() => {
                handleYesButtonClick()
                navigate(`/quotation/plans`);
              }}
              disabled={localDataVixncd?.variation ? false : true}
            >
              {t("yes")}
            </button>

          </div>
        </div>
        <StatusModal status={status} handleClose ={() => setStatus(false)} type={"error"} message={t('variant_error')} handleClick={()=>navigate(`/`)}></StatusModal>
      </div>
      {/* Payment Box Section */}
      {/* <PaymentBox handlePrev={() => navigate(`/quotation/${id}`)} payment={payment} isAmountReady={false} /> */}
    </>
  );
}
