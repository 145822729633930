import { useState } from "react";
import "../assets/styles/PriceSortModal.scss";
import { useTranslation } from "react-i18next";

function PriceSortModal(props) {
  const { t } = useTranslation();
  const [isActive, setActive] = useState(props.isActive);
  const [def , setDef] = useState(true)
  const [asc , setAsc] = useState(false)
  const [desc , setDesc] = useState(false)
  const [selection, setSelection] = useState('')
  const handleSort = (index) => {
    setActive(!isActive)
    localStorage.setItem('priceSort', index);
    // props.selectionPriceSort(index);
    setSelection(index)
    if(index === 'asc'){
      setDef(false);
      setAsc(true);
      setDesc(false)
    } if (index === "desc"){
      setDef(false);
      setAsc(false);
      setDesc(true)
    }

    window.dataLayer.push({
      event: "sort_section",
      event_category: "Plans",
      event_action: "Sort Section",
      event_label: `Price - ${index === "asc" ? "Low to High" : index === "desc" ? "High to Low" : "Alphabetical Order"}`,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
    });

  }
  const chooseSort =(index) => {
    props.selectionPriceSort(index)
  }

  return (
    <div
      className="modal fade"
      id="staticBackdrop-1"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable modal-sm">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="bold fs-5 ">{t("sort")}</h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className={"price-sort-item d-flex justify-content-start bold" + (localStorage?.priceSort === 'default' ? ' item-highlight' : ' not-highlight')} >
              <span className="price-input"><input className="form-check-input" type = "radio" checked={def}></input></span>{t("default")}
            </div>
            <div className={"price-sort-item item-hover d-flex justify-content-start bold" + (localStorage?.priceSort === 'asc' ? ' item-highlight' : ' not-highlight')} 
              onClick={() => { handleSort('asc') }}>
              <span className="price-input"><input className="form-check-input" type = "radio" checked={asc}></input></span>{t("price_up")}
            </div>
            <div className={"price-sort-item item-hover d-flex justify-content-start bold" + (localStorage?.priceSort === 'desc' ? ' item-highlight' : ' not-highlight')} 
              onClick={() => { handleSort('desc') }}>
              <span className="price-input"><input className="form-check-input" type = "radio" checked={desc}></input></span>{t("price_down")}
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-center">
          <button
            // variant="secondary"
            type="button"
            className="text-white hover"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => chooseSort(selection)}
          >
            {t("Done")}
          </button>
          </div>
          {/* <div className="modal-footer d-flex justify-content-center">
            <button
              type="button"
              className="text-white"
              data-bs-dismiss="modal"
              onClick={() => { setActive(!isActive) }}
            >
              Close
            </button>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default PriceSortModal;
