import { useEffect, useState } from "react";
import CheckBoxContainer from "../../Components/Elements/CheckBoxContainer";
import Toggle from "../../Components/fields/inputToggle";
import PaymentBox from "../../Components/Elements/PaymentBox";
import PopupModal from "../../Components/AddOnsPopup";
import { ChevronDown } from "react-bootstrap-icons";
import "../../assets/styles/AddOns.scss";
// import LoaderModal from "../../Components/LoaderModal";
import StatusModal from "../../Components/StatusModal";
import axios from "axios";
import { useTranslation } from "react-i18next";
import notMatchBg from "../../assets/images/insurer/not-match-bg.svg";
import LoaderModal2 from "../../Components/LoaderModal2";
import allianz from "../../assets/images/insurer/aliianz.svg";
import etiqa from "../../assets/images/insurer/etiqa.svg";
import rhb from "../../assets/images/insurer/rhb.svg";
import tokio from "../../assets/images/insurer/tokio.svg";
import zurich from "../../assets/images/insurer/zurich.svg";
import tune from "../../assets/images/tune_logo.svg";
import zurichTakaful from "../../assets/images/insurer/Zurich-Takaful-logo-1.svg"
import Stepper from "../../Components/Elements/Stepper";
import { useNavigate } from "react-router-dom";

export let item = localStorage.getItem("addonsBenefit") ? JSON.parse(localStorage.getItem("addonsBenefit")) : [];
export let drivers;

function AddOns(props) {
  if(localStorage.userInitilData === undefined) {window.location.replace('/');}
  const localDataVixncd = JSON.parse(localStorage.UserVerification);
  const userInitialData = JSON.parse(localStorage.userInitilData);

  drivers = [{name: "THE INSURED", icNumber: userInitialData.newIc, drvPassport: userInitialData.passportNo, drvDob: userInitialData.birthDate}] 

  const logoArray = {
    "13": { "name": "ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD", "logo": allianz },
    "18": { "name": "ZURICH GENERAL INSURANCE MALAYSIA BERHAD", "logo": zurich },
    "97": { "name": "ZURICH GENERAL TAKAFUL MALAYSIA BERHAD", "logo": zurichTakaful },
    "96": { "name": "ETIQA GENERAL TAKAFUL BERHAD", "logo": etiqa },
    "91": { "name": "TOKIO MARINE INSURANS (MALAYSIA) BERHAD", "logo": tokio },
    "21": { "name": "RHB INSURANCE BERHAD", "logo": rhb },
    "40": { "name": "TUNE PROTECT MALAYSIA", "logo": tune },
  }
  let navigate = useNavigate();
  const { t } = useTranslation();
  const addonsTitle = {
    allDrivers: t("allDrivers"),
    // allDriversI : "All Drivers (Individual)",
    wsc: t("wsc"),
    cvpp1cart: t("cvpp1cart"),
    cvpp4cart: t("cvpp4cart"),
    flood: t("flood"),
    llop: t("llop"),
    lltp: t("lltp"),
    ehailing: t("ehailing"),
    untow2: t("untow2"),
    waiver: t("waiver"),
    and: t("and"),
  };
    const localData = JSON.parse(localStorage.userInitilData);
    var gender = localData.newIc !== '' ? parseInt(localData.newIc.at(-1)) % 2 === 0 ? 'F' : 'M' : localData.gender.at(0);
    const getSelectedVariant = localStorage.selectedVariant?.split(',');
    let selectedVariant;
    if(localStorage.selectedVariant !== undefined){
      if(getSelectedVariant[2] === ''){getSelectedVariant.pop()}
      selectedVariant = getSelectedVariant?.join('*');
    }
    var makeCodeMinor = localDataVixncd?.arrResExtraParam.item[localDataVixncd.arrResExtraParam.item.findIndex(item => item.indicator === "DISTRIBUTOR_MODELCODE")];
    makeCodeMinor = getSelectedVariant ? selectedVariant : localStorage.getItem('userSelectedMinorCode') ? localStorage.getItem('userSelectedMinorCode') : makeCodeMinor.value.split('|')[0];

  const requestBody = {
    "actualMarketValue" : localStorage.getItem("actMarket")?localStorage.getItem("actMarket") : "",
    "actualSumInsured" : localStorage.getItem("actSum")? localStorage.getItem("actSum") : "",
    "compCode": localStorage.getItem("selectedCode"),
    "agentCode":"POS",
    "newIc": localData.newIc,
    "passportNo": localData.passportNo,
    "coverCode": localStorage.getItem("coverCode"), 
    "channel": "POS",
    "gender": gender,
    "birthDate": localData.birthDate ? localData.birthDate : localStorage.getItem("dateOfBirth"),
    "address1": "DEFAULT",
    "postCode": localData.postcode,
    "maritalStatus": localData.maritalStatus === "SINGLE" ? "S" : "M",
    "makeYear": localDataVixncd.makeYear,
    "regNo": localData.regNo,
    "chassisNo": localDataVixncd.chassisNo,
    "sumInsured": localStorage.getItem("chosedCoverage") === null ? localStorage.actSum : localStorage.chosedCoverage === "0" ? localStorage.actSum : localStorage.actMarket,
    "engineNo": localDataVixncd.engineNo,
    "makeCodeMajor": localDataVixncd.ismMakeCode,
    "makeCodeMinor": makeCodeMinor,
    "vehCapacity": parseInt(localDataVixncd.vehCapaCity),
    "requestId": localStorage.getItem("requestID")? localStorage.getItem("requestID") : "",
    "keepFlag":"Y",
    "vehCapacityCode": "CC",
    "seatCapacity": JSON.parse(localStorage.isCar) !== true ? "2" : "5",
    "vehTypeCode": localDataVixncd.vehTypeCode,
    "effectiveDate": localStorage.getItem("effectiveDate"),
    "effectiveTime": localStorage.getItem("effectiveTime"),
    "expiryDate": localStorage.getItem("expiryDateFormatted"),
    "piamDrv": JSON.parse(localStorage.isCar) ? "03" : "05",
    "ncdPerc": localDataVixncd.ncdPerc,
    "ownershipType": "I",
    "useCode": localDataVixncd.veHuseCode,
    "addBenData":{
      "item": item
    },
    "addDrvData": {
      // "item": drivers
      "item": localStorage.driversName ? JSON.parse(localStorage.driversName) : drivers
    }
  }

  const payment = {
    title: "Premium Details",
    plan: "Allianz Longname Comprehensive",
    coverage: "30/12/2022 - 30/12/2023",
    sum_insured: "RM 10,000",
    total: "RM834.000",
    total_highlight: "Price breakdown",
    buttonLabel: `${t("next")}`,
    sections: [
      {
        details: [
          {
            label: "Basic Premium/Contribution",
            value: "RM 1,718.69",
          },
          {
            label: "NCD (55%)",
            value: "-RM 845.28",
          },
        ],
      },
      {
        details: [
          {
            label: "Gross Premium/Contribution",
            value: "RM 967.99",
          },
          {
            label: "Rebate Amount",
            value: "-RM 18.10",
          },
          {
            label: "SST(8%)",
            value: "RM 23.22",
          },
          {
            label: "Stamp Duty",
            value: "RM 10.00",
          },
        ],
      },
      {
        details: [
          {
            label: "Total",
            value: "RM 1,234.00",
            isLabelBold: true,
            isValueBold: true,
          },
        ],
      },
    ],
  };

  const [data, setData] = useState();
  const handleToggle1 = (bencode, bentitle) => {
    setLoading(true);
    let addon = { benCode: bencode, benDesc: bentitle };

    if (item.some((id) => id.benCode === bencode)) {
      if (bencode === "AND") {
        requestBody.addDrvData.item = [];
      }
      item = item.filter((data) => data.benCode !== bencode);
      requestBody.addBenData.item = item;
      fetchpremium(requestBody);
    } else {
      item.push(addon);
      if (bencode === "AND") {
        setDriversModal(true)
      }
      item.forEach((data) => {
        if (data.benCode === "WSC") {
          addon.sumInsured = 500;
        }
        // item.push(addon)
      });
      if (bencode === "AND") {
        item = item.filter((data) => data.benCode !== "ALLD");
      } else if (bencode === "ALLD") {
        item = item.filter((data) => data.benCode !== "AND");
        drivers = [];
      }
      requestBody.addBenData.item = item;
      requestBody.addDrvData.item = drivers;
      fetchpremium(requestBody);
    }
    localStorage.setItem("addonsBenefit", JSON.stringify(item));

    if (allBen.find((data) => data.benefitCode === "ALLD")) {
      if (item.length < allBen.length - 1) {
        setCheck(false);
        localStorage.setItem("allBen", false);
      } else {
        setCheck(true);
        localStorage.setItem("allBen", true);
      }
    } else if (allBen.find((data) => data.benefitCode === "AND")) {
      if (item.length < allBen.length - 1) {
        setCheck(false);
        localStorage.setItem("allBen", false);
      } else {
        setCheck(true);
        localStorage.setItem("allBen", true);
      }
    } else if (item.length === allBen.length) {
      setCheck(true);
      localStorage.setItem("allBen", true);
    } else if (item.length !== allBen.length) {
      setCheck(false);
      localStorage.setItem("allBen", false);
    }
  };

  const [sumInsuredModal, setSumInsuredModal] = useState(false);
  const handleInsuredModal = (amount) => {
    setLoading(true);
    requestBody.sumInsured = amount;
    fetchpremium(requestBody);
    setSumInsuredModal(false);
  };

  const closeInsured = () => {
    setSumInsuredModal(false);
  };

  const [coverageModal, setCoverageModal] = useState(false);
  const handleCoverageModal = (value) => {
    requestBody.addBenData.item.forEach((item) => {
      if (item.benCode === "WSC") {
        item.sumInsured = value;
        localStorage.setItem("wscCoverage", value);
        setSum(localStorage.getItem("wscCoverage"));
        setLoading(true);
        fetchpremium(requestBody);
      }
    });
    localStorage.setItem("addonsBenefit", JSON.stringify(item));
    // setCoverageModal(false);
  };

  const [sum, setSum] = useState(localStorage.getItem("wscCoverage"));
  const [driversModal, setDriversModal] = useState(false);
  const closeDriverModal = () => {
    if(drivers === undefined || !Array.isArray(drivers)) {
      item = item.filter(i => i.benCode !== "AND")
      requestBody.addBenData.item = item;
      setLoading(true)
      fetchpremium(requestBody);
      localStorage.setItem("addonsBenefit", JSON.stringify(item));
    }
    setDriversModal(false);
  };
  const closeWscModal = () => {
    setCoverageModal(false);
  };
  const handleDriversModal = (payload, dataNum) => {
    var objs = [];
    
    Object.keys(payload).forEach(function (key) {
      var match = key.match(/(.*)(\d.*)$/);
      var newKey = match[1];
      var index = parseInt(match[2]);
      objs[index] = objs[index] || {};
      objs[index][newKey] = payload[key];
    });
    let count = 1;
    for(let i =0 ; i<objs.length; i++){
      if (objs[i].name !== "" && objs[i].name !== '' && objs[i].icNumber !== "" && objs[i].icNumber !== ''){
        count = count + 1;
      }
    }
    requestBody.addBenData.item.forEach((item) => {
      if (item.benCode === "AND") {
        item.unit = count;
        var obj = [];
        for(let i =0 ; i<objs.length; i++){
          if (objs[i].name !== "" && objs[i].name !== '' && objs[i].icNumber !== "" && objs[i].icNumber !== ''){
            obj.push(objs[i])
          }
        }
        drivers = drivers.concat(obj);
        requestBody.addDrvData.item = drivers;
        setLoading(true);
        fetchpremium(requestBody);
        localStorage.setItem("driversName", JSON.stringify(drivers))
      }
    });
    setDriversModal(false);
  };


  const ulimitedDrivers = () => {
    item.push({ benCode: "ALLD", benDesc: "ALL DRIVERS" });
    item = item.filter((data) => data.benCode !== "AND");
    requestBody.addBenData.item = item;
    fetchpremium(requestBody);
    setDriversModal(false);
    setLoading(true);
  };

  const handleModal = (sumInsured) => {
    setLoading(true);
    requestBody.sumInsured = sumInsured;
    fetchpremium(requestBody);
    setSumInsuredModal(false);
  };

  const [showLoader, setShowLoader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [premiumdetails, setPremiumdetails] = useState();
  const [status, setStatus] = useState(false);
  const [reqBody] = useState(requestBody);
  const [errorMessage, setErrorMessage] = useState(t("all_error_req"));
  const [type, setType] = useState("error");
  const [remove, setRemove] = useState();

  const handleCloseStatusModal = (remove) => {
    if(type === "error") setStatus(false)
    if(type === "notAvailable") {
      setStatus(false);
      if(localStorage.allBen === "false"){
        item = item.filter(i => i.benCode !== remove);
        localStorage.setItem("addonsBenefit", JSON.stringify(item));
      } else {
        item = []
        localStorage.setItem("addonsBenefit", JSON.stringify(item))
        localStorage.setItem("allBen", false)
        setCheck(false)
      }
    }
  }

  const openModal = (index, event, bencode, bentitle) => {
    event.stopPropagation();
    event.preventDefault();
    if (data[index]?.benefitCode === "WSC") {
      let addon = { benCode: bencode, benDesc: bentitle };
      setCoverageModal(true);
        if (item.find((items) => items.benCode === bencode)) {
        } else {
          item.push(addon);
          if (bencode === "WSC") {
            if (addon.sumInsured !== Number) {
              item.forEach((data) => {
                if (data.benCode === "WSC") {
                  data.sumInsured = 500;
                }
              });
              requestBody.addBenData.item = item;
              fetchpremium(requestBody);
              setLoading(true);
            }
        }
        localStorage.setItem("addonsBenefit", JSON.stringify(item));
      }
    }
    if (data[index]?.benefitCode === "AND"){
      let addon = { benCode: bencode, benDesc: bentitle };
      setDriversModal(true);
        if (item.find((items) => items.benCode === bencode)) {
        } else {
          item.push(addon);
          item = item.filter((data) => data.benCode !== "ALLD");
          requestBody.addBenData.item = item;
              fetchpremium(requestBody);
              setLoading(true);
          localStorage.setItem("addonsBenefit", JSON.stringify(item));
      }
    }
  };
  const vehData = localStorage.getItem("UserVerification");
  const parsedVehData = JSON.parse(vehData);
  const showPriceRange = () => {
    setSumInsuredModal(true);
  };
  const url = process.env.REACT_APP_AWS_URL;
  

  // const [finishStatus, setfinishStatus] = useState(false);

  // const onBackButtonEvent = (e) => {
  //   e.preventDefault();
  //   // if (!finishStatus) {
  //   setStatus(true);
  //   setErrorMessage(
  //     t("error8")
  //   );
  //   window.history.pushState(null, null, window.location.pathname);
  //   // if (window.confirm("Do you want to go back ? You will lose all you data and start from the beginning")) {
  //   //     setfinishStatus(true)
  //   //     // your logic
  //   //     window.history.back();
  //   //     // props.history.push("/");
  //   // } else {
  //   //     window.history.pushState(null, null, window.location.pathname);
  //   //     setfinishStatus(false)
  //   // }
  //   // }
  // };
  // useEffect(() => {
  //   window.history.pushState(null, null, window.location.pathname);
  //   window.addEventListener("popstate", onBackButtonEvent);
  //   // return () => {
  //   //   window.removeEventListener("popstate", onBackButtonEvent);
  //   // };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  
  useEffect(() => {
    // fetchpremium(reqBody);
    const body = {
      compCode: localStorage.getItem("selectedCode"),
      coverCode: localStorage.getItem("coverCode"),
      vehTypeCode: localDataVixncd?.vehTypeCode,
    };
 
    axios.post(`${url}api/benefit`, body, header).then(function (response) {
      setData(response.data.data);
      localStorage.setItem("benefit", JSON.stringify(response.data.data));
    });
    requestBody.actualMarketValue = localStorage.getItem("actMarket")?localStorage.getItem("actMarket") : "";
    requestBody.actualSumInsured = localStorage.getItem("actSum")? localStorage.getItem("actSum") : "";
    requestBody.compCode = localStorage.getItem("selectedCode")
    fetchpremium(requestBody);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reqBody]);

  const benefit = localStorage?.benefit
    ? JSON.parse(localStorage?.benefit)
    : [];

  const header = {
    headers: {
      "Content-Type": "application/json",
      "Authorization": localStorage.getItem('api_token'),
      "X-Api-Key": process.env.REACT_APP_AUTH_KEY
    }
  };
  const fetchpremium = (requestBody) => {
    axios
    .post(`${process.env.REACT_APP_AWS_URL}api/premium`, requestBody, header)
    .then(function (data) {
      setPremiumdetails(data?.data.data.item[0]);
      localStorage.setItem(
        "PaymentboxDetails",
        JSON.stringify(data?.data.data.item[0])
      );
      setShowLoader(false);
      setLoading(false);
    })
    .catch(function (error) {
      if(error.response.data.errors[0].code === "3"){
        setType("notAvailable")
        setErrorMessage(t("not_available"));
        setStatus(true);
        if(localStorage.allBen){
          setRemove(requestBody.addBenData.item[requestBody.addBenData.item.length -1].benCode)
        } else {
          setRemove(requestBody.addBenData.item = [])
        }  
      }
      setShowLoader(false);
        // setErrorMessage(t("expired"));
        // setStatus(true);
        setLoading(false);
    });

    // fetch(`${url}api/premium`, {
    //   method: "POST",
    //   headers: header.headers,
    //   body: JSON.stringify(requestBody),
    // })
    //   .then((response) => response.json())
    //   .then((data) => {
    //     setPremiumdetails(data?.data.item[0]);
    //     localStorage.setItem(
    //       "PaymentboxDetails",
    //       JSON.stringify(data?.data.item[0])
    //     );
    //     setShowLoader(false);
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setShowLoader(false);
    //     setErrorMessage(t("expired"));
    //     setStatus(true);
    //     setLoading(false);
    //   });
  };

  const [check, setCheck] = useState(localStorage.getItem("allBen") === "true" ? true : false);
  const allBen = JSON.parse(localStorage.getItem("benefit"));

  const selectAll = () => {
    setLoading(true);
    setCheck(!check);
    let addon = { benCode: allBen.benefitCode, benDesc: allBen.benefitTitle };
    item.length = 0;
    for (let i = 0; i < allBen.length; i++) {
      addon[i] = {
        benCode: allBen[i].benefitCode,
        benDesc: allBen[i].benefitTitle,
      };
      item.push(addon[i]);
    }
    if (check === false) {
      if (item.some((data) => data.benCode === "AND")) {
        const allSelected = item.filter((data) => data.benCode !== "AND");
        allSelected.forEach((data) => {
          if (data.benCode === "WSC") {
            data.sumInsured = 500;
          }
        });

        item = allSelected;
        requestBody.addBenData.item = allSelected;
        fetchpremium(requestBody);
        localStorage.setItem("addonsBenefit", JSON.stringify(allSelected));
        // localStorage.setItem("")
      } else {
        requestBody.addBenData.item = item;
        fetchpremium(requestBody);
        localStorage.setItem("addonsBenefit", JSON.stringify(item));
      }
      localStorage.setItem("allBen", true);
      handleSelectAll();
    } else {
      item = [];
      requestBody.addBenData.item = item;
      fetchpremium(requestBody);
      localStorage.setItem("addonsBenefit", JSON.stringify(item));
      localStorage.setItem("allBen", false);
    }
  };

  const handleSelectAll =()=>{
    window.dataLayer.push({
      event: "motor_enable_select_all_recommendation",
      event_category: "Premium Details",
      event_action: "Enable Select All Recommendations",
      event_label: "Select All Recommendations",
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
    });
  }

  const [policyTypes] = useState({
    "01": `${t("comprehensive")}`,
    "02": `${t("third_party")}`,
    "03": `${t("third_party_ft")}`,
  });
  const handleAddOns =()=>{
    const addonsBenefitString = localStorage.getItem('addonsBenefit');
    let addonBenefitsLog = '';

    if (addonsBenefitString) {
      try {
        const addonsBenefit = JSON.parse(addonsBenefitString);

        addonsBenefit.forEach((benefit, index) => {
          addonBenefitsLog += `Addon ${index + 1}:`;
          Object.keys(benefit).forEach(subKey => {
            addonBenefitsLog += `${benefit[subKey]}`;
          });
          addonBenefitsLog += '';
        });
      } catch (error) {
        console.error('Error parsing addonsBenefit from localStorage:', error);
      }
    } else {
      addonBenefitsLog = 'None'; // Set message for addonsBenefit not found
    }

    window.dataLayer.push({
      event: 'filter_section',
      event_category: 'Plans',
      event_action: 'Filter Section',
      event_label: `Add Ons - ${addonBenefitsLog}`,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
    });

    const selectedItem = logoArray[premiumdetails?.compCode]?.name.split(" ")[0]+" "+logoArray[premiumdetails?.compCode]?.name.split(" ")[1]+" "+policyTypes[localStorage.coverCode];
    window.dataLayer.push({
      event: "add_to_cart",
      event_category: "Premium Details",
      event_action: "Add To Cart",
      event_label: "Coverage Value Preference",
      currency: "MYR",
      value: premiumdetails?.grossDue,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
      vehicle_make:localStorage.vehicleMake,
      vehicle_variant: localStorage.getVehicleName,
      items: [{
        item_id: premiumdetails?.compCode,
        item_name: selectedItem,
        item_brand: logoArray[premiumdetails?.compCode]?.name.split(" ")[0],
        item_category: "Vehicle Insurance",
        item_category_2: localStorage.isCar===true?"Motorcycle":"Car",
        item_category_3: policyTypes[localStorage.coverCode],
        item_variant: "Market Value",
        currency: "MYR",
        price: premiumdetails?.grossDue,
        quantity: "1",}]
    })
  }

  const initialSteps = [
    { value: 1, label: "Plans", isActive: false, isRenderComponent: false },
    { value: 2, label: "Add-Ons", isActive: true, isRenderComponent: true },
    {
      value: 3,
      label: "Customer Details",
      isActive: false,
      isRenderComponent: false,
    },
    { value: 4, label: "Checkout", isActive: false, isRenderComponent: false },
  ];

  localStorage.setItem("addonsBenefit", JSON.stringify(item));

  return (
    <>
    <div
        className="mt-4 subBlueTitle cursor-pointer "
        onClick={() => navigate(-1)}
      >
        {t("bck_button")}
      </div>

      <div className="quotation-wrapper-stepper">
        <Stepper steps={initialSteps} activeStep={1}/>
      </div>
      {!showLoader && (
        <div className="addons">
          <div className="options">
            <div className="mb-4">
              <div>
                {localStorage.getVehicleName} {parsedVehData?.makeYear} -{" "}
                {parsedVehData?.vehRegNo}
              </div>
              {premiumdetails.coverCode === '02' ? '' :
                <div>
                {t("sum_insured")}:{" "}
                <span className="subBlueTitle">
                  RM {parseFloat(premiumdetails?.sumInsured).toLocaleString()}{" "}
                  (NCD {premiumdetails?.ncdPerc}%)
                </span>{" "}
                <span className="popup-link" onClick={() => showPriceRange()}>
                  <ChevronDown />
                </span>
              </div> 
              }
              <PopupModal
                showInsured={sumInsuredModal}
                closeInsured={closeInsured}
                handleHideInsured={handleInsuredModal}
                handleCloseCoverage={closeWscModal}
                showAdditionalDriver={driversModal}
                closeDriverModal={closeDriverModal}
                handleHideAdditionalDriver={handleDriversModal}
                unlimitedDrivers={ulimitedDrivers}
                showCoverage={coverageModal}
                handleHideCoverage={handleCoverageModal}
                actualSumInsured={premiumdetails?.actualSumInsured}
                handleModal={handleModal}
                actualMarketValue={premiumdetails?.actualMarketValue}
              />
              <div className="mt-2">
                {premiumdetails && (
                  <img
                    className={premiumdetails?.compCode === "40" ? "tune" : ""}
                    src={logoArray[premiumdetails?.compCode]?.logo}
                    alt={`${
                      logoArray[premiumdetails?.compCode]?.name
                    }-logo"`}
                  />
                )}
              </div>
              {/* <div className="mb-3">
            {highlights.map((highlight, index) => (
              <Badge key={index} data={highlight}></Badge>
            ))}
          </div> */}
              {/* recommend section */}
              {/* <div className="recommend">
            {data.map((data, index) => (
              <div key={index}>
                <CheckBoxContainer
                  data={data}
                  title={data.title}
                  info={data.info}
                  pop={data.pop}
                  val={data.id}
                  options={data.options}
                  amount={data.amount}
                  isTitleBold={true}
                  index={index}
                  handleChangeEvent={() => handleToggle(index)}
                />
              </div>
            ))}
          </div> */}
              <div className="addons-more">
                <div className="mt-4 mb-4">{t("add_on")}</div>
                <div className="toggle">
                  <Toggle
                    type="toggle"
                    label={t("select_all")}
                    toggle={selectAll}
                    check={check}
                  ></Toggle>
                </div>
              </div>
              {/* more section */}
              <div className="more">
                {loading && <LoaderModal2 show={loading} />}
                {benefit.map((addon, index) => (
                  <div key={addon.id}>
                    <CheckBoxContainer
                      title={
                        addon.benefitCode === "CVPALL01FLD"
                          ? t("addon_1")
                          : addon.benefitCode === "ALLD"
                          ? t("addon_2")
                          : addon.benefitCode === "ALD"
                          ? t("addon_2")
                          : addon.benefitCode === "AND"
                          ? t("addon_3")
                          : addon.benefitCode === "WSC"
                          ? t("addon_4")
                          : addon.benefitCode === "LLOP"
                          ? t("addon_5")
                          : addon.benefitCode === "LLTP"
                          ? t("addon_6")
                          : addon.benefitCode === "UNTOW01" ||
                            addon.benefitCode === "UNTOW02"
                          ? t("addon_7")
                          : addon.benefitCode === "EHAILING"
                          ? t("addon_8")
                          : addon.benefitCode === "CVPP401C002"
                          ? t("addon_9")
                          : addon.benefitCode === "CVPP4CART"
                          ? t("addon_10")
                          : addon.benefitCode === "CVPP1CART"
                          ? t("addon_12")
                          : addon.benefitCode === "CVPALL02LLTP"
                          ? t("addon_6")
                          : addon.benefitCode === "CVPALL02LLOP"
                          ? t("addon_5")
                          : addon.benefitTitle
                      }
                      info={
                        addon.benefitCode === "ALLD"
                          ? `${addonsTitle.allDrivers}`
                          : addon.benefitCode === "ALD"
                          ? `${addonsTitle.allDrivers}`
                          : addon.benefitCode === "WSC"
                          ? `${addonsTitle.wsc}`
                          : addon.benefitCode === "CVPP1CART" || addon.benefitCode === "CVPP4CART"
                          ? `${addonsTitle.cvpp1cart}`
                          : addon.benefitCode === "CVPALL01FLD" ||
                            addon.benefitCode === "CVMALL01FLD"
                          ? `${addonsTitle.flood}`
                          : addon.benefitCode === "LLOP"
                          ? `${addonsTitle.llop}`
                          : addon.benefitCode === "LLTP"
                          ? `${addonsTitle.lltp}`
                          : addon.benefitCode === "AND"
                          ? `${addonsTitle.and}`
                          : addon.benefitCode === "CVPALL02LLOP"
                          ? `${addonsTitle.llop}`
                          : addon.benefitCode === "CVPALL02LLTP"
                          ? `${addonsTitle.lltp}`
                          : addon.benefitCode === "EHAILING"
                          ? `${addonsTitle.ehailing}`
                          : addon.benefitCode === "CVPP101C002"
                          ? `${addonsTitle.waiver}`
                          : addon.benefitCode === "UNTOW01"
                          ? `${addonsTitle.untow2}`
                          : addon.benefitCode === "UNTOW01"
                          ? `${addonsTitle.untow2}`
                          : addon.benefitCode === "CVPP401C002"
                          ? `${addonsTitle.waiver}`
                          : ""
                      }
                      pop={addon.pop}
                      val={addon.benefitCode}
                      options={
                        addon.benefitCode === "WSC"
                          ? {
                              label: `${t("coverage")}`,
                              highlight: `RM ${
                                premiumdetails?.addBenData?.item.find(
                                  (data) => data.benCode === addon.benefitCode
                                )?.sumInsured
                                  ? premiumdetails?.addBenData?.item.find(
                                      (data) =>
                                        data.benCode === addon.benefitCode
                                    )?.sumInsured
                                  : sum
                                  ? sum
                                  : 500
                              }`,
                            }
                          : addon.benefitCode === "AND"
                          ? { label: `${t("driver")}`, highlight: "All" }
                          : ""
                      }
                      amount={premiumdetails?.addBenData?.item.find((data) => data.benCode === addon.benefitCode)
                        ?.benPremium.toFixed(2)}
                      isBgColor={false}
                      isTitleBold={true}
                      type="checkbox"
                      handleChangeEvent={() =>
                        handleToggle1(
                          addon.benefitCode,
                          addon.benefitTitle,
                          addon.id,
                          index
                        )
                      }
                      handleClick={(event) =>
                        openModal(
                          index,
                          event,
                          addon.benefitCode,
                          addon.benefitTitle
                        )
                      }
                      checked={item.includes(
                        item.find((data) => data.benCode === addon.benefitCode)
                      )}
                    />
                  </div>
                ))}
              </div>
            </div>
            {benefit.length === 0 && (
              <div className="addons-not-match text-center">
                <img src={notMatchBg} alt="not-match" />
                <div className="label">
                  {t("error6")}
                </div>
              </div>
            )}
          </div>
          {/* Payment Box Section */}
          <PaymentBox
            handlePrevious={() => {navigate('/quotation/customer-details'); handleAddOns();}}
            premiumdetails={premiumdetails}
            payment={payment}
            logoarray={props.logoArray}
            isAmountReady={true}
          />
        </div>
      )}
      {showLoader && <LoaderModal2 show={showLoader ? 1 : 0} />}
      <StatusModal
        status={status}
        handleClose={() => handleCloseStatusModal(remove)}
        type={type}
        message={errorMessage}
      />
      <div></div>
    </>
  );
}

export default AddOns;