import rtax from "../assets/images/image 27.png";
import "../assets/styles/AdvantageContainer.scss";
import React from "react";
import { useTranslation } from "react-i18next";
import car from "../assets/images/car.png";
import motor from "../assets/images/motor.png";
import i18next from "i18next";

function RoadtaxContainer() {
  const { t } = useTranslation();

  return (
    <>
      <div className="advantage-container d-flex flex-column flex-lg-row flex-wrap mt-5">
        <div className="advantage col-12 col-sm-12 col-lg-6 col-xl-6 p-sm-0 px-sm-4 pt-sm-3 p-4">
          <div>
            <h1 className="adv-title-1 bold">
              {t("rtxTitle_1")}{" "}
              <span className="subBlueTitle">{t("rtxTitle_2")} </span>{" "}
              {t("rtxTitle_3")}
            </h1>
            <p className="adv-title-2">{t("rtxInfo")}</p>
          </div>
          <div className="adv-list d-flex flex-wrap mb-3">
            <div
              className={"vehicleBox2 wblueBg mb-2 hover "}
              onClick={() => {
                setTimeout(function () {
                  document.location = "#section2";
                }, 500);
                document.getElementById("vhl").classList.add("hint");
                localStorage.setItem("isCar", true);
                localStorage.setItem("isBike", false);
                window.dataLayer.push({
                  vehicle_type: "car",
                });
                window.open("https://roadtax.pos.com.my/","_blank")
              }}
            >
              <div className="motor">
                <div className="imageBox">
                  <img src={car} width={"100px"} alt="Car" />
                </div>
                <div
                  className="content medium"
                  style={{
                    lineHeight: i18next.language === "en" ? 5 : 1,
                    paddingTop: i18next.language === "en" ? "0" : "1.6rem",
                  }}
                >
                  {t("rtxcar")}
                </div>
              </div>
            </div>
            <div
              className={"vehicleBox2 wblueBg mb-2 hover "}
              onClick={() => {
                setTimeout(function () {
                  document.location = "#section2";
                }, 500);
                localStorage.setItem("isCar", false);
                localStorage.setItem("isBike", true);
                window.dataLayer.push({
                  vehicle_type: "motorcycle",
                });
                window.open("https://roadtax.pos.com.my/","_blank")
              }}
            >
              <div className="motor">
                <div className="imageBox">
                  <img src={motor} width={"100px"} alt="Motor" />
                </div>
                <div
                  className="content d-block ms-5 medium"
                  style={{ lineHeight: 1, paddingTop: "1.6rem" }}
                >
                  {t("rtxmotor")}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="rtax-img col-sm-12 col-lg-6 col-xl-6">
          <img src={rtax} alt="road-tax" />
        </div>
      </div>
    </>
  );
}

export default RoadtaxContainer;
