import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import { useState } from "react";
import { useTranslation } from "react-i18next";

function Profile() {
     const [text1, setText1] = useState("First");
     let navigate = useNavigate();
       const { t } = useTranslation();
     return (
          <div>Profile Page
               <h2>{t('welcome')}</h2>
               <Button variant="primary">Primary Button</Button>{' '}
               <input onChange={(e)=>setText1(e.target.value)} value={text1}/>
               <button onClick={() => navigate("/about")}>Change to About page</button>
          </div>
     );
};

export default Profile;