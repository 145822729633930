/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useTranslation } from "react-i18next";
import "../assets/styles/AddOnsContainer.scss";
import officerdesktop from "../assets/images/insurance-officer-desktop.png";
import {ArrowRight} from "react-bootstrap-icons";
function AddOnsContainer() {
  const { t } = useTranslation();

  return (
    <>
      <div className="add-on-container d-flex flex-wrap pt-lg-5 pt-md-5 pt-sm-1 pt-xs-1">
        <div className="left-sec col-xl-7 col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <picture>
            <source media="(min-width:1200px)" srcSet={officerdesktop}  alt="officerdesktop"/>
            <source media="(max-width:1200px)" srcSet={officerdesktop}  alt="officermobile"/>
            <img src={officerdesktop} alt="person"/>
          </picture>
        </div>
        <div className="right-sec col-xl-5 col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div className="add-ons-title">
            <h1 className="add-ons-main bold">
              <span>{t("add_ons_title_1")}</span>{t("add_ons_title_2")}
              {t("add_ons_title_3")}
            </h1>
            <br />
            {/* <p className="add-ons-below">{t("add_ons_title_4")}</p> */}
          </div>
          <div className="add-ons-para">
            <div className="add-on-scroll">
              <div className="medium label">{t('add_ons_label_1')}</div>
              <div className="medium label">{t('add_ons_label_2')}</div>
              <div className="medium label">{t('add_ons_label_3')}</div>
              <div className="medium label">{t('add_ons_label_4')}</div>
              {/* <h5 className="add-ons-title">
                Cover for Windscreens, Windows and Sunroof
              </h5>
              <p>
                Covers loss or damage to your car caused by flood, typhoon,
                hurricane, storm, tempest, volcanic eruption, earthquake,
                landslide, landslip, subsidence or sinking of the soil / earth
                or other convulsions of nature.
              </p>
              <p>
                Covers loss or damage to your car caused by flood, typhoon,
                hurricane, storm, tempest, volcanic eruption, earthquake,
                landslide, landslip, subsidence or sinking of the soil / earth
                or other convulsions of nature.
              </p>
              <p>
                Covers loss or damage to your car caused by flood, typhoon,
                hurricane, storm, tempest, volcanic eruption, earthquake,
                landslide, landslip, subsidence or sinking of the soil / earth
                or other convulsions of nature.
              </p>
              <p>
                Covers loss or damage to your car caused by flood, typhoon,
                hurricane, storm, tempest, volcanic eruption, earthquake,
                landslide, landslip, subsidence or sinking of the soil / earth
                or other convulsions of nature.
              </p> */}
            </div>
          </div>
          <div className="link">
            <button className="renewNow hover" onClick={() => {
                setTimeout(function () {
                  document.location = "#vhl";
                }, 500);}}><span><ArrowRight/></span>{t('renew_now')}</button>
            {/* <a className="medium goFaq" onClick={() => {
                setTimeout(function () {
                  document.location = "#faq";
                }, 500);
              }}>{t('comp_link')}</a> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default AddOnsContainer;