/* eslint-disable jsx-a11y/anchor-is-valid */
import "../assets/styles/ProcessContainer.scss";
// import { TelephoneFill } from "react-bootstrap-icons";
import BrandList from "./BrandList";
import { useTranslation } from 'react-i18next';

function ProcessContainer() {
  const { t } = useTranslation();

  // Function to handle the click event and push data to the dataLayer.
  const handleLinkClick = () => {

    setTimeout(function () {
      document.location = "#faq";
    }, 500);
      
    // On Click of View insurance car hotline
    window.dataLayer.push({
      event: 'go_to_page',
      event_category: 'Home',
      event_action: 'Go To Page',
      event_label: 'NEED TO KNOW MORE? READ OUR FAQ',
    });
  };
  return (
    <>
      <div className="process-container pt-lg-5 pt-md-5 pt-sm-4 pt-4 px-lg-5 px-md-5 px-sm-4 px-4 mx-auto">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6">
            <div className="left-side ">
              <h1 className="bold subBlueTitle">
                <span>{t("claim_process_title")}</span>
              </h1>
              <p>
               {t("claim_process")}
              </p>
            </div>
            <div className="brand-list col-sm-12 col-md-12 col-lg-12 d-none d-lg-block">
              <BrandList />
            </div>
          </div>
          <div className="right-side col-sm-12 col-md-12 col-lg-5 offset-sm-0 col-md-5 offset-md-0 mx-lg-4 px-lg-5">
            <div className="side-bar col-1 inlineBlk"></div>
            <div className="claim-process col-11 inlineBlk">
              <ol type="1">
                <li className="list-type medium">{t('claim_process_list_1')}</li>
                <li className="list-type medium">{t('claim_process_list_2')}</li>
                <li className="list-type medium">{t('claim_process_list_3')}</li>
                <li className="list-type medium">{t('claim_process_list_4')}</li>
              </ol>
            </div>
            <a className="inlineBlk medium hover" onClick={handleLinkClick}>
              {t('comp_link')}
            </a>
            <div className="brand-list-bottom col-sm-12 col-md-12 col-lg-12 d-lg-none">
              <BrandList/>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProcessContainer;
