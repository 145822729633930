import { useNavigate } from 'react-router-dom';

const styles = {
    logo:{
        marginTop:"9px",
        cursor: "pointer"
    }
}

function Logo({width=24, height=24}) {
  const navigate = useNavigate();
  return (
      <svg
        onClick={() => navigate("/")}
        width={width}
        height={height}
        style={styles.logo}
        viewBox="0 0 53 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1088_4954)">
          <path
            d="M22.3278 1.12749L26.3373 0.97876C26.3373 0.97876 33.9109 6.19974 34.133 6.57267C34.355 6.9456 34.8014 13.3597 34.8014 13.3597C34.8014 13.3597 29.0099 21.1912 28.7878 21.2656C28.5657 21.3399 25.1496 21.7129 25.1496 21.7129L20.7689 20.8926L17.7251 17.8349C17.7251 17.8349 16.9827 8.66041 16.9827 8.43731C16.9827 8.21421 18.5416 3.43942 18.5416 3.43942L22.3278 1.12749Z"
            fill="white"
          />
          <path
            d="M4.08672 30.9268V27.5399L2.61598 29.7566H2.5855L1.12891 27.5617V30.9268H0V25.7375H1.23995L2.61598 27.9325L3.98983 25.7375H5.22978V30.9268H4.08672Z"
            fill="#2B3990"
          />
          <path
            d="M10.0797 30.9266L9.59965 29.763H7.38973L6.91183 30.9266H5.73828L7.98086 25.7002H9.04119L11.2838 30.9266H10.0808H10.0797ZM8.49578 27.0716L7.80124 28.7536H9.19033L8.49578 27.0716Z"
            fill="#2B3990"
          />
          <path
            d="M11.7888 30.9268V25.7375H12.9395V29.8901H15.5479V30.9268H11.7888Z"
            fill="#2B3990"
          />
          <path
            d="M20.1582 30.9266L19.6792 29.763H17.4682L16.9903 30.9266H15.8179L18.0583 25.7002H19.1186L21.3612 30.9266H20.1582ZM18.5743 27.0716L17.8786 28.7536H19.2688L18.5743 27.0716Z"
            fill="#2B3990"
          />
          <path
            d="M23.668 28.8588V30.9268H22.5184V28.8806L20.5088 25.7375H21.8532L23.1019 27.8209L24.3713 25.7375H25.6798L23.668 28.8588Z"
            fill="#2B3990"
          />
          <path
            d="M27.8451 31.0001C27.0537 31.0001 26.2535 30.7278 25.6265 30.1701L26.3069 29.363C26.7771 29.749 27.2703 29.9929 27.868 29.9929C28.3393 29.9929 28.6224 29.8081 28.6224 29.504V29.4887C28.6224 29.2 28.4428 29.0513 27.5686 28.8293C26.5148 28.5624 25.8355 28.2726 25.8355 27.2424V27.2282C25.8355 26.2855 26.5964 25.6643 27.6655 25.6643C28.4264 25.6643 29.0774 25.9016 29.6076 26.3249L29.0088 27.1845C28.5462 26.8651 28.0911 26.6726 27.6513 26.6726C27.2115 26.6726 26.9785 26.8728 26.9785 27.1254V27.1396C26.9785 27.4797 27.2017 27.5913 28.1064 27.8209C29.1667 28.0954 29.7654 28.4727 29.7654 29.3783V29.3925C29.7654 30.4227 28.9729 31.0001 27.8451 31.0001Z"
            fill="#2B3990"
          />
          <path
            d="M31.8196 25.7375H30.6689V30.9268H31.8196V25.7375Z"
            fill="#2B3990"
          />
          <path
            d="M36.871 30.9266L36.392 29.763H34.181L33.702 30.9266H32.5295L34.7721 25.7002H35.8324L38.0739 30.9266H36.871ZM35.2881 27.0716L34.5925 28.7536H35.9827L35.2892 27.0716H35.2881Z"
            fill="#2B3990"
          />
          <path
            d="M44.6458 8.73919C41.54 8.04692 40.7714 7.60728 40.7714 6.60005V6.53771C40.7714 5.75249 41.5084 5.12256 43.0129 5.12256C44.9975 5.12256 47.2368 5.87716 49.2856 7.29122L52.3915 2.98342C49.9584 1.06738 46.981 0 43.1729 0C39.3649 0 36.3722 1.67872 35.1617 4.1711C33.0487 1.61966 29.7871 0 25.9639 0C22.3888 0 19.2851 1.43484 17.1557 3.71943C15.6425 1.6131 12.921 0.439639 9.38944 0.439639H0V22.4467H6.07238V16.1573H9.07592C10.8428 16.1573 12.4082 15.8664 13.7788 15.2988C13.5535 14.3911 13.4294 13.4429 13.4294 12.4663C13.4294 9.95968 14.2251 7.63462 15.5794 5.7164C15.6121 5.65406 15.7362 5.67593 15.6893 5.76124C14.715 7.43996 14.1337 9.36693 14.1337 11.4426V11.505C14.1337 17.8251 19.1403 22.8864 25.9007 22.8864C32.6611 22.8864 37.7298 17.7616 37.7298 11.4426V11.3803C37.7298 10.582 37.6481 9.80766 37.4935 9.05634C37.4892 8.98963 37.5883 8.96119 37.6122 9.03228C37.966 10.139 38.0749 11.2491 38.0749 12.4663C38.0749 12.4925 38.0727 12.5188 38.0727 12.5439C39.4465 13.2099 41.0904 13.6671 42.9476 14.0827C45.9881 14.7749 46.724 15.2791 46.724 16.189V16.2524C46.724 17.1962 45.8281 17.7616 44.1625 17.7616C41.8372 17.7616 39.6153 17.0212 37.5861 15.6225C37.082 17.3832 36.1665 18.9799 34.9494 20.3163C37.499 21.9798 40.6527 22.8229 43.9382 22.8229C49.3814 22.8229 52.9978 20.1501 52.9978 15.6553V15.593C52.9978 11.4744 49.7972 9.83938 44.6437 8.73919M12.4213 8.51828C12.4213 10.2473 11.1073 11.3803 8.94855 11.3803H6.07021V5.56438H8.91807C11.1073 5.56438 12.4213 6.56943 12.4213 8.45703V8.51828ZM33.2424 12.7091L25.8528 21.0152C23.4894 20.9966 21.3241 20.1742 19.6335 18.8126L25.0646 12.7091C25.3412 12.3613 25.5066 11.9216 25.5066 11.4405C25.5066 10.9593 25.3412 10.5196 25.0646 10.1707L19.6367 4.07049C21.3274 2.71001 23.4927 1.8876 25.8572 1.87011L33.2424 10.1707C33.5189 10.5196 33.6855 10.9593 33.6855 11.4405C33.6855 11.9216 33.5189 12.3613 33.2424 12.7091Z"
            fill="#ED1C24"
          />
        </g>
        <defs>
          <clipPath id="clip0_1088_4954">
            <rect width="53" height="31" fill="white" />
          </clipPath>
        </defs>
      </svg>
  )
};

export default Logo;

