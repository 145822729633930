import React, { useEffect, useReducer, useState } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from 'react-router';
import Stepper from "../Components/Elements/Stepper";
import Plans from "../Features/Quotation/Plans";
import AddOns from "../Features/Quotation/AddOns";
import CustomerDetails from "../Features/Quotation/CustomerDetails";
import Checkout from "../Features/Quotation/Checkout";
import { ScrollToTop } from '../Services/ScrollToTop';
import "../assets/styles/Quotation.scss";
import allianz from "../assets/images/insurer/aliianz.svg";
import etiqa from "../assets/images/insurer/etiqa.svg";
// import kurnia from "../assets/images/insurer/kurnia.svg";
import rhb from "../assets/images/insurer/rhb.svg";
import tokio from "../assets/images/insurer/tokio.svg";
import zurich from "../assets/images/insurer/zurich.svg";
import zurichTakaful from "../assets/images/insurer/Zurich-Takaful-logo-1.svg"
import { useTranslation } from "react-i18next";

function reducerSteps(states, actions) {

  if (actions.type === 'prevStep') {
    const index = states.findIndex(state => state.label === actions.payload.label) - 1;
    states[index] = {
      value: states[index].value,
      label: states[index].label,
      isActive: true,
      isRenderComponent: true,
    };
    /* update previos step */
    states[index + 1] = {
      value: states[index + 1].value,
      label: states[index + 1].label,
      isActive: false,
      isRenderComponent: false,
    };
    return [...states];
  }
  if (actions.type === 'nextStep') {
    const index = states.findIndex(state => state.label === actions.payload.label) + 1;
    states[index] = {
      value: states[index].value,
      label: states[index].label,
      isActive: true,
      isRenderComponent: true,
    };
    states.map((state, stateIndex) => {
      if (stateIndex !== index) state.isRenderComponent = false;
      return state;
    });
    return [...states];
  }

  throw Error('Unknown action: ' + actions.type);
}

export default function Quotation() {
  const { t } = useTranslation();
  const [queryParams] = useSearchParams();
  const checkout = queryParams.get('step');
  const { regNo } = useParams();
  const navigate = useNavigate();
  const initialSteps = [
    { value: 1, label: 'Plans', isActive: checkout === "4" ? false : true, isRenderComponent: checkout === "4" ? false : true},
    { value: 2, label: 'Add-Ons', isActive: false, isRenderComponent: false },
    { value: 3, label: 'Customer Details', isActive: false, isRenderComponent: false },
    { value: 4, label: 'Checkout', isActive: checkout === "4" ? true : false, isRenderComponent: checkout === "4" ? true : false },
  ];
  const [stateSteps, dispatchSteps] = useReducer(reducerSteps, initialSteps);
  const [activeStep, setActiveStep] = useState(initialSteps[checkout === "4" ? 3 : 0]);
  const [, setPlan] = useState('');
  const [reqBody, setReqBody] = useState({})
  const [isPlansReady, setIsPlansReady] = useState(false)
  const handlePrevButton = () => {
    dispatchSteps({ type: 'prevStep', payload: activeStep });
    localStorage.setItem("plansLoadingFirstTime", false);
    setTimeout(() => {
      const currentActiveStep = stateSteps.find(step => step.isRenderComponent);
      setActiveStep(currentActiveStep);
    }, 100);
  }

  const handleNextButton = () => {
    localStorage.setItem("plansLoadingFirstTime", false);
    dispatchSteps({ type: 'nextStep', payload: activeStep });
    setTimeout(() => {
      const currentActiveStep = stateSteps.find(step => step.isRenderComponent);
      setActiveStep(currentActiveStep);
    }, 100);
  }

  const prevPage = () => {
    navigate(`/user-verification/${regNo}`);
  }

  const selectedPlan = (plan) => {
    setPlan(plan.compCode);
    setReqBody({ ...reqBody, "compCode": plan.compCode, "actualSumInsured": plan.sumInsured, "actualMarketValue": plan.marketValue, "requestId": plan.requestId, "keepFlag": "Y" })
    localStorage.setItem("actMarket",plan.marketValue);
    localStorage.setItem("actSum",plan.sumInsured);
    localStorage.setItem("selectedCode",plan.compCode);
    localStorage.setItem("requestID", plan.requestId);
    handleNextButton();
  }
  const logoArray = {
    "13": { "name": "ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD", "logo": allianz },
    "18": { "name": "ZURICH GENERAL INSURANCE MALAYSIA BERHAD", "logo": zurich },
    "97": { "name": "ZURICH GENERAL TAKAFUL MALAYSIA BERHAD", "logo": zurichTakaful },
    "96": { "name": "ETIQA GENERAL TAKAFUL BERHAD", "logo": etiqa },
    "91": { "name": "TOKIO MARINE INSURANS (MALAYSIA) BERHAD", "logo": tokio },
    "21": { "name": "RHB INSURANCE BERHAD", "logo": rhb },
  }
  useEffect(() => ScrollToTop())
  
  useEffect(() => {
    if(localStorage.userInitilData === undefined) {window.location.replace('/');}
    const localData = JSON.parse(localStorage.userInitilData);
    const localDataVixncd = JSON.parse(localStorage.UserVerification);
    let dateTempArray = localDataVixncd.expiryDate.split('/');
    let expiryDateFormatted = `${dateTempArray[2]}-${dateTempArray[1]}-${dateTempArray[0]}`;
    const givenDate = new Date(expiryDateFormatted);
    const currentDate = new Date();
    var effectiveDate = expiryDateFormatted;
    var effectiveTime = '00:00:00';
    if (givenDate.getTime() > currentDate.getTime()) {
      // Add a day
      let d = new Date(expiryDateFormatted);
      d.setUTCDate(d.getUTCDate() + 1);
      effectiveDate = d.toISOString().substr(0, 10);
      let c = effectiveDate.split("-");
      let cyear = parseInt(c[0]) + 1;
      let cday = parseInt(c[2]);
      c[0] = cyear.toString();
      c[2] = cday
      var newStartDate = `${c[0]}-${c[1]}-${(c[2] < 10 ? "0" : "")+c[2]}`;
      expiryDateFormatted = newStartDate;
    } else {
      effectiveDate = currentDate.toISOString().split('T')[0];
      effectiveTime = new Date().toLocaleTimeString()
      let c = effectiveDate.split("-");
      let cyear = parseInt(c[0]) + 1;
      c[0] = cyear.toString();
      newStartDate = `${c[0]}-${c[1]}-${(c[2] < 10 ? "0" : "")+c[2]}`
      // var cyear = c.getFullYear();
      // var cmonth = c.getMonth();
      // var cday = c.getDate();
      // var newExpDate = new Date(cyear + 1, cmonth, cday)
      expiryDateFormatted = newStartDate
    }
    var gender = localData.newIc !== '' ? parseInt(localData.newIc.at(-1)) / 2 === 0 ? 'F' : 'M' : localData.gender.at(0);
    var makeCodeMinor = localDataVixncd?.arrResExtraParam.item[localDataVixncd.arrResExtraParam.item.findIndex(item => item.indicator === "DISTRIBUTOR_MODELCODE")];
    makeCodeMinor = localStorage.getItem('userSelectedMinorCode') ? localStorage.getItem('userSelectedMinorCode') : makeCodeMinor.value.split('|')[0];
    const requestBody = {
      "compCode": "POS",
      "agentCode":"POS",
      "newIc": localData.newIc,
      "passportNo": localData.passportNo,
      "coverCode": localStorage.getItem("oldCar") === "true" ? "03" : "01", 
      "channel": "POS",
      "gender": gender,
      "birthDate": localData.birthDate ? localData.birthDate : localStorage.getItem("dateOfBirth"),
      "address1": "DEFAULT",
      "postCode": localData.postcode,
      "maritalStatus": localData['marital-status'] === "Married" ? "S" : "M",
      "makeYear": localDataVixncd.makeYear,
      "regNo": localData.regNo,
      "chassisNo": localDataVixncd.chassisNo,
      "sumInsured": localDataVixncd.variation && localStorage.getMinorCode !== "" ? localDataVixncd.variation.find(data => data.minor === localStorage.getMinorCode)?.sumInsured : localDataVixncd.variation[0]?.sumInsured,
      "engineNo": localDataVixncd.engineNo,
      "makeCodeMajor": localDataVixncd.ismMakeCode,
      "makeCodeMinor": makeCodeMinor,
      "vehCapacity": parseInt(localDataVixncd.vehCapaCity),
      "requestId": localStorage.getItem("requestId")? localStorage.getItem("requestId") : "",
      "keepFlag":"Y",
      "vehCapacityCode": "CC",
      "seatCapacity": 5,
      "vehTypeCode": localDataVixncd.vehTypeCode,
      "effectiveDate": effectiveDate,
      "effectiveTime": effectiveTime,
      "expiryDate": expiryDateFormatted,
      "piamDrv": JSON.parse(localStorage.isCar) ? "03" : "05",
      "ncdPerc": localDataVixncd.ncdPerc,
      "ownershipType": "I",
      "useCode": localDataVixncd.veHuseCode,
      "addBenData":{
        "item": []
      },
      "addDrvData": {
        "item": []
      }
    }
    setReqBody(requestBody)
    setIsPlansReady(true)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="quotation-wrapper">
      {
        String(stateSteps[0].isRenderComponent) === "true"
          ? (<div className='mt-4 subBlueTitle cursor-pointer ' onClick={prevPage}>{t("bck_button")}</div>)
          : (<div className='mt-4 subBlueTitle cursor-pointer ' onClick={handlePrevButton}>{t("bck_button")}</div>)
      }

      <div className='quotation-wrapper-stepper'>
        <Stepper steps={stateSteps} />
      </div>
      <div className="quotation-wrapper-render mb-4">
        {
          stateSteps[0].value === 1 && stateSteps[0].isRenderComponent
            ? (isPlansReady && <Plans handleSinglePlan={selectedPlan} requestBody={reqBody} />)
            : (stateSteps[1].value === 2 && stateSteps[1].isRenderComponent)
              ? (<AddOns handleNextStep={handleNextButton} requestBody={reqBody} logoArray={logoArray} />)
              : (stateSteps[2].value === 3 && stateSteps[2].isRenderComponent)
                ? (<CustomerDetails handleNextStep={handleNextButton} logoArray={logoArray} />)
                : (<Checkout stateSteps={stateSteps}  logoArray={logoArray} />)
        }
      </div>

      {/* {
        String(stateSteps[0].isRenderComponent) === "true"
          ? (<div className='mt-4 subBlueTitle cursor-pointer back-bottom-action' onClick={prevPage}>Back</div>)
          : (<div className='mt-4 subBlueTitle cursor-pointer back-bottom-action' onClick={handlePrevButton}>Back</div>)
      } */}

      {/* {stateSteps[0].isRenderComponent &&
        <div className='contact'>
          <div className='label'>Need help?</div>
          <button className='action'><span className="subBlueTitle hover">FAQ</span></button>
          <button className='action'><span className="subBlueTitle hover">Contact Us</span></button>
        </div>
      } */}
    </div>
  )
}
