import { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import { useForm, FormProvider } from "react-hook-form";
import info from "../../assets/images/info.svg";
import InputText from "../../Components/fields/inputText";
import PaymentBox from "../../Components/Elements/PaymentBox";
import "../../assets/styles/CustomerDetails.scss";
// import axios from "axios";
import { Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import StatusModal from "../../Components/StatusModal";
import Stepper from "../../Components/Elements/Stepper";
import { useNavigate } from "react-router-dom";
import allianz from "../../assets/images/insurer/aliianz.svg";
import etiqa from "../../assets/images/insurer/etiqa.svg";
import rhb from "../../assets/images/insurer/rhb.svg";
import tokio from "../../assets/images/insurer/tokio.svg";
import zurich from "../../assets/images/insurer/zurich.svg";
import zurichTakaful from "../../assets/images/insurer/Zurich-Takaful-logo-1.svg";
import tune from "../../assets/images/tune_logo.svg";

function CustomerDetails(props) {
  if(localStorage.userInitilData === undefined) {window.location.replace('/');}
  let navigate = useNavigate();
  const useFormMethod = useForm({ mode: "all" });
  const [formError, setFormError] = useState(null);
  const [maritalStatus, setMaritalStatus] = useState("M");
  const localData = JSON.parse(localStorage.userInitilData);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [status, setStatus] = useState(false);
  const { t } = useTranslation();
  const [errorMessage, ] = useState(t('all_error_req'));
  const premiumDetails = JSON.parse(localStorage.getItem("PaymentboxDetails"))
  // const api = localStorage.getItem("UserVerification");
  // const parsedApi = JSON.parse(api);
  // const ownerName = parsedApi.engineNo;
  const payment = {
    title: "Premium Details",
    plan: "Allianz Longname Comprehensive",
    coverage: "30/12/2022 - 30/12/2023",
    sum_insured: "RM 10,000",
    total: "RM834.000",
    total_highlight: "Price breakdown",
    buttonLabel: `${t("next")}`,
    sections: [
      {
        details: [
          {
            label: "Basic Premium/Contribution",
            value: "RM 1,718.69",
          },
          {
            label: "NCD (55%)",
            value: "-RM 845.28",
          },
          {
            label: "Personal Accident Cover",
            value: "RM 10.00",
          },
        ],
      },
      {
        title: "Add-Ons:",
        details: [
          {
            label: "Windscreen",
            value: "RM 25.00",
          },
          {
            label: "Additional Driver",
            value: "RM 10.00",
          },
          {
            label: "Legal Liability Passenger",
            value: "RM 15.00",
          },
          {
            label: "For Negligence Acts Special Peril",
            value: "RM 20.00",
          },
        ],
      },
      {
        details: [
          {
            label: "Gross Premium Contribution",
            value: "RM 967.99",
          },
          {
            label: "Rebate Amount",
            value: "-RM 18.10",
          },
          {
            label: "SST (8%)",
            value: "RM 23.22",
          },
          {
            label: "Stamp Duty",
            value: "RM 10.00",
          },
        ],
      },
      {
        details: [
          {
            label: "Total",
            value: "RM 1,234.00",
            isLabelBold: true,
            isValueBold: true,
          },
        ],
      },
    ],
  };
  const vehData = localStorage.getItem("UserVerification");
  const parsedVehData = JSON.parse(vehData);
  const onSubmit = (data) => {
    let payload = data;
    localStorage.setItem("customerDetails", JSON.stringify(payload));
    navigate('/quotation/checkout')
    handleBeginCheckout()
  };
  const onError = (error) => setFormError(error);

  const customerDetails = localStorage?.getItem("customerDetails") ? JSON.parse(localStorage?.getItem("customerDetails")) : "";

  // const [finishStatus, setfinishStatus] = useState(false);

//   const onBackButtonEvent = (e) => {
//     e.preventDefault();
//     setStatus(true)
//     setErrorMessage(t("error8"))
//     // if (!finishStatus) {
//     //     if (window.confirm("Do you want to go back ? You will lose all you data and start from the beginning")) {
//     //         setfinishStatus(true)
//     //         // your logic
//     //         window.history.back();
//     //         // props.history.push("/");
//     //     } else {
//     //         window.history.pushState(null, null, window.location.pathname);
//     //         setfinishStatus(false)
//     //     }
//     // }
    
// }
// useEffect(() => {
//   window.history.pushState(null, null, window.location.pathname);
//   window.addEventListener('popstate', onBackButtonEvent);
//   return () => {
//     window.removeEventListener('popstate', onBackButtonEvent);  
//   };
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);

  useEffect(() => {
    const marital = `${localData.maritalStatus === "SINGLE" ? "S" : "M"}`;
    setMaritalStatus(marital);
    setCity(localStorage.city);
    setState(localStorage.state);
    // const fetchCities = () => {
      // const token = process.env.REACT_APP_MDM_TOKEN;
      // const config = {
      //   headers: { Authorization: `Bearer ${token}` }
      // };

      // axios
      //   .get(`https://spp-svc.uat-pos.com/api/options/cities/v1/querybypostcode?country=MY&postcode=${localData.postcode}`, config)
      //   .then(function (response) {
      //     setState(response.data?.data[0]?.state_name);
      //     setCity(response.data?.data[0]?.city_name);
      //     localStorage.setItem("state", state);
      //     localStorage.setItem("city", city);
      //   })
      //   .catch(function (error) {
      //   });
    // }

    // fetchCities();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function capitalizeFirstLetter(str) {
  //   if (!str) return '';
  // return str
  //   .toLowerCase() // Convert all characters to lowercase first
  //   .split(' ')    // Split the string by spaces
  //   .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word's first letter
  //   .join(' ');    // Join the words back together with spaces
  // }

  localStorage.setItem("city",localStorage.city)

  const initialSteps = [
    { value: 1, label: "Plans", isActive: false, isRenderComponent: false },
    { value: 2, label: "Add-Ons", isActive: false, isRenderComponent: false },
    {
      value: 3,
      label: "Customer Details",
      isActive: true,
      isRenderComponent: true,
    },
    { value: 4, label: "Checkout", isActive: false, isRenderComponent: false },
  ];

const logoArray = {
      "13": { "name": "ALLIANZ GENERAL INSURANCE COMPANY (MALAYSIA) BERHAD", "logo": allianz },
      "18": { "name": "ZURICH GENERAL INSURANCE MALAYSIA BERHAD", "logo": zurich },
      "97": { "name": "ZURICH GENERAL TAKAFUL MALAYSIA BERHAD", "logo": zurichTakaful },
      "96": { "name": "ETIQA GENERAL TAKAFUL BERHAD", "logo": etiqa },
      "91": { "name": "TOKIO MARINE INSURANS (MALAYSIA) BERHAD", "logo": tokio },
      "21": { "name": "RHB INSURANCE BERHAD", "logo": rhb },
      "40": { "name": "TUNE PROTECT MALAYSIA", "logo": tune },
    }
    const [policyTypes] = useState({
      "01": `${t("comprehensive")}`,
      "02": `${t("third_party")}`,
      "03": `${t("third_party_ft")}`,
    });
  const handleBeginCheckout=()=>{
    const itemName = logoArray[localStorage.selectedCode].name.split(" ")[0] +" "+logoArray[localStorage.selectedCode].name.split(" ")[1] +" "+policyTypes[localStorage.coverCode];

    // On Click of Next
    window.dataLayer.push({
      event: "begin_checkout",
      event_category: "User Details",
      event_action: "Begin Checkout",
      event_label: itemName,
      currency: "MYR",
      value: premiumDetails.grossDue,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
      vehicle_make:localStorage.vehicleMake,
      vehicle_variant: localStorage.getVehicleName,
      items: [{
        item_id: premiumDetails.compCode,
        item_name: itemName,
        item_brand: logoArray[localStorage.selectedCode].name.split(" ")[0],
        item_category: "Vehicle Insurance",
        item_category_2: localStorage.isCar=== "true" ? "Car" : "Motorcycle",
        item_category_3: policyTypes[localStorage.coverCode],
        item_variant: "Market Value",
        currency: "MYR",
        price: premiumDetails.grossDue,
        quantity: "1",}]
    });
}

  return (
    <>
    <div
        className="mt-4 subBlueTitle cursor-pointer "
        onClick={() => navigate(-1)}
      >
        {t("bck_button")}
      </div>

      <div className="quotation-wrapper-stepper">
        <Stepper steps={initialSteps} activeStep={2}/>
      </div>
      <div className="customer-details">
        <div className="options">
          <div className="mb-4">
            <div>{localStorage.getVehicleName} {parsedVehData.makeYear}  - {parsedVehData.vehRegNo}</div>
            <div>
              {t("sum_insured")}: <span>{`${premiumDetails.sumInsured.toLocaleString()} (NCD ${premiumDetails.ncdPerc}%)`}</span>{" "}
            </div>
          </div>
          <div className="fields">
            <FormProvider {...useFormMethod}>
              {" "}
              {/*pass all methods into the context */}
              <Form
                onSubmit={useFormMethod.handleSubmit(onSubmit, onError)}
                id="details-form"
              >
                <div className="mb-3">
                  {/* Owner Full Name field */}
                  <InputText
                    fieldControlId="validationCustom01"
                    detectEmpty={()=>null}
                    floatingControlId="floatingInput"
                    floatingLabel={t("full_name")+ " *"}
                    // fieldValue={ownerName}
                    fieldType="text"
                    fieldPlaceholder="Owner's Full Name *"
                    defaultValue = {customerDetails?.fullName}
                    formError={formError}
                    register={{
                      control: "fullName",
                      rules: {
                        required: `${t("name_required")}`,
                        uppercase: true
                      },
                    }}
                  ></InputText>
                </div>
                <div className="mb-3 d-flex flex-wrap">
                  {/* Email field */}
                  <div className="col-12 col-md-6 spacing-right noBotMargin">
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={()=>null}
                      floatingControlId="floatingInput"
                      floatingLabel={t("email")+" *"}
                      fieldType="email"
                      fieldPlaceholder={t("email")+" *"}
                      defaultValue={customerDetails?.email}
                      formError={formError}
                      register={{
                        control: "email",
                        rules: {
                          required: `${t("email_required")}`,
                          pattern: {
                            value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
                            message: `${t("email_message")}`,
                          },
                        },
                      }}
                    ></InputText>
                  </div>
                  <div className="info mb-2">
            <span className="image">
              <img src={info} alt="info" />
            </span>
            <div className="description">{t("mandatory_desc")}
            </div>
          </div>
                  {/* </Row> */}
                  {/* <Row className="mb-3"> */}
                  {/* Mobile Number field */}
                  <div className="col-12 col-md-6 spacing-left mobile">
                    <InputText
                      fieldControlId="validationCustom02"
                      detectEmpty={()=>null}
                      floatingControlId="floatingInput"
                      floatingLabel={t("mobile")+" *"}
                      fieldType="number"
                      fieldValue={0 + localData.mobileNumber}
                      fieldPlaceholder="Mobile Number *"
                      validate={true}
                      read={true}
                      formError={formError}
                      // disabled
                      register={{
                        control: "mobile",
                        rules: {
                          // required: "Mobile number is required.",
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        },
                      }}
                    ></InputText>
                  </div>
                </div>
                <div className="small">{t("marital_status")} *</div>
                <div className="wrapper-marital">
                  <Row>
                    <div className="d-flex">
                      <div className={`marital-status ${maritalStatus === "S" ? "" : "hideNone"}`}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDisabled"
                          id="flexRadioDefault1"
                          disabled
                          checked={maritalStatus === "S" ? true : false}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          <p>{t("v_f_marital_s")}</p>
                        </label>
                      </div>
                      <div className={`marital-status ${maritalStatus === "M" ? "" : "hideNone" }`}>
                        <input
                          className="form-check-input"
                          type="radio"
                          name="flexRadioDisabled"
                          id="flexRadioDefault1"
                          disabled
                          checked={maritalStatus === "M" ? true : false}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          <p>{t("v_f_marital_m")}</p>
                        </label>
                      </div>
                    </div>
                  </Row>
                </div>
                <div className="mb-2">{t("current_address")}</div>
                <div className="d-flex flex-wrap">
                  {/* Address field */}
                  <div className="col-12">
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={()=>null}
                      floatingControlId="floatingInput"
                      floatingLabel={t("address")+" *"}
                      fieldType="text"
                      fieldPlaceholder="Address *"
                      defaultValue={customerDetails.address}
                      formError={formError}
                      register={{
                        control: "address",
                        rules: {
                          required: `${t("address_required")}`,
                          uppercase: true,
                          maxLength: {
                            value: 200,
                            message: "Address max 200 characters",
                          },
                        },
                      }}
                    ></InputText>
                  </div>
                  {/* </Row> */}
                  {/* <Row className="mb-3"> */}
                  {/* Postcode field */}
                  <div className="col-12 col-md-6 spacing-right postcode">
                    <InputText
                      fieldControlId="validationCustom02"
                      detectEmpty={()=>null}
                      floatingControlId="floatingInput"
                      floatingLabel={t("postcode")+" *"}
                      fieldType="number"
                      fieldPlaceholder="Postcode *"
                      validate={true}
                      read={true}
                      fieldValue={localData.postcode}
                      formError={formError}
                      register={{
                        control: "postcode",
                        rules: {
                          // required: "Postcode is required.",
                          maxLength: {
                            value: 5,
                            message: "Postcode max 5 characters",
                          },
                          minLength: {
                            value: 5,
                            message: "Postcode min 5 characters",
                          },
                          pattern: {
                            value: /^[0-9]+$/,
                            message: "Please enter a number",
                          },
                        },
                      }}
                    ></InputText>
                  </div>
                  {/* </Row> */}
                  {/* <Row className="mb-3"> */}
                  {/* State field */}
                  <div className="col-12 col-md-6 spacing-left state">
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={()=>null}
                      floatingControlId="floatingInput"
                      floatingLabel={t("state")+" *"}
                      fieldType="text"
                      fieldPlaceholder="State *"
                      validate={true}
                      read={true}
                      fieldValue={state.toUpperCase()}
                      formError={formError}
                      register={{
                        control: "state",
                        rules: {
                          // required: "State is required.",
                        },
                      }}
                    ></InputText>
                  </div>
                  {/* </Row> */}
                  {/* <Row className="mb-3 col-6"> */}
                  {/* City field */}
                  <div className="col-12 col-md-6 spacing-right city">
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={()=>null}
                      floatingControlId="floatingInput"
                      floatingLabel={t("City")+" *"}
                      fieldType="text"
                      fieldPlaceholder="City *"
                      validate={true}
                      read={true}
                      fieldValue={city}
                      formError={formError}
                      register={{
                        control: "city",
                        rules: {
                          // required: "City is required.",
                        },
                      }}
                    ></InputText>
                  </div>
                </div>
                <div className="col-12 col-md-6 spacing-right city">
                  {/* <div className="mb-2">Promo Code</div> */}
                    <InputText
                      fieldControlId="validationCustom01"
                      detectEmpty={()=>null}
                      floatingControlId="floatingInput"
                      floatingLabel={t("Referral Code (Optional)")+" *"}
                      fieldType="text"
                      fieldPlaceholder="Referral Code *"
                      formError={formError}
                      defaultValue={customerDetails.referralCode}
                      register={{
                        control: "referralCode",
                        rules: {
                          maxLength: {
                            value: 12,
                            message: "Promo code max 12 characters",
                          },
                        },
                      }}
                    ></InputText>
                  </div>
              </Form>
            </FormProvider>
          </div>
         
        </div>
        {/* Payment Box Section */}
        <StatusModal status={status} message={errorMessage} handleClose={() => setStatus(false)}
        type={'error'}/>
        <PaymentBox
          handlePrevious={() => null}
          type={"submit"}
          form={"details-form"}
          premiumdetails={JSON.parse(localStorage.getItem("PaymentboxDetails"))}
          logoarray={props.logoArray}
          payment={payment}
          isAmountReady={true}
        />
      </div>
    </>
  );
}

export default CustomerDetails;
