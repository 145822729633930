import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { InputGroup } from "react-bootstrap";
import iconChevronUp from "../../assets/images/chevron-up.svg";
import iconChevronDown from "../../assets/images/chevron-down.svg";
import iconError from "../../assets/images/field-icon-error.svg";
import iconSuccess from "../../assets/images/field-icon-success.svg";
import "../../assets/styles/inputStyles.scss";
import "../../assets/styles/inputDropdownText.scss";

function InputDropdownText(state) {
  let [fieldDropdownIcon, setFieldDropdownIcon] = useState(iconChevronDown);
  let [fieldBgColor, setFieldBgColor] = useState("");
  let [fieldIconStatus, setFieldIconStatus] = useState(null);

  const {
    register,
    resetField,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
  } = useFormContext({ defaultValues: { [state.register.control]: "" } }); // retrieve all hook methods

  const handleOnChangeDropdown = (e) => {
    const value = e.target.value;
    resetField(state.register.control);
    setFieldBgColor("");
    setFieldIconStatus(null);
    setFieldDropdownIcon(iconChevronDown);
    state.handleCallBack({ type_id: value, driver_id: state.fieldControlId });
  };

  const handleOnClickDropdown = () => {
    setFieldDropdownIcon(iconChevronUp);
  };

  useEffect(() => {
    /* handle field background style */
    if (state.formError || dirtyFields[state.register.control]) {
      if (
        !errors[state.register.control] &&
        Boolean(getValues(state.register.control))
      ) {
        setFieldBgColor("success");
        setFieldIconStatus(iconSuccess);
      } else if (
        Boolean(
          Object.keys(errors).length > 0 && errors[state.register.control]
        )
      ) {
        setFieldBgColor("error");
        setFieldIconStatus(iconError);
      } else {
        setFieldBgColor("");
        setFieldIconStatus(null);
      }
    }
  }, [state, dirtyFields, errors, getValues]);

  return (
    <Form.Group
      as={Col}
      lg={state.fieldLg}
      md={state.fieldMg}
      controlId={state.fieldControlId}
      className="form-element-container"
    >
      <InputGroup>
        {fieldIconStatus && (
          <img
            className="icon dropdown-icon"
            src={`${fieldIconStatus}`}
            alt="icon-error"
          />
        )}
        <FloatingLabel
          controlId={state.floatingControlId}
          label={state.floatingLabel}
          className={`input-text-select ${state.className}`}
        >
          <Form.Control
            as="select"
            onChange={handleOnChangeDropdown}
            disabled={state.optionsDisable}
            defaultValue={state.defaultOption}
            onClick={handleOnClickDropdown}
            className={`${fieldBgColor}`}
          >
            <option value="1">{state.firstOption}</option>
            <option value="2">Passport</option>
          </Form.Control>
          <img
            className="icon dropdown-icon"
            src={`${fieldDropdownIcon}`}
            alt="icon-dropdown"
          />
        </FloatingLabel>
        <FloatingLabel
          controlId={state.floatingControlId}
          label={state.fieldPlaceholder}
          className="input-text-select insideLabel"
        >
          <Form.Control
            type={state.fieldType}
            placeholder={state.fieldPlaceholder}
            disabled={state.disabled}
            defaultValue={state.defaultValue}
            className={`${fieldBgColor} passport-nric-input`}
            maxLength={state.maxLength}
            onChange={(e) => {
              let value = e.target.value;
              if (state.register?.rules?.no0) {
                if(value.charAt(0) === "0")
                value = value.charAt(0).replace("0", "");
              };
              setValue(state.register.control, value);

            }}
            onSelect={(e) => {
              var value = e.target.value;
              if (state.register?.rules?.no0) {
                if(value.charAt(0) === "0")
                value = value.charAt(0).replace("0", "");
              };
              // if (state.register.rules.format) {
              //   if (value.length < 6) value = value.replace(/(\d{6})/);

              //   if (value.length < 9) {
              //     value = value.replace(/(\d{6})(\d{2})(\d{1})/, "$1-$2-$3");
              //   }
              //   if (value.length < 13) {
              //     value = value.replace(/(\d{6})(\d{2})(\d{4})/, "$1-$2-$3");
              //   }
              //   value = value.replace(/(\d{6})(\d{2})(\d{4})/, "$1-$2-$3");
              // }
              setValue(state.register.control, value);
            }}
            // onKeyUpCapture={(e) => {
            //   if (!state.isPassport) {
            //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
            //   }
            // }}
            {...register(
              state.register.control,
              state.register.rules,
              state.register.maxLength
            )}
          />
        </FloatingLabel>
        {fieldIconStatus && (
          <img className="icon" src={`${fieldIconStatus}`} alt="icon-error" />
        )}
      </InputGroup>
      <ErrorMessage
        errors={errors}
        name={state.register.control}
        render={({ messages }) => {
          return (
            <span className="error-label">
              {errors[state.register.control].message}
            </span>
          );
        }}
      />
    </Form.Group>
  );
}

export default InputDropdownText;
