import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import i18next from "i18next";
import cookies from "js-cookie";
import Logo from './Logo';
import "../../assets/styles/header.scss";

const languages = [
  {
    code: "en",
    name: "english",
    country_code: "gb",
  },
  {
    code: "my",
    name: "bahasa",
    country_code: "my",
  },
];

const Language = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentLanguageCode = cookies.get('i18next') || 'en';
  const currentLanguage = languages.find(l => l.code === currentLanguageCode);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get('lang');
  const lang = id !== null ? id : cookies.get('i18next');

  const pushLanguageSelectionEvent = (selectedLanguage) => {
    window.dataLayer.push({
      event: "select_language",
      event_category: "Menu",
      event_action: "Select Language",
      event_label: selectedLanguage,
    });
  };

  var storedData = localStorage.getItem('gclid');
  var parsedData = JSON.parse(storedData);
  const [data, setData] = useState(parsedData)
  if(parsedData === null) {localStorage.setItem('gclid', data)}

  useEffect(() => {
    document.title = t('app_title');
    document.body.dir = currentLanguage || 'ltr';
    i18next.changeLanguage(id);
    setSearchParams(data, { replace: true });
    localStorage.setItem('gclid', JSON.stringify(data))
    i18next.changeLanguage(lang);
    // eslint-disable-next-line
  }, [currentLanguage, searchParams]);

  return (
    <>
      <div className="d-flex flex-row-reverse collapse navbar-collapse" id="navbarNavDropdown">
        <ul className="navbar-nav d-flex flex-row">
          {languages.map(({ code, name, country_code }, index) => (
            <li key={country_code}>
              <button
                disabled={code === currentLanguageCode}
                className={`btn btn-link ${code === currentLanguageCode ? "text-muted" : "text-danger"} noLinkLine`}
                aria-current="page"
                onClick={() => {
                  i18next.changeLanguage(code);
                  setData({...data, lang:code})
                  localStorage.setItem('gclid', JSON.stringify(data))
                  pushLanguageSelectionEvent(name);
                }}
              >
                {/* FLAG <span className={`flag-icon flag-icon-${country_code} m-1`}></span> */}
                {name}
              </button>
              <span>{index === 0 ? '/' : ''}</span>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
};




const HeaderMobile = ({ topNav, showMobileMenu, toggleMobileMenu }) => {
  const [activeSubMenuLevel1, setActiveSubMenuLevel1] = useState(null);
  const [activeSubMenuLevel2, setActiveSubMenuLevel2] = useState(null);
  const [activeSubMenuLevel3, setActiveSubMenuLevel3] = useState(null);

  const navigate = useNavigate();

  const openSubMenu = (index) => {
    setActiveSubMenuLevel1(index);
    setActiveSubMenuLevel2(null);
    setActiveSubMenuLevel3(null);
  };

  const closeSubMenu = () => {
    setActiveSubMenuLevel1(null);
    setActiveSubMenuLevel2(null);
    setActiveSubMenuLevel3(null);
  };

  const openSubSubMenu = (index) => {
    setActiveSubMenuLevel2(index);
    setActiveSubMenuLevel3(null);
  };

  const openSubSubSubMenu = (index) => {
    setActiveSubMenuLevel3(index);
  };

  return (
    <div>
      <div
        id="mySidenav"
        className={`megamenu-t mm-listview sidenav slideAnim ${showMobileMenu ? 'open' : ''}`}
      >
        <div>
          <div className="mlogo">
            <Logo width={53} height={31} onClick={() => navigate("/")} />
          </div>
          <div className="mm-navbar">
          <button
            className="closebtn m-top-1 close-mob-menu icon-remove"
            onClick={toggleMobileMenu}
            aria-label="Close mobile menu">
            Close
          </button></div>
        </div>
        {topNav.map((navItem, index) => (

          <div key={index} className="level-top mm-listitem__text">

            <a
              className="mm-listitem"
              href={navItem.url}
              target={navItem.target}
              rel={navItem.rel}
              onClick={() => {
                if (navItem.submenu) {
                  openSubMenu(index);
                } else {
                  navigate("/"); // This will navigate to the URL if there's no submenu
                }
              }}
            >
              <span>{navItem.title}</span>
              {navItem.submenu && (
                <button
                  className="mm-btn angle-right mm-listitem__btn"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent the click event from propagating to the parent anchor
                    openSubMenu(index);
                  }}
                ></button>
              )}
            </a>

            {activeSubMenuLevel1 === index && navItem.submenu && (
              <div className="megamenu-t mm-listview sidenav">
                <button className=" mm-btn backbtn mm-listitem__btn" onClick={closeSubMenu}>
                <span className="visually-hidden">close</span></button>

                {navItem.submenu.map((subNavItem, subIndex) => (
                  <div className="level-top mm-listitem__text" key={subIndex}>
                    <a
                      className="mm-listitem"
                      href={subNavItem.url}
                      target={subNavItem.target}
                      rel={subNavItem.rel}
                      onClick={() => {
                        if (subNavItem.submenu) {
                          openSubSubMenu(subIndex);
                        } else {
                          navigate("/");
                        }
                      }}
                    >
                      <span>{subNavItem.title}</span>
                      {subNavItem.submenu && (
                        <button
                          className="mm-btn angle-right mm-listitem__btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            openSubSubMenu(subIndex);
                          }}
                        ></button>
                      )}
                    </a>

                    {activeSubMenuLevel2 === subIndex && subNavItem.submenu && (
                      <div className="megamenu-t mm-listview sidenav">
                        <button className=" mm-btn backbtn mm-listitem__btn" onClick={() => setActiveSubMenuLevel2(null)}></button>
                        {subNavItem.submenu.map((subSubNavItem, subSubIndex) => (
                          <div className="level-top mm-listitem__text" key={subSubIndex}>
                            <a
                              className="mm-listitem"
                              key={subSubIndex}
                              href={subSubNavItem.url}
                              target={subSubNavItem.target}
                              rel={subSubNavItem.rel}
                              onClick={() => {
                                if (subSubNavItem.submenu) {
                                  openSubSubSubMenu(subSubIndex);
                                } else {
                                  navigate("/");
                                }
                              }}
                            >
                              <span>{subSubNavItem.title}</span>
                              {subSubNavItem.submenu && (
                                <button
                                  className="mm-btn angle-right mm-listitem__btn"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    openSubSubSubMenu(subSubIndex);
                                  }}
                                ></button>
                              )}
                            </a>

                            {activeSubMenuLevel3 === subSubIndex && subSubNavItem.submenu && (
                              <div className="megamenu-t mm-listview sidenav">
                                <button className=" mm-btn backbtn mm-listitem__btn" onClick={() => setActiveSubMenuLevel3(null)}></button>
                                {subSubNavItem.submenu.map((subSubSubNavItem, subSubSubIndex) => (
                                  <div className="level-top mm-listitem__text" key={subSubSubIndex}>
                                    <a
                                      className="mm-listitem"
                                      key={subSubSubIndex}
                                      href={subSubSubNavItem.url}
                                      target={subSubSubNavItem.target}
                                      rel={subSubNavItem.rel}
                                    >
                                      {subSubSubNavItem.title}
                                    </a>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};





function Header() {
  const [showTopNavi] = useState(true);
  const navigate = useNavigate();

  //top nav list
  const topNav = [
    {
      title: 'Send',
      submenu: [
        {
          title: 'Book A Shipment',
          url: 'https://send.pos.com.my/home',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'Tracking',
          url: 'https://tracking.pos.com.my/tracking',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'Rate Calculator',
          url: 'https://www.pos.com.my/send/ratecalculator',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'SendParcel',
          url: 'https://www.pos.com.my/send/send-parcel',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'Mail',
          submenu: [
            {
              title: 'Domestic',
              submenu: [
                {
                  title: 'Prabayar Ekonomi',
                  url: 'https://www.pos.com.my/send/mail/domestic/prabayarekonomi',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Standard Mail',
                  url: 'https://www.pos.com.my/send/mail/domestic/standard-mail',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Non-standard Mail',
                  url: 'https://www.pos.com.my/send/mail/domestic/non-standard-mail',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Registered Mail',
                  url: 'https://www.pos.com.my/send/mail/domestic/registered-mail',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Mel Rakyat',
                  url: 'https://www.pos.com.my/send/mail/domestic/mel-rakyat',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Postcards',
                  url: 'https://www.pos.com.my/send/mail/domestic/postcards',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Others',
                  url: 'https://www.pos.com.my/send/mail/domestic/others',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }
              ]
            },
            {
              title: 'International',
              submenu: [
                {
                  title: 'Air Mail',
                  url: 'https://www.pos.com.my/send/mail/international/air-mail',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Surface Mail',
                  url: 'https://www.pos.com.my/send/mail/international/surface-mail',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Small Packet',
                  url: 'https://www.pos.com.my/send/mail/international/small-packet',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Postcards',
                  url: 'https://www.pos.com.my/send/mail/international/postcards',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Add-on Labels',
                  url: 'https://www.pos.com.my/send/mail/international/add-on-labels',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }
              ]
            }
          ]
        },
        {
          title: 'Pos Laju',
          submenu: [
            {
              title: 'Domestic',
              submenu: [
                {
                  title: 'Prepaid',
                  url: 'https://www.pos.com.my/send/pos-laju/domestic/prepaid',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Express',
                  url: 'https://www.pos.com.my/send/pos-laju/domestic/express',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Economy',
                  url: 'https://www.pos.com.my/send/pos-laju/domestic/economy',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Services',
                  url: 'https://www.pos.com.my/send/pos-laju/domestic/services',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }
              ]
            },
            {
              title: 'International',
              submenu: [
                {
                  title: 'Prepaid',
                  url: 'https://www.pos.com.my/send/pos-laju/international/prepaid',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Express',
                  url: 'https://www.pos.com.my/send/pos-laju/international/express',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Economy',
                  url: 'https://www.pos.com.my/send/pos-laju/international/economy',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'Services',
                  url: 'https://www.pos.com.my/send/pos-laju/international/services',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                },
                {
                  title: 'EziPoz',
                  url: 'https://www.pos.com.my/send/pos-laju/international/ezipoz',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }
              ]
            }
          ]
        },
        {
          title: 'Fulfilment Services',
          url: 'https://www.pos.com.my/business-business-submit/pos-malaysia-efs',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'Business Enquiries',
          url: 'https://www.pos.com.my/send/business-enquiries',
          target: '_blank',
          rel: 'noopener noreferrer',
        },
        {
          title: 'Our Locations',
          url: 'https://www.pos.com.my/pos-outlet-finder',
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      ]
    },
    {
      title: 'Pay',
      url: 'https://www.posonline.com.my/PosOnline.Web.Portal/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      title: 'Buy',
      submenu:[
        {
          title: 'Insurance',
          url: 'https://insurance.pos.com.my/',
          target: '_blank',
          rel: 'noopener noreferrer',
        },{
          title: 'Shop',
          url: 'https://shop.pos.com.my/',
          target: '_blank',
          rel: 'noopener noreferrer',
        }
      ],
    },
    {
      title: 'Get In Touch',
      url: 'https://www.pos.com.my/contact-us#AskPos',
      target: '_blank',
      rel: 'noopener noreferrer',
    }
  ]

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const toggleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);

    if (!showMobileMenu) {
      document.body.classList.add('scroll-lock');
    } else {
      document.body.classList.remove('scroll-lock');
    }

  };


  return (
    <div className={`headerContainer ${!showTopNavi ? 'hide-nav' : ''}`}>
      <nav className={`topnav ${showMobileMenu ? 'hide-topnav' : ''}`}>
          <ul className="left-nav">
          <div className="logo">
              <Logo width={53} height={31} onClick={() => navigate("/")} />
            </div>
            <li>
              <div className="sections nav-sections">
              <div className="section-items nav-sections-items">
                <div className="section-item-content-t nav-sections-item-content" id="store.menu" data-role="content">
                  <nav className="navigation" data-action="navigation" id="mobileNav">
                    <ul className="megamenu-t">
                      {topNav.map((mainNav, i) => (
                        <li key={i} className={`level0 nav-${i+1} category-item ui-menu-item-t level-top ${mainNav.submenu ? 'parent' : ''} ${i === 0 ? 'first' : ''} ${i === topNav.length-1 ? 'last' : ''}`}>
                          {mainNav.submenu && <i className="arr-menu-opener"></i>}
                          <a className={`level-top ${mainNav.submenu ? 'has-submenu' : ''}`} href={mainNav.url} target={mainNav.target} rel={mainNav.rel} ><span>{mainNav.title}</span></a>
                          {mainNav.submenu && (
                            <ul className="level0 submenu-t ui-menu-t">
                              {mainNav.submenu.map((subNav, j) => (
                                <li key={j} className={`level1 nav-${i+1}-${j+1} category-item ui-menu-item-t ${j === 0 ? 'first' : ''} ${j === mainNav.submenu.length-1 ? 'last' : ''} ${subNav.submenu ? 'parent' : ''}`}>
                                  {subNav.submenu && <i className="arr-menu-opener"></i>}
                                  <a className={`submenu-title ${subNav.submenu ? 'has-submenu' : ''}`} href={subNav.url} target={subNav.target} rel={subNav.rel}><span>{subNav.title}</span>
</a>                                  {subNav.submenu && (
                                    <ul className="level1 submenu-t ui-menu-t">
                                      {subNav.submenu.map((subNavLvl2, k) => (
                                        <li key={k} className={`level2 nav-${i+1}-${j+1}-${k+1} category-item ui-menu-item-t ${k === 0 ? 'first' : ''} ${k === subNav.submenu.length-1 ? 'last' : ''} ${subNavLvl2.submenu ? 'parent' : ''}`}>
                                          {subNavLvl2.submenu && <i className="arr-menu-opener"></i>}
                                          <a href={subNavLvl2.url} target={subNavLvl2.target} rel={subNavLvl2.rel} ><span>{subNavLvl2.title}</span></a>
                                          {subNavLvl2.submenu && (
                                            <ul className="level2 submenu-t ui-menu-t">
                                              {subNavLvl2.submenu.map((subNavLvl3, l) => (
                                                <li key={l} className={`level3 nav-${i+1}-${j+1}-${k+1}-${l+1} category-item ui-menu-item-t ${l === 0 ? 'first' : ''} ${l === subNavLvl2.submenu.length-1 ? 'last' : ''}`}>
                                                  <a href={subNavLvl3.url} target={subNavLvl3.target} rel={subNavLvl3.rel}><span>{subNavLvl3.title}</span></a>
                                                </li>
                                              ))}
                                            </ul>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </div>
              </div>
            </li>
          </ul>
          <Language/>
          <span
          className={`action nav-toggle icon-re mobile-toggle-menu ${showMobileMenu ? 'close-mob-menu' : ''}`}
          style={{ top: '68px' }}
          onClick={toggleMobileMenu}>
          <span>
            Toggle Nav
          </span>
        </span>
      </nav>
      {showMobileMenu && <HeaderMobile topNav={topNav} showMobileMenu={showMobileMenu} toggleMobileMenu={toggleMobileMenu} />}
    </div>
  );
}

export default Header;
