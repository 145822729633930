import { useTranslation } from "react-i18next";
import step1 from "../assets/images/step11.svg";
import step2 from "../assets/images/step2.svg";
import step3 from "../assets/images/step3.svg";
import step4 from "../assets/images/step4.svg";
import '../assets/styles/StepsContainer.scss';


function StepsContainer() {
  const { t } = useTranslation();

  return (
    <>
      <div className="steps-container ">
        <div className="title medium "><span className="subBlueTitle">{t("step_intro_1")}</span>{t("step_intro_2")} </div>
        <div className="steps d-flex flex-wrap">
          <div className="step-box col-6 col-lg-3">
            <img src={step1} alt="mobile" />
            <div className="description">
              <span className="medium subBlueTitle">{t("step_1")}</span><br />
              <span className="medium subBlueTitle">{t("step_title_1")}</span>
              <div className="info">{t("step_info_1")}</div>
            </div>
          </div>
          <div className="step-box col-6 col-lg-3">
            <img src={step2} alt="mobile" />
            <div className="description">
              <span className="medium subBlueTitle">{t("step_2")}</span><br />
              <span className="medium subBlueTitle">{t("step_title_2")}</span>
              <div className="info">{t("step_info_2")}</div>
            </div>
          </div>
          <div className="step-box col-6 mt-4 mt-lg-0 col-lg-3">
            <img src={step3} alt="mobile" />
            <div className="description">
              <span className="medium subBlueTitle">{t("step_3")}</span><br />
              <span className="medium subBlueTitle">{t("step_title_3")}</span>
              <div className="info">{t("step_info_3")}</div>
            </div>
          </div>
          <div className="step-box col-6 mt-4 mt-lg-0 col-lg-3">
            <img src={step4} alt="mobile" />
            <div className="description">
              <span className="medium subBlueTitle">{t("step_4")}</span><br />
              <span className="medium subBlueTitle">{t("step_title_4")}</span>
              <div className="info">{t("step_info_4")}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StepsContainer;
