import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

import car from '../assets/images/car.png';
import motor from '../assets/images/motor.png';
import VehicleForm from './VehicleForm';
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { useState } from "react";
// localStorage.setItem('isCar', true)
// localStorage.setItem('isBike', false)

function VhlTypeContainer() {
     const [parent] = useAutoAnimate();
     return (
          <div id="vhl" className="container vhlContainerCollapse" ref={parent}>
               <LeftContainer />
          </div>
     )
}

const LeftContainer = () => {
     const [parent] = useAutoAnimate();
     const [check, setCheck] = useState(false);
     const [cbutton, setCbutton] = useState(false);
     const [mbutton, setMbutton] = useState(false);
     const { t } = useTranslation();
     return (
          <><div className="vhlBg row VhlTypeContainer pb-5 pt-lg-5 pt-md-5 pt-sm-5 pt-xs-1 px-3 px-sm-4 px-md-5 px-lg-5">
               <div className="col-xl-7 col-lg-8 col-md-6 col-sm-12 col-xs-12">
                    <h1 className="mainTitle bold">
                         {t('home_title')}<span className="hl">{t('home_title_2')}</span>
                    </h1>
                    <div className="medium pt-2">
                         {t('home_caption_1')}
                    </div>
                    {/* <div className="pt-3">
         <NavLink className='b_link subBlueTitle'>
              <span>{t('home_link_1')}</span> <ArrowDownSquareFill className="m-1" size={15} />
         </NavLink>
    </div> */}
               </div>

               <div className="rigt-vhcl-container col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mt-sm-5 mt-5 mt-md-0">
                    <h1 className="subTitle mb-4 medium" style={{ color: "#5698FC" }}>
                         {t('home_title_3')}
                    </h1>

                    <div>
                         <div ref={parent} className={"vehicleBox lblueBg mb-2 hover " + (cbutton ? "activeBtn" : "")}
                              onClick={() => {
                                   setTimeout(function () {
                                        document.location = "#section2";
                                   }, 500);
                                   document.getElementById('vhl').classList.add('hint');
                                   setCbutton(() => true);
                                   setMbutton(() => false);
                                   setCheck(() => true);
                                   localStorage.setItem('isCar', true)
                                   localStorage.setItem('isBike', false)
                                   window.dataLayer.push({
                                        vehicle_type: "car"
                                      });
                              }}>
                              <div className="motor">
                                   <div className='imageBox'>
                                        <img src={car} width={'100px'} alt="Car" />
                                   </div>
                                   <div className="content medium" style={{ lineHeight: i18next.language === 'en' ? 5 : 1, paddingTop: i18next.language === 'en' ? '0' : '1.6rem' }}>
                                        {t('drive_car')}
                                   </div>
                              </div>
                         </div>
                         <div className={"vehicleBox lblueBg mb-2 hover " + (mbutton ? "activeBtn" : "")}
                              onClick={() => {
                                   setTimeout(function () {
                                        document.location = "#section2";
                                   }, 500);
                                   setCbutton(() => false);
                                   setMbutton(() => true);
                                   setCheck(() => true);
                                   localStorage.setItem('isCar', false)
                                   localStorage.setItem('isBike', true)
                                   window.dataLayer.push({
                                        vehicle_type: "motorcycle"
                                      });
                              }}>
                              <div className="motor">
                                   <div className='imageBox'>
                                        <img src={motor} width={'100px'} alt="Motor" />
                                   </div>
                                   <div className="content d-block ms-5 medium" style={{ lineHeight: 1, paddingTop: '1.6rem' }}>
                                        {t('drive_motor')}
                                   </div>
                              </div>

                         </div>

                    </div>
               </div>

          </div><VehicleForm isOpen={check} /></>

     )
}




export default VhlTypeContainer;