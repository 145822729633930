
export const checkvalue = (grossPrem) => {
  let checkval = grossPrem.toString();
  let _split = checkval.split('.');

  if (_split.length === 1) {
    // If the number has no decimal part, add '.00' to the end
    return `${_split[0]}.00`;
  } else if (_split[1].length === 1) {
    // If the decimal part has only one digit, add a '0' at the end
    return `${_split[0]}.${_split[1]}0`;
  } else {
    // The decimal part has two or more digits, return as is
    return checkval;
  }
};

