import { useState } from "react";
import RadioBoxContainer from "./Elements/RadioBoxContainer";
import "../assets/styles/PolicyTypeModal.scss";
import { useTranslation } from "react-i18next";

// import '../../assets/styles/policyDropdown.scss';
// import PolicyTypeModal from "./PolicyTypeModal";



function PolicyTypeModal(props) {
  const { t } = useTranslation();

  const datas = [
    {
      title: `${t("comprehensive")}`,
      isSelected: false,
      info: `${t("comprehensive_info")}`,
      value: "01"
    },
    {
      title: `${t("third_party")}`,
      isSelected: false,
      info: `${t("comprehensive_info")}`,
      value: "02"
    },
    {
      title: `${t("third_party_ft")}`,
      isSelected: false,
      info: `${t("third_party_ft_info")}`,
      value: "03"
    },
  ];

  const [isActive, setActive] = useState(props.isActive);
  const [data, setData] = useState(datas);
  const [insuranceType, setInsuranceType] = useState("01");
  const changeEvent = (index,value) => {
    const updatedData = [...data];
    updatedData[index].isSelected = !updatedData[index].isSelected;
    setData(updatedData);
    setInsuranceType(value)
  };
  
  const handlePolicyType = (title) => {
    // On Click of Policy Type dropdown filter
    window.dataLayer.push({
      event: "filter_section",
      event_category: "Plans",
      event_action: "Filter Section",
      event_label: `Policy Type - ${title}`,
      vehicle_type: localStorage.isCar === 'true' ? "car" : "motorcycle",
      customer_age: localStorage.customerAge,
      customer_gender: localStorage.customerGender,
    });

  }

  return (
    <div
      className="modal fade"
      id="staticBackdrop-2"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5 bold" id="staticBackdropLabel">
              {t("p_type")}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="policy-type-modal">
              {/* <span>{data.filter(x => x.isSelected === false).length} selected</span> */}
              {props.vehicleType === "CVP" ? data.filter(x => x.value !== '02').map((data, index) => (
                <div key={index}>
                  <RadioBoxContainer
                    data={data}
                    title={data.title}
                    info={data.info}
                    pop={data.pop}
                    index={index}
                    // val={data.id}
                    // checked = {data.isSelected}
                    // isBgColor={true}
                    isTitleBold={true}
                    // index={index}
                    handleChangeEvent={()=>changeEvent(index,data.value)}
                    id={data.value}
                  />
                </div>
              )): data.map((data, index) => (
                <div key={index}>
                  <RadioBoxContainer
                    data={data}
                    title={data.title}
                    info={data.info}
                    pop={data.pop}
                    index={index}
                    // val={data.id}
                    // checked = {data.isSelected}
                    // isBgColor={true}
                    isTitleBold={true}
                    // index={index}
                    handleChangeEvent={()=>changeEvent(index,data.value)}
                    id={data.value}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer d-flex justify-content-center">
            <button
              type="button"
              className="text-white hover"
              data-bs-dismiss="modal"
              onClick={() => { props.selection(insuranceType); setActive(!isActive);
                handlePolicyType(data.find(d => d.value === insuranceType)?.title); }}
            >
              {t("Done")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PolicyTypeModal;
